import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { NotFoundPageComponent } from "./not-found-page/not-found-page.component";
import { LandingPageComponent } from "./pages/landing-page/landing-page.component";
import { authGuard } from "./core/guards/auth/auth.guard";
import { routeGuard } from "./core/guards/route/route.guard";
import { AboutUsComponent } from "./shared/components/about-us/about-us.component";
import { PrivacyPageComponent } from "./shared/components/privacy-page/privacy-page.component";
import { FillFormComponent } from "./pages/lms/pages/pledge-form/components/fill-form/fill-form.component";

const routes: Routes = [
  {
    path: "",
    canActivate: [authGuard],
    loadChildren: () =>
      import("./pages/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "donation",
    canActivate: [authGuard, routeGuard],
    loadChildren: () =>
      import("./pages/donation/donation.module").then((m) => m.DonationModule),
  },
  {
    path: "recruitment",
    canActivate: [authGuard, routeGuard],
    loadChildren: () =>
      import("./pages/recruitment/recruitment.module").then(
        (m) => m.RecruitmentModule
      ),
  },
  {
    path: "job-listing",
    loadChildren: () =>
      import("./pages/job-listing/job-listing.module").then(
        (m) => m.JobListingModule
      ),
  },
  {
    path: "landing-page",
    canActivate: [authGuard, routeGuard],
    component: LandingPageComponent,
  },
  {
    path: "admin",
    canActivate: [authGuard, routeGuard],
    loadChildren: () =>
      import("./pages/super-admin/super-admin.module").then(
        (m) => m.SuperAdminModule
      ),
  },
  {
    path: "payments",
    loadChildren: () =>
      import("./pages/payments/payments.module").then((m) => m.PaymentsModule),
  },
  {
    path: "arjuna",
    canActivate: [authGuard, routeGuard],
    loadChildren: () =>
      import("./pages/arjuna/arjuna.module").then((m) => m.ArjunaModule),
  },
  {
    path: "lms",
    canActivate: [authGuard, routeGuard],
    loadChildren: () =>
      import("./pages/lms/lms.module").then((m) => m.LMSModule),
  },
  {
    path: "about-us",
    component: AboutUsComponent,
  },
  {
    path: "pledge-form/:formId",
    component: FillFormComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPageComponent,
  },
  { path: "**", component: NotFoundPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
