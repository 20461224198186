<div class="card__opening-container">
  <mat-card *ngIf="currentOpeningDetails">
    <mat-card-header>
      <mat-card-title>
        <span class="text-lg-bold mobile-cardTitle">{{ currentOpeningDetails.title }}</span>
      </mat-card-title>
      <button
        mat-button
        class="button__view-content no-hover-effect" [ngClass]="{'button-visible':viewDetailsButton===true}"
        (click)="clickOnViewDetails()"
      >
        <span class="text-xs-medium">View details</span>
        <mat-icon svgIcon="arrow-up-right"></mat-icon>
      </button>
      <mat-card-subtitle>
        <span class="text-md-light">Posted {{
          currentOpeningDetails.postedOn 
        }}<span *ngIf="currentOpeningDetails.postedOn !== 'Today'"> days ago</span></span>
        <span class="text-md-light">
          <li>{{ currentOpeningDetails.noOfApplicant }} applicants</li>
        </span>
      </mat-card-subtitle>
    </mat-card-header>
    
    <mat-card-actions>
     
        <ng-container *ngFor="let data of previewHeaderContent">
          <div class="action__content" *ngIf="data.text">
            <mat-icon svgIcon="{{ data.icon }}"></mat-icon>
            <span class="text-md-light">{{ data.text }}</span>
          </div>
        </ng-container>
  
      
      
    </mat-card-actions>

    <div
        class="mobile-viewDetails-button"
        (click)="clickOnViewDetails()" [ngClass]="{'button-visible':viewDetailsButton===false}">
        <span class="text-xs-medium mobile-viewDetails">View details</span>
        <mat-icon svgIcon="arrow-up-right" class="mobile-arrow-up-right"></mat-icon>
    </div>

    
  </mat-card>
</div>
