import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs/internal/Subject";
import { ILoginResponse } from "../../models/login-response";
import { API_ENDPOINTS } from "../../structs/api.endpoints.enum";
import { LocalStorageData } from "../../utils/localstorage.data";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private http: HttpClient, private router: Router) {}

  // core methods

  //to get the token from the localStorage
  getToken(): string | null {
    return LocalStorageData.getDataFromLocalStorage("token");
  }

  //to set the token to local storage
  setToken(token: string): void {
    LocalStorageData.saveDataToLocalStorage("token", token);
  }

  //to set the apply job token to local storage
  setApplyJobToken(token: string): void {
    LocalStorageData.saveDataToLocalStorage("applyJobToken", token);
  }

  //to get the apply job token from the localStorage
  getApplyJobToken(): string | null {
    return LocalStorageData.getDataFromLocalStorage("applyJobToken");
  }

  setPaymentInitializationToken(token: string) {
    LocalStorageData.saveDataToLocalStorage("paymentInitToken", token)
  }

  getPaymentInitializationToken() {
    return LocalStorageData.getDataFromLocalStorage("paymentInitToken");
  }

  // to check whether the user is authenticated or not (logged in or not)
  isAuthenticated(): boolean {
    return this.getToken() !== null ? true : false;
  }

  // to set the logged in user details to the local storage
  setLoggedInUserDetails(user: object): void {
    LocalStorageData.saveDataToLocalStorage(
      "loggedInUserDetails",
      JSON.stringify(user)
    );
  }

  // to get the logged in user details from local storage
  getLoggedInUserDetails(): string | null {
    return LocalStorageData.getDataFromLocalStorage("loggedInUserDetails");
  }

  //to set the refresh token to the local storage
  setRefreshToken(refreshToken: string): void {
    LocalStorageData.saveDataToLocalStorage("refreshToken", refreshToken);
  }

  //to set the refresh token to the local storage
  getRefreshToken(): string | null {
    return LocalStorageData.getDataFromLocalStorage("refreshToken");
  }

  // to check if an user is logged in
  isUserLoggedIn() {
    return this.getToken() !== null ? true : false;
  }

  // auth methods

  // handle unauthorized error for expired JWT token
  generateToken(refreshToken: string) {
    const payload = { refreshToken };
    return this.http.post(`${API_ENDPOINTS.generateToken}`, payload);
  }

  //to handle login requests
  userLogin(payload: { username: string; password: string }) {
    return this.http.post<ILoginResponse>(`${API_ENDPOINTS.login}`, payload);
  }

  //to get user profile (get details)
  getUserProfile() {
    return this.http.get(`${API_ENDPOINTS.getUserProfile}`);
  }

  //to handle forgot password
  forgotPassword(email: string) {
    return this.http.post(`${API_ENDPOINTS.forgotPassword}`, {
      email,
    });
  }

  // to validate token from forgot password
  validateToken(token: string) {
    return this.http.get<{
      data: {
        email: string;
        isValid: boolean;
        firstName: string;
        lastName: string;
      };
    }>(`${API_ENDPOINTS.resetPassword}`, {
      params: {
        token,
      },
    });
  }

  //to handle reset or set password
  setPassword(payload: {
    email: string;
    password: string;
    confirmPassword: string;
  }) {
    return this.http.post<{ data: boolean }>(
      `${API_ENDPOINTS.setPassword}`,
      payload
    );
  }

  //to set and get the detailsof logged in user
  private userDetails = new Subject();

  addUserDetails(details: any) {
    this.userDetails.next(details);
  }

  getUserDetails() {
    return this.userDetails;
  }

  //to logout user
  userLogout() {
    return this.http.get(`${API_ENDPOINTS.userLogout}`);
  }

  private userPermissions = new BehaviorSubject({});

  addUserPermission(value: any) {
    this.userPermissions.next(value);
  }

  getUserPermissions() {
    return this.userPermissions;
  }
}
