<!-- cdk-accordion -->
<cdk-accordion class="accordion">
  <div class="accordion__container">
    <cdk-accordion-item
      *ngFor="let item of cdkAccordionData; let index = index"
      #accordionItem="cdkAccordionItem"
      class="accordion__item"
      role="button"
      tabindex="0"
      [attr.id]="'accordion-header-' + index"
      [attr.aria-expanded]="accordionItem.expanded"
      [attr.aria-controls]="'accordion-body-' + index"
    >
      <div
        class="accordion__header"
        (click)="accordionItem.toggle()"
      >
        {{ item.question }}
        <span class="accordion__description">
         <mat-icon svgIcon="caret-up" *ngIf="accordionItem.expanded"></mat-icon>
         <mat-icon svgIcon="caret-down" *ngIf="!accordionItem.expanded"></mat-icon>
        </span>
      </div>
      <div
        class="accordion__body"
        role="region"
        [style.display]="accordionItem.expanded ? '' : 'none'"
        [attr.id]="'accordion-body-' + index"
        [attr.aria-labelledby]="'accordion-header-' + index"
      >
        {{ item.answer }}
      </div>
    </cdk-accordion-item>
  </div>
</cdk-accordion>
