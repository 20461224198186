import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { Router } from "@angular/router";
import { MatChipsModule } from "@angular/material/chips";

@Component({
  selector: "iitb-landing-card",
  standalone: true,
  imports: [CommonModule, MatIconModule, MatButtonModule, MatChipsModule],
  templateUrl: "./landing-card.component.html",
  styleUrls: ["./landing-card.component.scss"],
})
export class LandingCardComponent implements OnChanges {
  constructor(private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.displayChips = this.chipList.map((list: string) => {
      switch (list) {
        case "PERM_VIEW_DASHBOARD":
          return { chipName: "Dashboard", url: "donation" };
        case "PERM_VIEW_DASHBOARD_ARJUNA":
          return { chipName: "Dashboard", url: "arjuna" };
        case "PERM_OFFLINE_DONATION":
          return { chipName: "Offline Donation", url: "donation/offline" };
        case "PERM_BULK_UPLOAD":
          return { chipName: "Bulk Upload", url: "donation/bulk-upload" };
        default:
          return "";
      }
    });
  }

  @Input() details: any;
  @Input() chipList: string[] = [];
  @Input() type: string = "";
  displayChips: any[] = [];

  navigateTo(redirect: string) {
    this.router.navigate([redirect]);
  }
}
