import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from "../../services/auth/auth.service";
import { environment } from "src/environments/environment";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.endsWith(".svg")) {
      return next.handle(request);
    }

    if (!request.headers.has("Content-Type")) {
      request = request.clone({
        url: environment.HOST_URL + request.url,
      });
    }
    request = this.addAuthenticationToken(request);
    return next.handle(request);
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = this.authService.getToken();
    const applyJobToken = this.authService.getApplyJobToken();
    const paymentToken = this.authService.getPaymentInitializationToken();
    const paymentUrls =
      request.url.endsWith("payment/init") ||
      request.url.endsWith("mandate/register");
    const publicUrls =
      request.url.endsWith("login") ||
      request.url.endsWith("forgot-password") ||
      request.url.endsWith("reset-password") ||
      request.url.endsWith("refresh-token");

    if (request.url.endsWith("apply")) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${applyJobToken}`,
        },
      });
    } else if (paymentUrls) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${paymentToken}`,
        },
      });
    } else if (token && !paymentUrls) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
    return request;
  }
}
