import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianNumberFormat',
  standalone: true
})
export class IndianNumberFormatPipe implements PipeTransform {

  transform(value: number | string): string {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    if (!isNaN(value)) {
      return value?.toLocaleString('en-IN', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      });
    }
    return '';
  }

}
