import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AccordionComponent } from '../accordion/accordion.component';
import { SearchComponent } from '../search/search.component';
import { MatIconModule } from '@angular/material/icon';
import { NotificationsCardComponent } from '../notifications-card/notifications-card.component';
import { SegmentControlsComponent } from '../segment-controls/segment-controls.component';
import { BannerComponent } from '../banner/banner.component';
import { HeaderComponent } from '../header/header.component';
import { DonationCountCardComponent } from '../donation-count-card/donation-count-card.component';
import { CurrentOpeningCardComponent } from '../current-opening-card/current-opening-card.component';
import { MatDialog } from '@angular/material/dialog';
import { OtpDialogComponent } from '../otp-dialog/otp-dialog.component';
import { AlertDialogComponent } from '../alert-dialog/alert-dialog.component';
import { StepperComponent } from '../stepper/stepper.component';
import { StepFourComponent } from '../stepper/step-four/step-four.component';
import { StepOneComponent } from '../stepper/step-one/step-one.component';
import { StepThreeComponent } from '../stepper/step-three/step-three.component';
import { StepTwoComponent } from '../stepper/step-two/step-two.component';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { ProfileImageComponent } from '../profile-image/profile-image.component';
import { errorText } from "src/app/shared/common/errorMessages";

@Component({
  selector: 'iitb-component-library',
  standalone: true,
  imports: [CommonModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatIconModule,
    AccordionComponent,
    SearchComponent,
    NotificationsCardComponent,
    SegmentControlsComponent,
    BannerComponent,
    HeaderComponent,
    DonationCountCardComponent,
    CurrentOpeningCardComponent,
    StepperComponent,
    StepFourComponent,
    StepOneComponent,
    StepThreeComponent,
    StepTwoComponent,
    CdkStepperModule,
    FileUploadComponent,
    ProfileImageComponent
  ],
  templateUrl: './component-library.component.html',
  styleUrls: ['./component-library.component.scss']
})
export class ComponentLibraryComponent {
  constructor(private dialog: MatDialog) {
  }
  nameFormControl = new FormControl('', [Validators.required]);
  searchFormControl = new FormControl({ value: '', disabled: true });
  // searchFormControl= new FormControl('');
  emailFormControl = new FormControl('', [Validators.required, Validators.email]);
  iconName!: any;

  accordionData = {
    panelOpenState: false,
    title: "Click to display accordion",
    description: "hi this is expansion panel using accordion in it"
  }
  errorText = errorText;
  cdkAccordionData = [
    { question: "item1", answer: "this is item 1" },
    { question: "item2", answer: "this is item 2" },
    { question: "item3", answer: "this is item 3" },
    { question: "item4", answer: "this is item 4" },
    { question: "item5", answer: "this is item 5" }
  ]

  notificationsCardData = [
    {
      notificationTitle: "Anurag has donated 10,000 rupees through website",
      notificationDate: "10 Oct 2023",
      notificationTime: "10:46 am"
    },
    {
      notificationTitle: "Anurag has donated 10,000 rupees through website",
      notificationDate: "10 Oct 2023",
      notificationTime: "10:46 am"
    },
    {
      notificationTitle: "Anurag has donated 10,000 rupees through website",
      notificationDate: "10 Oct 2023",
      notificationTime: "10:46 am"
    },
    {
      notificationTitle: "Anurag has donated 10,000 rupees through website",
      notificationDate: "10 Oct 2023",
      notificationTime: "10:46 am"
    },
    {
      notificationTitle: "Anurag has donated 10,000 rupees through website",
      notificationDate: "10 Oct 2023",
      notificationTime: "10:46 am"
    }
  ]

  segmentControlData = [
    {
      checked: true,
      data: "DRF",
      key: "DRF"
    },
    {
      checked: false,
      data: "center/Projects",
      key: 'CENTER'
    },
    {
      checked: false,
      data: "center/Projects",
      key: "MIDDLE"
    },
  ]
  currentOpeningDetails = {
    title: 'Vice President - Corporate engagement',
    timeSpan: '2 weeks ago',
    applicants: '4 applicants',
    text: 'IITB DRF (EXT001/2022)',
    user: 'Reporting to COO, KCDH',
    location: 'Powai, Mumbai'
  }

  donationDetails = {
    text: 'Total Donation',
    count: '₹ 49,00,000',
    color: 'black'
  }

  openDialog() {
    this.dialog.open(OtpDialogComponent);
  }

  openSuccessAlert() {
    this.dialog.open(AlertDialogComponent,
      {
        data: { isSuccess: true },
        backdropClass: 'backdropBackground',
        position: { right: '10px', top: '10px' }
      })
  }

  openErrorAlert() {
    this.dialog.open(AlertDialogComponent,
      {
        data: { isSuccess: false },
        backdropClass: 'backdropBackground',
        position: { right: '10px', top: '10px' }
      })
  }

  onSearch(searchText: any) {

  }

  displayData(data: any) {
  }
  knowMoreclicked(event: boolean) {
  }

  getIcon(iconName: any) {
    this.iconName = iconName;
  }
}
