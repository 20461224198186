const GA_EVENTS = {
    JOB_LISTING: {
        BASIC_QAL: {
            SAVE_PROCEED: {
                category: 'Job_listing_SaveAndProceedClick_from_BasicInformation',
                action: 'Click',
                label: 'Basic Information'
            },
        },
        EDU_QAL: {
            SAVE_PROCEED: {
                category: 'Job_listing_SaveAndProceedClick_from_EducationalQualification',
                action: 'Click',
                label: 'Educational Qualification'
            },
            PREVIOUS: {
                category: 'Job_listing_PreviousClick_from_EducationalQualification',
                action: 'Click',
                label: 'Educational Qualification'
            }
        },
        PREVIEW: {
            APPLY: {
                category: 'Job_listing_ApplyClick_from_Preview',
                action: 'Click',
                label: 'Apply'
            },
            PREVIOUS: {
                category: 'Job_listing_PreviousClick_from_Preview',
                action: 'Click',
                label: 'Previous'
            }
        },
        JOB_DETAILS: {
            APPLY: {
                category: 'Job_listing_ApplyNowClick_from_JobDetails',
                action: 'Click',
                label: 'Apply Now'
            },
            PREVIOUS: {
                category: 'Job_listing_PreviousClick_from_Preview',
                action: 'Click',
                label: 'Previous'
            }
        },
        VIEW_DETAILS: {
            category: 'Job_listing_ViewDetailsClick',
            action: 'Click',
            label: 'View Details'
        },
        OTP_VALIDATION: {
            category: 'Job_listing_OTPValidation_Success',
            action: 'Validation',
            label: 'OTP Validation'
        }
    },
    PAYMENTS: {
        ONE_TIME: {
            CONFIRMATION: {
                category: 'Payments_PaymentConfirmationClick_from_OneTime',
                action: 'Click',
                label: 'Payment Confirmation'
            },
            PREVIOUS: {
                category: 'Payments_PreviousClick_from_OneTime',
                action: 'Click',
                label: 'Previous'
            },
            CANCEL: {
                category: 'Payments_CancelClick_from_OneTime',
                action: 'Click',
                label: 'Cancel'
            }
        },
        RECURRING: {
            CONFIRMATION: {
                category: 'Payments_PaymentConfirmationClick_from_Recurring',
                action: 'Click',
                label: 'Payment Confirmation'
            },
            PREVIOUS: {
                category: 'Payments_PreviousClick_from_Recurring',
                action: 'Click',
                label: 'Previous'
            },
            CANCEL: {
                category: 'Payments_CancelClick_from_Recurring',
                action: 'Click',
                label: 'Cancel'
            }
        },
        TABS: {
            ONE_TIME: {
                category: 'Payments_OneTimeClick_from_tabs',
                action: 'Click',
                label: 'One Time'
            },
            RECURRING: {
                category: 'Payments_RecurringClick_from_tabs',
                action: 'Click',
                label: 'Recurring'
            }
        },
        OTP_VALIDATION: {
            category: 'Payments_OTPValidation_Success',
            action: 'Validation',
            label: 'OTP Validation'
        }
    }
}

export const BASIC_QAL_SAVE_PROCEED = GA_EVENTS.JOB_LISTING.BASIC_QAL.SAVE_PROCEED;

export const EDU_QAL_SAVE_PROCEED = GA_EVENTS.JOB_LISTING.EDU_QAL.SAVE_PROCEED;
export const BASIC_EDU_QAL_PREV = GA_EVENTS.JOB_LISTING.EDU_QAL.PREVIOUS;

export const PREVIEW_APPLY = GA_EVENTS.JOB_LISTING.PREVIEW.APPLY;
export const PREVIEW_PREV = GA_EVENTS.JOB_LISTING.PREVIEW.PREVIOUS;

export const JOB_DETAILS_APPLY = GA_EVENTS.JOB_LISTING.JOB_DETAILS.APPLY;
export const JOB_DETAILS_PREV = GA_EVENTS.JOB_LISTING.JOB_DETAILS.PREVIOUS;

export const OTP_VALIDATION_JL = GA_EVENTS.JOB_LISTING.OTP_VALIDATION;
export const OTP_VALIDATION_PY = GA_EVENTS.PAYMENTS.OTP_VALIDATION;

export const VIEW_DETAILS = GA_EVENTS.JOB_LISTING.VIEW_DETAILS;

export const ONE_TIME_CONF = GA_EVENTS.PAYMENTS.ONE_TIME.CONFIRMATION;
export const ONE_TIME_PREV = GA_EVENTS.PAYMENTS.ONE_TIME.PREVIOUS;
export const ONE_TIME_CANCEL = GA_EVENTS.PAYMENTS.ONE_TIME.CANCEL;

export const RECURRING_CONF = GA_EVENTS.PAYMENTS.RECURRING.CONFIRMATION;
export const RECURRING_PREV = GA_EVENTS.PAYMENTS.RECURRING.PREVIOUS;
export const RECURRING_CANCEL = GA_EVENTS.PAYMENTS.RECURRING.CANCEL;

export const TABS_ONE_TIME = GA_EVENTS.PAYMENTS.TABS.ONE_TIME;
export const TABS_RECURRING = GA_EVENTS.PAYMENTS.TABS.RECURRING;
