import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DateAgoPipe } from '../../pipes/date-ago.pipe';
import { constants } from 'src/app/core/utils/constants.enum';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'current-opening-card',
  standalone: true,
  imports: [
    CommonModule,
    MatCardModule,
    MatButtonModule,
    MatIconModule,
    DateAgoPipe
  ],
  templateUrl: './current-opening-card.component.html',
  styleUrls: ['./current-opening-card.component.scss']
})
export class CurrentOpeningCardComponent {
  @Input() currentOpeningDetails: any;
  @Output() viewDetailsEmitter: EventEmitter<any> = new EventEmitter<any>();
  previewHeaderContent:any=[];
  viewDetailsButton= false;
  constructor(public breakPointObserver:BreakpointObserver){

  }
  ngOnInit() {
    this.getBasicDetails();
    
    this.breakPointObserver.observe(['(max-width: 480px)', '(min-width: 360px)']).subscribe((response:any)=>{
      if (response.breakpoints['(max-width: 480px)'] && response.breakpoints['(min-width: 360px)']) {
        this.viewDetailsButton = true;
      }
      else {
        this.viewDetailsButton = false;
      }
    })
    
  }

 
  

  clickOnViewDetails() {
    this.viewDetailsEmitter.emit(this.currentOpeningDetails)

  }

  getBasicDetails() {
    let type = constants.drf;
    if (this.currentOpeningDetails.organisation === type) {
      this.previewHeaderContent = [
        {
          text: this.currentOpeningDetails.positionCode,
          icon: 'building'
        },
        {
          text: this.currentOpeningDetails.jobLocation,
          icon: 'location'
        },
        {
          text: `Reporting to ${this.currentOpeningDetails.reportingTo}`,
          icon: 'ic-user'
        },
        {
          text: this.currentOpeningDetails.duration,
          icon: 'suitcase_simple'
        }
      ]
    } else {
      this.previewHeaderContent = [
        {
          text: this.currentOpeningDetails.positionCode,
          icon: 'building'
        },
        {
          text: `${this.currentOpeningDetails.duration}`,
          icon: 'suitcase_simple'
        },
        {
          text: `Reporting to ${this.currentOpeningDetails.reportingTo}`,
          icon: 'ic-user'
        },
        {
          text: this.currentOpeningDetails.salaryBand,
          icon: 'money'
        }
      ]
    }

    // return previewHeaderContent;
  }


}
