<ng-container *ngIf="data.isSuccess">
    <div class="alert__dialog success__container">
        <mat-icon svgIcon="success"></mat-icon>
        <div>
            <div mat-dialog-title class="text-sm-medium">Your from has been submitted!</div>
            <div mat-dialog-content class="text-sm-light">We have sent an email to your registered email address. </div>
            <div mat-dialog-actions>
                <button class="text-sm-medium button__dismiss button__color" (click)="onClose()">Dismiss</button>
            </div>
        </div>
        <button class="button__cross" (click)="onClose()">
            <mat-icon svgIcon="cross-success"></mat-icon>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="!data.isSuccess">
    <div class="alert__dialog error__container">
        <mat-icon svgIcon="error"></mat-icon>
        <div>
            <div mat-dialog-title class="text-sm-medium">Invalid Credentials</div>
            <div mat-dialog-content class="text-sm-light">Please re-enter your credentials and try again.</div>
            <div mat-dialog-actions>
                <button class="text-sm-medium button__dismiss button__color" (click)="onClose()">Dismiss</button>
            </div>
        </div>
        <button class="button__cross" (click)="onClose()">
            <mat-icon svgIcon="cross-error"></mat-icon>
        </button>
    </div>
</ng-container>

