import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable, signal, WritableSignal } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { API_BASE_URL } from "src/app/core/constants/urls/urls";
import { API_ENDPOINTS } from "src/app/core/structs/api.endpoints.enum";
import { constants } from "src/app/core/utils/constants.enum";

@Injectable({
  providedIn: "root",
})
export class JobListingService {
  selectedJobListing: any = {};
  jobTypeSelected: string = "DRF";
  currentPage = 1;
  hasClickedButton = false;

  constructor(private route: ActivatedRoute, public http: HttpClient,private router:Router) {}

  setButtonClicked() {
    this.hasClickedButton = true;
  }

  hasUserClickedButton() {
    return this.hasClickedButton;
  }

  getJobDetails(id: number) {
    return this.http.get(`${API_BASE_URL}${API_ENDPOINTS.createJob}/${id}`);
  }

  getFAQs(faqCategory: string): Observable<any> {
    return this.http.get(`${API_ENDPOINTS.getFaq}`, {
      params: {
        category: faqCategory,
      },
    });
  }

  getJobList(payload: any, size?: number) {
    const apiUrl = `${API_BASE_URL}${API_ENDPOINTS.jobList}/${size}`;
    return this.http.post(apiUrl, payload);
  }

  getAffiliations() {
    return this.http.get(`${API_ENDPOINTS.getAffiliations}`);
  }


  sendOTPForApply(payload: any) {
    return this.http.post(`${API_ENDPOINTS.jobCandidateApply}`, payload);
  }

  verifyOTP(payload: any) {
    return this.http.post(`${API_ENDPOINTS.jobVerifyOTp}`, payload);
  }

  applyJob(payload: any): Observable<any> {
    return this.http.post(`${API_BASE_URL}${API_ENDPOINTS.applyJob}`, payload);
  }

  //to set and get the values of form fields in apply job flow
  private applyJobFormDetails = new BehaviorSubject({});

  addDetails(details: any) {
    this.applyJobFormDetails.next({
      ...this.applyJobFormDetails.value,
      ...details,
    });
  }

  getAppliedFormDetails() {
    return this.applyJobFormDetails;
  }

  private searchText = new BehaviorSubject("");

  setSearchText(text: string) {
    this.searchText.next(text);
  }

  getSearchText() {
    return this.searchText.asObservable();
  }
}
