import { Component } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  MatProgressSpinner,
  MatProgressSpinnerModule,
} from "@angular/material/progress-spinner";
import { LoaderService } from "src/app/core/services/loader/loader.service";

@Component({
  selector: "iitb-loader",
  standalone: true,
  imports: [CommonModule, MatProgressSpinnerModule],
  templateUrl: "./loader.component.html",
  styleUrls: ["./loader.component.scss"],
})
export class LoaderComponent {
  constructor(public loader: LoaderService) {}
}
