<div class="internal-footer" *ngIf="portal === 'internal'">
    <section class="internal-footer__copyright">
        <mat-icon svgIcon="copyright"></mat-icon>
        <span>{{year}} - IIT Bombay. All rights reserved</span>
    </section>
</div>

<div class="public-footer" *ngIf="portal === 'public'">
    <section class="public-footer__reachout">
        <span class="public-footer__reachout--header">Got any Questions?</span>
        <span class="public-footer__reachout--sub-text">Reachout to us with your queries and we will get back to you at
            earliest.</span>
        <button mat-flat-button disableRipple class="button--primary footer-button">
            <mat-icon svgIcon="envelope"></mat-icon>
            <span><a href="mailto:contactus@iitbacr.com" class="send-mail">Send email</a></span>
        </button>
    </section>
    <section class="public-footer__share__copyright">
        <div class="public-footer__copyright">
            <mat-icon svgIcon="copyright"></mat-icon>
            <span>{{year}} - IIT Bombay. All rights reserved</span>
        </div>
    </section>
</div>