import { Component, Input, OnInit } from "@angular/core";
import { CommonModule, TitleCasePipe } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatButtonModule } from "@angular/material/button";
import { MatListModule } from "@angular/material/list";
import { MatToolbarModule } from "@angular/material/toolbar";
import { Router, RouterModule } from "@angular/router";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { MatMenuModule } from "@angular/material/menu";
import { MatDividerModule } from "@angular/material/divider";
import { LocalStorageData } from "src/app/core/utils/localstorage.data";
import { ProfileImageComponent } from "../profile-image/profile-image.component";
import { UtilityService } from "src/app/core/utils/utility.service";
import { BreakpointObserver } from "@angular/cdk/layout";
@Component({
  selector: "iitb-header",
  standalone: true,
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    RouterModule,
    MatMenuModule,
    MatDividerModule,
    ProfileImageComponent,
    TitleCasePipe
  ],
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private router: Router,
    private utility: UtilityService,
    public breakPointObserver:BreakpointObserver
  ) {}
  viewMenuButton = false
  ngOnInit(): void {
    this.breakPointObserver.observe(['(max-width: 480px)', '(min-width: 360px)']).subscribe((response:any)=>{
      if (response.breakpoints['(max-width: 480px)'] && response.breakpoints['(min-width: 360px)']) {
        this.viewMenuButton = true;
      }
      else {
        this.viewMenuButton = false;
      }
    })
    if(this.isLoggedIn) {
      this.authService.getUserProfile().subscribe({
        next: (response: any) => {
          this.authService.addUserDetails(response.data);
          // this.utility.setUserPermissions({});
        },
      });
      this.authService.getUserDetails().subscribe({
        next: (response: any) => {
          this.loggedInDetails = response;
        },
      });
    }

  }

  loggedInDetails: any;
  @Input() isLoggedIn: boolean = false;
  clicked: boolean = false;
  navListing = [
    {
      menuItem: "JOB OPENINGS",
      link: "/job-listing",
    },
    {
      menuItem: "ABOUT US",
      link: "/about-us",
    },
  ];

  redirectTo(item:any) {
    if(item==='ABOUT US') {
      this.router.navigate(['about-us']);
    }
    this.clicked = true;
  }

  signout() {
    this.authService.userLogout().subscribe({
      next: (response: any) => {
        LocalStorageData.clearDataFromLocalStorage();
        this.router.navigate(["login"]);
      },
    });
  }
}
