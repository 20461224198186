<div class="stepper" [ngClass]="[direction==='vertical' ? 'vertical' : '']">
  <div class="stepper-header">
    <div class="stepper-steps">
      <ol class="steps">
        <div class="progress">
          <div class="step-progress">
            <span class="progress-count">{{currentIndex}}/{{steps.length}}</span>
          </div>
          <canvas #canvas width="56px" height="56px" class="canvas"></canvas>
        </div>
        <ng-container *ngFor="let step of steps; let i = index;">
          <li class="step"
          (click)="goToStep(step,i)"
            [ngClass]="getIconClass(step,selectedIndex,i)">
            <ng-container [ngTemplateOutlet]="step.stepLabel.template">
              <ng-content select=".step-bullet"></ng-content>
              <ng-content select=".step-title"></ng-content>
              <ng-content select=".step-content"></ng-content>
            </ng-container>
          </li>
        </ng-container>
      </ol>
    </div>
    <div class="stepper-contact-support">
      <p>
        For any payment-related issues, please email us on
        <a href="mailto:operations&#64;iitbacr.com">operations&#64;iitbacr.com</a> 
        or WhatsApp us at 
        <a href="https://wa.me/918779416134">+91 8779416134</a> 
        with the details of your issue
      </p>
    </div>
  </div>
  <div class="stepper-body">
    <div class="content" *ngFor="let step of _steps; let i = index" [class.current]="selectedIndex === i"
      [@stepTransition]="_getAnimationDirection(i)">
      <ng-container [ngTemplateOutlet]="step.content">
      </ng-container>
    </div>
  </div>
</div>
