<div class="banner">
    <div class="banner__logoBox">
        <div class="banner__logo"><mat-icon svgIcon="banner-logo"></mat-icon></div>
        <div>
            <h6 class="institute-name">Indian Institute of Technology</h6>
            <p class="institute-subtitle">Development and Relations Foundation</p>
        </div>
    </div>

    <div class="banner-content">
        <p class="banner-content__title">Get a Chance to Work at IIT-Bombay</p>

        <p class="banner-content__subtitle">
            <span>Gain flexible work opportunities, be open to working in challenging intellectual fields and experience the best of technology</span>
        </p>
        <button mat-flat-button disableRipple class="button--primary" (click)="knowMoreClicked()">Know more</button>
    </div>
</div>
