import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { IPledgeFormFields } from "src/app/core/utils/pledgeForm.interface";
import { RegexContainer } from "src/app/core/utils/regex.patterns.enum";

@Injectable({
  providedIn: "root",
})
export class CommonService {
  constructor() {}

  updateFieldStatus(
    fieldNames: IPledgeFormFields[],
    event: boolean,
    fieldProperty: "isRequired" | "isSelected"
  ): boolean {
    if (event) {
      const allFieldsSatisfied = fieldNames.filter(
        (field) => !field[fieldProperty]
      );
      return allFieldsSatisfied.length === 0;
    } else {
      return false;
    }
  }

  setDynamicValidators(formGroup: FormGroup, formDetails: IPledgeFormFields[]) {
    Object.keys(formGroup.controls).forEach((controlKey) => {
      const control = formGroup.get(controlKey);
      if (control instanceof FormControl) {
        const isRequired = this.checkForIsRequired(controlKey, formDetails);
        if (isRequired) {
          if (controlKey === "email") {
            control.setValidators([Validators.pattern(RegexContainer.emailPattern), Validators.required]);
          } else {
            control.setValidators([Validators.required]);
          }
        } else {
          control.clearValidators();
        }
        control.updateValueAndValidity();
      } else if (control instanceof FormGroup) {
        Object.keys(control.controls).forEach((childControlKey) => {
          const childControl = control.get(childControlKey);
          const isRequired = this.checkForIsRequired(
            childControlKey,
            formDetails
          );

          if (isRequired) {
            childControl?.setValidators([Validators.required]);
          } else {
            childControl?.clearValidators();
          }
          childControl?.updateValueAndValidity();
        });
      }
    });
  }

  checkForIsRequired(fieldName: string, formDetails: IPledgeFormFields[]) {
    return formDetails?.find((field: any) => field.fieldName === fieldName)
      ?.isRequired;
  }
}
