import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import {
  contactUsEmail,
  contactUsNumber,
  donationContactNumber,
  donationMailId,
} from "../../configs/constants";

@Component({
  standalone: true,
  imports: [CommonModule, MatIconModule],
  selector: "iitb-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  givingBackContactNumber = donationContactNumber;
  givingBackContactEmail = donationMailId;
  contactEmail = contactUsEmail;
  contactNumber = contactUsNumber;
  currentYear: number = new Date().getFullYear();
  quickLinks = [
    {
      name: "Home",
      link: "https://acr.iitbombay.org/",
    },
    {
      name: "Giving Back",
      link: "https://acr.iitbombay.org/giving-back/",
    },
    {
      name: "Corporate connect",
      link: "https://acr.iitbombay.org/corporate-connect/",
    },
    {
      name: "Events",
      link: "https://acr.iitbombay.org/events/upcoming-events/",
    },
    {
      name: "Batch Legacy",
      link: "https://acr.iitbombay.org/batch-legacy/",
    },
    {
      name: "Privacy Policy",
      link: "https://alumni.acr.iitb.ac.in/privacy-policy/",
    },
  ];

  check: number[] = [1, 2, 3, 4];
  otherLinks = [
    {
      name: "About",
      link: "/about-us",
    },
    {
      name: "Alumni Corner",
      link: "https://acr.iitbombay.org/stay-connected/",
    },
    {
      name: "Donor Wall",
      link: "https://acr.iitbombay.org/donor-wall/",
    },
    {
      name: "Awards",
      link: "https://acr.iitbombay.org/distinguished-alumnus/",
    },
    {
      name: "Resources",
      link: "https://acr.iitbombay.org/resources/news/",
    },
  ];
}
