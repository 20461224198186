<div class="card__container">
  <div class="card__image">
    <img src="../../../../assets/images/landing-card.png" />
  </div>
  <div class="card__content">
    <div>
      <div class="para-bold">{{ details.title }}</div>
      <div class="text-xs-light">
        {{ details.description }}
      </div>
    </div>
    <button
      mat-button
      class="button__view-details no-hover-effect"
      (click)="navigateTo(details.redirection)"
      *ngIf="type === 'RECRUITMENT' || type === 'ADMIN'"
    >
      <span class="text-xs-medium">View details</span>
      <mat-icon svgIcon="arrow-up-right"></mat-icon>
    </button>
    <mat-chip-set *ngIf="type === 'DONATION'">
      <mat-chip *ngFor="let chip of displayChips" (click)="navigateTo(chip.url)" class="home-page__chips"
        ><span class="chip-label">{{ chip.chipName }}</span
        ><mat-icon svgIcon="arrow-up-right"></mat-icon
      ></mat-chip>
    </mat-chip-set>
  </div>
</div>
