import { Injectable } from "@angular/core";
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from "@angular/material/snack-bar";
import { SnackbarComponent } from "src/app/shared/snackbar/snackbar.component";

@Injectable({
  providedIn: "root",
})
export class SnackbarService {
  horizontalPosition: MatSnackBarHorizontalPosition = "right";
  verticalPosition: MatSnackBarVerticalPosition = "top";
  constructor(private _snackBar: MatSnackBar) {}

  openSnackBar(message: string, type?: string) {
    const data = {
      message,
      type,
    };

    const config: MatSnackBarConfig = {
      data: data,
      duration: 3000,
      horizontalPosition: "right",
      verticalPosition: "top",
      panelClass: type,
    };
    this._snackBar.openFromComponent(SnackbarComponent, config);
  }
}
