export const errorText = {
  requiredField: "This is a mandatory field.",
  emptySelection: "Please make a selection.",
  nameLimit: "Input text exceeds the specified limit of 50 characters.",
  nameInvalid: "Can contain only spaces and periods ('.') as characters.",
  fullNameValidation: "Please enter a full name.",
  emailInvalid: "Please enter a valid email address.",
  phoneNumberInvalid: "Please enter a valid contact number.",
  addressLimit: "Input text exceeds the specified limit.",
  addressInvalid: "Address line can contain commas (',') and spaces only.",
  cityInvalid: "No numeric inputs are allowed.",
  pincodeInvalid: "No spaces or special characters are allowed.",
  remarkLimit: "Input text exceeds the specified limit. Please limit to 1,000 characters.",
  panInvalid: "Please enter a valid PAN card number.",
  rateInvalid: "Rate should contain at least one decimal point.",
  accountInvalid: "Please enter a valid bank account number.",
  ifscInvalid: "Please enter a valid bank branch IFSC code.",
  passwordInvalid: "Minimum of 6 characters, with at least one uppercase letter, one lowercase letter, and one number or symbol.",
  passwordMismatch: "Password confirmation mismatch.",
  otpLengthMismatch: "Length should be 6 digits.",
  unauthorizedError: "You have been logged out of the current session. Please re-login to continue with the latest updates.",
  restrictedAccess: "Restricted access.",
  somethingWentWrong: "Something went wrong.",
  exportSuccessMessage: "Records exported successfully!",
  exportErrorMessage: "Couldn't export records.",
  noPermissions: "No permissions found.",
  minAmount: "The minimum donation amount should be Rs. 25.",
  vpaPattern: "No spaces or special characters allowed; should contain '@'.",
  fileExportFailed : "Couldn't export records",
  fileLimitExceeds: "Uploaded file exceeds specified limit.",
  pageNotFound: "404 Page not found!"
};

export const successMessages = {
  otpValidateSuccessfully: "OTP verified successfully.",
  otpSentSuccessfully: "OTP sent successfully.",
  userRegistration: "User added successfully. Registration link shared with the new user.",
  userUpdation: "User access edited successfully.",
  userDeletion: "User access revoked.",
  fileSuccess: "Successfully uploaded.",
  fileExportSuccess : "Exported all records successfully!",
  purposeCreated: "Purpose created successfully.",
  purposeUpdated: "Purpose updated successfully.",
  purposeaArchived: "Purpose archived successfully.",
  purposeaUnarchived: "Purpose unarchived successfully.",
  notesAndAttachmentAdded:"Notes and Attachments added successfully!",
  archiveTransaction:"Transaction archived successfully",
  unArchiveTransaction:"Transaction Unarchived successfully",
  updatePledge:"Pledge updated successfully",
  profileDetailsSaved : "Your profile details has been saved successfully!",
  updateTransactionType:"Transaction Type updated successfully"
};
