<div class="container">
    <form [formGroup]="stepThreeForm" (ngSubmit)="stepThreeSubmit()" autocomplete="off" novalidate #formRef="ngForm">
      <div>
        <label class="label">Address</label>
        <mat-form-field appearance="fill" floatLabel="always">
          <input type="text" tabindex="0" matInput formControlName="address" placeholder="address..." />
          <mat-icon
          matSuffix
          *ngIf="showErrorState(stepThreeForm.get('address'))"
        ></mat-icon>
          <mat-error *ngIf="stepThreeForm.get('address')?.hasError('required')">
            {{ errorText.requiredField }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="stepper-button">
        <div>
          <button mat-flat-button color="primary" disableRipple class="sm" cdkStepperPrevious>Previous</button>
        </div>
        <div>
          <button mat-flat-button color="primary" disableRipple class="sm" [disabled]="!stepThreeForm.valid"
            cdkStepperNext>Next</button>
        </div>
      </div>
    </form>
  </div>
