import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { CdkStepper, STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { CommonModule } from "@angular/common";
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatStepperModule } from "@angular/material/stepper";

@Component({
  selector: "iitb-stepper",
  standalone: true,
  imports: [
    CommonModule,
    MatStepperModule,
    MatFormFieldModule,
    MatButtonModule,
    MatInputModule,
  ],
  templateUrl: "./stepper.component.html",
  styleUrls: ["./stepper.component.scss"],
  providers: [
    { provide: CdkStepper, useExisting: StepperComponent },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  animations: [
    trigger("stepTransition", [
      state("previous", style({ transform: "translateX(100%)", opacity: 0 })),
      state("current", style({ transform: "translateX(0)", opacity: 1 })),
      state("next", style({ transform: "translateX(100%)", opacity: 0 })),
      transition(":enter", animate(0)),
      transition(
        "previous => current",
        animate("500ms cubic-bezier(0.35, 0, 0.25, 1)")
      ),
      transition(
        "next => current",
        animate("500ms cubic-bezier(0.35, 0, 0.25, 1)")
      ),
    ]),
  ],
})
export class StepperComponent extends CdkStepper implements OnInit, OnChanges {
  ngOnInit(): void {}
  @Input() direction!: string;
  @Input() size!: string;
  @Input() currentIndex: number = 0;
  @Input() goToStepEnable: boolean = false;

  @Output() iconEvent = new EventEmitter<string>();
  iconName: any;

  @ViewChild("canvas") canvas!: ElementRef<HTMLCanvasElement>;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["currentIndex"] && this.canvas)
      this.drawCircle(changes["currentIndex"].currentValue);
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.drawCircle(this.currentIndex);
  }

  drawCircle(currentStep: number): void {
    const ctx = this.canvas.nativeElement.getContext("2d")!;
    ctx.clearRect(
      0,
      0,
      this.canvas.nativeElement.width,
      this.canvas.nativeElement.height
    );
    const centerX = this.canvas.nativeElement.width / 2;
    const centerY = this.canvas.nativeElement.height / 2;
    const adjustedRadius = Math.min(centerX, centerY) - 1;

    ctx.beginPath();
    ctx.arc(
      centerX,
      centerY,
      adjustedRadius,
      -0.5 * Math.PI,
      -0.5 * Math.PI,
      false
    );

    ctx.beginPath();
    ctx.arc(
      centerX,
      centerY,
      adjustedRadius,
      -0.5 * Math.PI,
      ((currentStep * 2) / this.steps.length) * Math.PI - 0.5 * Math.PI,
      false
    );
    ctx.lineWidth = 2;
    ctx.strokeStyle = "#004F9F";
    ctx.stroke();
  }

  getIconClass(step: any, selectedIndex: number, i: number) {
    const stepClass: string[] = [];

    if (selectedIndex === i && !step.interacted && !step.completed) {
      this.iconName = {
        iconName: "stepper-current",
        iconLine: "stepper-active-line",
      };
      this.getIconName(this.iconName);
      stepClass.push("current");
      // return 'current';
    }
    // else if(selectedIndex === i && step.interacted && step.completed){
    //   if(this.iconName.iconName === "stepper-complete"){
    //     this.iconName = {iconName:"stepper-current",iconLine:"stepper-active-line"};
    //   }
    //         this.getIconName(this.iconName);
    //         return 'current';
    // }

    if (selectedIndex === i && step.interacted && step.completed) {
      if (i === this.steps.length - 1) {
        this.iconName = {
          iconName: "stepper-complete",
          iconLine: "stepper-complete-line",
        };
      } else {
        this.iconName = {
          iconName: "stepper-current",
          iconLine: "stepper-active-line",
        };
      }

      this.getIconName(this.iconName);
      this.iconName = {
        iconName: "stepper-complete",
        iconLine: "stepper-complete-line",
      };
      stepClass.push("done");
      // return 'done';
    } else if (step.interacted && i >= selectedIndex && step.completed) {
      this.iconName = {
        iconName: "stepper-complete",
        iconLine: "stepper-complete-line",
      };
      this.getIconName(this.iconName);
      stepClass.push("done");
      // return 'done';
    } else if (step.completed && i <= selectedIndex && step.interacted) {
      this.iconName = {
        iconName: "stepper-complete",
        iconLine: "stepper-complete-line",
      };
      this.getIconName(this.iconName);
      stepClass.push("complete");
      // return 'complete';
    }

    if (
      selectedIndex < i &&
      step.completed === false &&
      step.interacted === false
    ) {
      this.iconName = {
        iconName: "stepper-inactive",
        iconLine: "stepper-inactive-line",
      };
      this.getIconName(this.iconName);
      stepClass.push("step");
      // return 'step';
    }

    if (selectedIndex === i) stepClass.push("currentStep");
    return stepClass;
  }

  override _stateChanged() {
    this.steps.forEach((step, i) => {
      if (i >= this.selectedIndex && !step.completed) {
        step.interacted = false;
      }
      if (this.selectedIndex <= i && step.completed) {
        step.interacted = true;
      }
    });
  }

  getIconName(value: any) {
    this.iconEvent.emit(value);
  }

  goToStep(step:any, value: number) {
    if (this.goToStepEnable && step.interacted && step.completed) this.selectedIndex = value;
  }
}
