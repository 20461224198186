import { Component } from '@angular/core';

@Component({
  selector: 'iitb-project-layout',
  templateUrl: './project-layout.component.html',
  styleUrls: ['./project-layout.component.scss']
})
export class ProjectLayoutComponent {

}
