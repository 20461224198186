<div class="container">
    <form [formGroup]="stepOneForm" (ngSubmit)="stepOneSubmit()" autocomplete="off" novalidate #formRef="ngForm">
      <div>
        <label class="label">First Name</label>
        <mat-form-field appearance="fill" floatLabel="always">
          <input type="text" tabindex="0" matInput formControlName="firstName" placeholder="First name..." />
          <mat-icon matSuffix *ngIf="showErrorState(stepOneForm.get('firstName'))"></mat-icon>
          <mat-error *ngIf="stepOneForm.get('firstName')?.hasError('required')">
            {{ errorText.requiredField }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <label class="label">Last Name</label>
        <mat-form-field appearance="fill" floatLabel="always">
          <input type="text" tabindex="0" matInput formControlName="lastName" placeholder="Last name..." />
          <mat-icon matSuffix  *ngIf="showErrorState(stepOneForm.get('lastName'))"></mat-icon>
          <mat-error *ngIf="stepOneForm.get('lastName')?.hasError('required')">
            {{ errorText.requiredField }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="stepper-button">
        <button class="button" mat-flat-button color="primary" disableRipple class="sm" [disabled]="!stepOneForm.valid"
          cdkStepperNext>Next</button>
      </div>
    </form>
  </div>
