import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { Subject, BehaviorSubject, Observable } from "rxjs";
import { API_BASE_URL } from "../../constants/urls/urls";
import {
  IBatchDropdownData,
  ICurrencyDropdownData,
  ITransactionDropdownData,
  IdonorDropdownData,
} from "../../models/donation";
import { API_ENDPOINTS } from "../../structs/api.endpoints.enum";
import { IPageConfig } from "../../models/data-table";
import { Router } from "@angular/router";
import { IFileUploadService } from "../file-upload.service.interface";

@Injectable({
  providedIn: "root",
})
export class DonationService implements IFileUploadService {
  constructor(public http: HttpClient,private router: Router) {}

  getDonationTableData(payload: any, pageNumber: number) {
    return this.http.post(
      `${API_ENDPOINTS.donationListWithFilter}` + pageNumber,
      payload
    );
  }

  getPendingTransactionTableData(payload: any, pageNumber: number) {
    return this.http.post(
      `${API_ENDPOINTS.pendingTransaction}` + pageNumber,
      payload
    );
  }

  getDonarPreviewData(donorId: any) {
    return this.http.get(`${API_ENDPOINTS.donationPreview}` + donorId);
  }

  getDonationSummary() {
    return this.http.get(`${API_ENDPOINTS.donationKPI}`);
  }

  exportDonors(payload: any) {
    return this.http.post(`${API_ENDPOINTS.donationExport}`, payload, {
      responseType: "arraybuffer",
    });
  }

  exportPaymentDonors(payload: any) {
    return this.http.post(`${API_ENDPOINTS.donationPaymentExport}`, payload, {
      responseType: "arraybuffer",
    });
  }

  downloadThankyouNote(pdfType: string, id: any) {
    return this.http.get(`${API_ENDPOINTS.downloadPdf}` + id + "/" + pdfType, {
      responseType: "arraybuffer",
    });
  }

  //to set and get the values of form fields in offline donation flow
  private donationFormDetails = new Subject();

  addDetails(details: any) {
    this.donationFormDetails.next(details);
  }

  getDonationFormDetails() {
    return this.donationFormDetails;
  }

  // set offline donor property in offline donation flow (signal)
  private unknownDonor = signal<boolean>(false);

  setUnknownDonor(value: boolean) {
    this.unknownDonor.set(value);
  }

  checkUnknownDonor() {
    return this.unknownDonor;
  }

  // form submission signal
  private formSubmission = signal<boolean>(false);

  setFormSubmission(value: boolean) {
    this.formSubmission.set(value);
  }

  checkFormSubmission() {
    return this.formSubmission;
  }

  // handle offline form requests
  submitOfflineDonationForm(payload: any) {
    return this.http.post(API_ENDPOINTS.donation, payload);
  }

  //date subject
  private dataSubject = new BehaviorSubject<any>({});

  setDate(value: { startDate: string; endDate: string }) {
    this.dataSubject.next(value);
  }

  getDate() {
    return this.dataSubject.asObservable();
  }

  //date subject for pending transaction
  private dataPendingTransSubject = new BehaviorSubject<any>({});

  setPendingTransDate(value: { startDate: string; endDate: string }) {
    this.dataPendingTransSubject.next(value);
  }

  getPendingTransDate() {
    return this.dataPendingTransSubject.asObservable();
  }

   //project filter subject
   private projectFilter = new BehaviorSubject<any>({});

  setProjectFilter(value: { projectSearch: string; projectIds: any }) {
    this.projectFilter.next(value);
  }

  getProjectFilter() {
    return this.projectFilter.asObservable();
  }

  private resetBtnSubject = new BehaviorSubject<any>({ btnState: true });

  setResetBtnState(value: { btnState: boolean }) {
    this.resetBtnSubject.next(value);
  }

  getResetBtnState() {
    return this.resetBtnSubject.asObservable();
  }

  private resetBtnSubjectPendingTrans = new BehaviorSubject<any>({ btnState: true });

  setResetBtnStatePendingTrans(value: { btnState: boolean }) {
    this.resetBtnSubjectPendingTrans.next(value);
  }

  getResetBtnStatePendingTrans() {
    return this.resetBtnSubjectPendingTrans.asObservable();
  }

  getProjectIds(payload: string) {
    return this.http.get(`${API_BASE_URL}${API_ENDPOINTS.getProjectIds}`, {
      params: { project: payload },
    });
  }

  // offline form dropdown handlers
  getAffiliations() {
    return this.http.get<IdonorDropdownData>(API_ENDPOINTS.affiliation);
  }

  getLevel1() {
    return this.http.get<IdonorDropdownData>(API_ENDPOINTS.level1);
  }

  getLevel2(id: number) {
    return this.http.get<IdonorDropdownData>(`${API_ENDPOINTS.level2}/${id}`);
  }

  getProjects(id: number) {
    return this.http.get<IdonorDropdownData>(`${API_ENDPOINTS.project}/${id}`);
  }

  getDepartment() {
    return this.http.get<IdonorDropdownData>(API_ENDPOINTS.department);
  }

  getDegree() {
    return this.http.get<IdonorDropdownData>(API_ENDPOINTS.degree);
  }

  getHostel() {
    return this.http.get<IdonorDropdownData>(API_ENDPOINTS.hostel);
  }

  getBatches() {
    return this.http.get<IBatchDropdownData>(API_ENDPOINTS.batches);
  }

  getTypeOfTransaction() {
    return this.http.get<ITransactionDropdownData>(
      API_ENDPOINTS.transactionTypes
    );
  }

  getCurrency() {
    return this.http.get<ICurrencyDropdownData>(API_ENDPOINTS.currency);
  }

  getCurrencyById(id: string) {
    return this.http.get(`${API_ENDPOINTS.currency}/${id}`);
  }

  // search handler (signal)

  //Handle pageConfig

  private pageConfig = new BehaviorSubject<IPageConfig>({
    currentPage: 0,
    selectedPageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
  });

  getPageConfig() {
    return this.pageConfig;
  }

  setPageConfig(value: IPageConfig) {
    this.pageConfig.next(value);
  }

   //Handle pageConfig for pending transaction

   private pageConfigPendingTrans = new BehaviorSubject<IPageConfig>({
    currentPage: 0,
    selectedPageSize: 10,
    pageSizeOptions: [10, 25, 50, 100],
  });

  getPageConfigPendingTrans() {
    return this.pageConfigPendingTrans;
  }

  setPageConfigPendingTrans(value: IPageConfig) {
    this.pageConfigPendingTrans.next(value);
  }

  // bulk upload handles
  bulkUpload(payload: any) {
    return this.http.post(
      `${API_BASE_URL}${API_ENDPOINTS.bulkUpload}`,
      payload
    );
  }

  uploadFile(formData: FormData): Observable<HttpEvent<any>> {
    return this.http.post(`${API_BASE_URL}${API_ENDPOINTS.getPreview}`, formData, {
      reportProgress: true,
      observe: 'events'
    });
  }

  getPreview(payload: any) {
    return this.http.post(
      `${API_BASE_URL}${API_ENDPOINTS.getPreview}`,
      payload,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  private uncheckCheckbox = signal<boolean>(false);

  setCheckboxValue(value: boolean) {
    this.uncheckCheckbox.set(value);
  }

  getCheckboxValue() {
    return this.uncheckCheckbox;
  }

  //persist search text in donation dshboard

  private searchText = new BehaviorSubject("");

  setSearchText(text: string) {
    this.searchText.next(text);
  }

  getSearchText() {
    return this.searchText.asObservable();
  }

  // amount filter

  private amountFilter = new BehaviorSubject({min: "", max: ""});

  setAmountFilter(min: string, max: string) {
    this.amountFilter.next({min, max});
  }

  getAmountFilter() {
    return this.amountFilter.asObservable();
  }

  clearFilterData() {
    if(this.router.url.includes("donation")) {
      this.setDate({ startDate: "", endDate: "" });
      this.setProjectFilter({ projectSearch: "", projectIds: ""})
      this.setResetBtnState({btnState: true});
      this.setSearchText("");
      this.setPendingTransDate({startDate:"",endDate:""})
      this.setResetBtnStatePendingTrans({btnState:true})
      this.setAmountFilter("", "")
    }
  }
}
