import { Pipe, PipeTransform } from '@angular/core';
import { IndianNumberFormatPipe } from './indian-number-format.pipe';

@Pipe({
  name: 'indianCurrencyUnit'
})
export class IndianCurrencyUnitPipe implements PipeTransform {

  constructor(private indianNumberFormat: IndianNumberFormatPipe) {
    
  }

  transform(value: number): string {
    if (value >= 10000000) {
      // Convert to Crore
      const croreValue = value / 10000000;
      return croreValue % 1 === 0 ? croreValue.toFixed(0) + ' Cr' : Math.round(croreValue * 100) / 100 + ' Cr';
    } else if (value >= 100000) {
      // Convert to Lakh
      const lakhValue = value / 100000;
      return lakhValue % 1 === 0 ? lakhValue.toFixed(0) + ' Lac' : Math.round(lakhValue * 100) / 100 + ' Lac';
    } else if( value <= 99999) {
      return this.indianNumberFormat.transform(value)
    }
    return value.toString();
  }

}
