<section class="personal-details">
  <div class="form__header">
    <span class="text-lg-medium"> Institutional Details</span>
    <button mat-icon-button (click)="gotoEdit(2)" *ngIf="isPreviewForm">
      <mat-icon
        svgIcon="edit"
        class="edit-icon"
      ></mat-icon>
    </button>
  </div>
  <div class="personal-details__form">
    <form
      [formGroup]="instituteDetailsForm"
      (ngSubmit)="proceedHandler()"
      autocomplete="off"
      novalidate
    >
      <div class="field-group">
        <ng-container *ngIf="checkForField('degree')">
          <div class="input__field">
            <mat-label class="text-sm-light"
              >Degree
              <span class="asterisk" *ngIf="checkForIsRequired('degree', institutionDetails)"
                >*</span
              ></mat-label
            >
            <mat-form-field>
              <mat-select
                formControlName="degree"
                placeholder="Select degree"
                [compareWith]="compareFn"
                (selectionChange)="degreeSelectHandler($event)"
              >
                <input
                  type="text"
                  (keyup)="searchDegreeHandler($event)"
                  placeholder="Search degree"
                  class="dropdown-search__input"
                />
                <mat-option
                  *ngFor="let degree of filteredDegrees"
                  [value]="degree.id"
                  >{{ degree.name }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  instituteDetailsForm.controls['degree'].hasError('required')
                "
              >
                Degree is required
              </mat-error>
            </mat-form-field>
          </div></ng-container
        >
        <ng-container *ngIf="checkForField('department')">
          <div class="input__field">
            <mat-label class="text-sm-light"
              >Department<span
                class="asterisk"
                *ngIf="checkForIsRequired('department', institutionDetails)"
                >*</span
              ></mat-label
            >
            <mat-form-field>
              <mat-select
                formControlName="department"
                placeholder="Select department"
                [compareWith]="compareFn"
                (selectionChange)="departmentSelectHandler($event)"
              >
                <input
                  type="text"
                  (keyup)="searchDepartmentHandler($event)"
                  placeholder="Search department"
                  class="dropdown-search__input"
                />
                <mat-option
                  *ngFor="let department of filteredDepartments"
                  [value]="department.id"
                  >{{ department.name }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  instituteDetailsForm.controls['department'].hasError(
                    'required'
                  )
                "
              >
                Department is required
              </mat-error>
            </mat-form-field>
          </div></ng-container
        >
        <ng-container *ngIf="checkForField('hostel')">
          <div class="input__field">
            <mat-label class="text-sm-light"
              >Hostel
              <span class="asterisk" *ngIf="checkForIsRequired('hostel', institutionDetails)"
                >*</span
              ></mat-label
            >
            <mat-form-field>
              <mat-select
                formControlName="hostel"
                placeholder="Select hostel"
                [compareWith]="compareFn"
                (selectionChange)="hostelSelectHandler($event)"
              >
                <input
                  type="text"
                  (keyup)="searchHostelHandler($event)"
                  placeholder="Search hostel"
                  class="dropdown-search__input"
                />
                <mat-option
                  *ngFor="let hostel of filteredHostels"
                  [value]="hostel.id"
                  >{{ hostel.name }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  instituteDetailsForm.controls['hostel'].hasError('required')
                "
              >
                Hostel is required
              </mat-error>
            </mat-form-field>
          </div></ng-container
        >
        <ng-container *ngIf="checkForField('rollNo')">
          <div class="input__field">
            <mat-label  class="text-sm-light"
              >Roll Number
              <span class="asterisk" *ngIf="checkForIsRequired('rollNo', institutionDetails)"
                >*</span
              ></mat-label
            >
            <mat-form-field appearance="fill" floatLabel="always">
              <input
                matInput
                formControlName="rollNo"
                placeholder="Enter roll Number"
              />
              <mat-error
                *ngIf="instituteDetailsForm.get('rollNo')!.hasError('required')"
              >
                Roll number is required.
              </mat-error>
            </mat-form-field>
          </div></ng-container
        >
      </div>
      <div class="actions">
        <button
          *ngIf="!isPreviewForm"
          mat-flat-button
          disableRipple
          class="button--secondary"
          type="button"
          cdkStepperPrevious
        >
          Previous
        </button>
        <button
          *ngIf="!isPreviewForm"
          mat-flat-button
          disableRipple
          cdkStepperNext
          class="button--primary"
          type="button"
          [disabled]="instituteDetailsForm.invalid"
          (click)="proceedHandler()"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</section>
