import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { ActivatedRoute, Params } from "@angular/router";
import { DonationService } from "src/app/core/services/donation/donation.service";
import { LmsService } from "src/app/core/services/lms/lms.service";
import { PaymentsService } from "src/app/core/services/payments/payments.service";
import { CdkStepperModule } from "@angular/cdk/stepper";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { donorDetailsDropdownValue } from "src/app/core/models/donation";
import { CommonService } from "src/app/pages/lms/services/common-service/common.service";

@Component({
  selector: "iitb-institutional-details-form",
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    CdkStepperModule,
  ],
  templateUrl: "./institutional-details-form.component.html",
  styleUrls: ["./institutional-details-form.component.scss"],
})
export class InstitutionalDetailsFormComponent implements OnInit, OnDestroy {
  @Input() institutionDetails!: any;
  @Input() isPreviewForm!: boolean;
  @Output() editClicked = new EventEmitter();
  filteredDegrees: any;
  selectedDegree: any;
  filteredDepartments: any;
  selectedDepartment: any;
  filteredHostels: any;
  selectedHostel: any;
  selectedFieldsAndFormDetails: any;
  departments!: donorDetailsDropdownValue;
  degrees!: donorDetailsDropdownValue;
  hostels!: donorDetailsDropdownValue;

  instituteDetailsForm: FormGroup<any> = new FormGroup({
    degree: new FormControl(""),
    hostel: new FormControl(""),
    department: new FormControl(""),
    rollNo: new FormControl(""),
  });
  public subscriptions: any[] = [];
  checkForIsRequired = this.commonService.checkForIsRequired;

  constructor(
    public paymentService: PaymentsService,
    private donationService: DonationService,
    private lmsService: LmsService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (!this.isPreviewForm) {
      this.getDropdownValues();
      this.commonService.setDynamicValidators(
        this.instituteDetailsForm,
        this.institutionDetails
      );
    } else {
      const subscription = this.lmsService
        .getPledgeFormDetails()
        .subscribe((res) => {
          this.selectedFieldsAndFormDetails = res;
          this.instituteDetailsForm.disable();
        });
      this.subscriptions.push(subscription);
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription?.unsubscribe());
  }

  searchDegreeHandler(event: any) {
    const searchText: string = event.target.value;
    this.filteredDegrees = this.degrees.filter(
      (degree: { id: number; name: string }) =>
        degree.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  searchDepartmentHandler(event: any) {
    const searchText: string = event.target.value;
    this.filteredDepartments = this.departments.filter(
      (department: { id: number; name: string }) =>
        department.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  searchHostelHandler(event: any) {
    const searchText: string = event.target.value;
    this.filteredHostels = this.hostels.filter(
      (hostel: { id: number; name: string }) =>
        hostel.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  degreeSelectHandler(event: MatSelectChange) {
    this.selectedDegree = event?.value;
  }

  departmentSelectHandler(event: MatSelectChange) {
    this.selectedDepartment = event?.value;
  }

  hostelSelectHandler(event: MatSelectChange) {
    this.selectedHostel = event?.value;
  }
  compareFn(option: { id: string; name: string; year: number }, value: string) {
    return option.name
      ? option.name === value
      : option.year === Number.parseInt(value);
  }
  getDropdownValues() {
    this.donationService.getDegree().subscribe((response) => {
      this.degrees = response.data;
      this.filteredDegrees = this.degrees.slice();

      if (this.degrees.length === 1) {
        this.selectedDegree = this.degrees[0];
        this.instituteDetailsForm.controls["degree"].setValue(
          this.selectedDegree.name
        );
      }
    });

    this.donationService.getDepartment().subscribe((response) => {
      this.departments = response.data;
      this.filteredDepartments = this.departments.slice();

      if (this.departments.length === 1) {
        this.selectedDepartment = this.departments[0];
        this.instituteDetailsForm.controls["department"].setValue(
          this.selectedDepartment.name
        );
      }
    });

    this.donationService.getHostel().subscribe((response) => {
      this.hostels = response.data;
      this.filteredHostels = this.hostels.slice();

      if (this.hostels.length === 1) {
        this.selectedHostel = this.hostels[0];
        this.instituteDetailsForm.controls["hostel"].setValue(
          this.selectedHostel.name
        );
      }
    });
  }

  proceedHandler() {
    this.lmsService.addPledgeFormValues({
      institutionalDetails: this.instituteDetailsForm.value,
    });
  }

  checkForField(fieldName: string) {
    return this.institutionDetails?.find(
      (field: any) => field.fieldName === fieldName
    )?.isSelected;
  }


  gotoEdit(value: number) {
    this.editClicked.emit(value);
  }
}
