import { CdkStepperModule } from "@angular/cdk/stepper";
import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectChange, MatSelectModule } from "@angular/material/select";
import { Country, State } from "country-state-city";
import { LmsService } from "src/app/core/services/lms/lms.service";
import { PaymentsService } from "src/app/core/services/payments/payments.service";
import { CommonService } from "src/app/pages/lms/services/common-service/common.service";

@Component({
  selector: "iitb-personal-details-form",
  templateUrl: "./personal-details-form.component.html",
  styleUrls: ["./personal-details-form.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    CdkStepperModule,
  ],
})
export class PersonalDetailsFormComponent implements OnInit, AfterViewInit {
  @Input() isPreviewForm!: boolean;
  @Input() personalDetails!: any;
  @Output() editClicked = new EventEmitter();

  default: boolean = true;
  contactFieldCountry: string = "IN";
  countryCode: string = "+91";
  phoneNumber: string = "";
  countries: any;
  filteredCountries: any = [];
  filteredCountryFlags: any;
  states: any;
  filteredStates: any;
  cities: any;
  formNumber: any;
  selectedFieldsAndFormDetails: any;
  currencies: any;
  checkForIsRequired = this.commonService.checkForIsRequired;

  public basicInfoForm: FormGroup<any> = new FormGroup({
    name: new FormControl(""),
    email: new FormControl(""),
    contact: new FormControl(""),
    addressDetails: new FormGroup({
      country: new FormControl(""),
      state: new FormControl(""),
      city: new FormControl(""),
      zipCode: new FormControl(""),
      flatNo: new FormControl(""),
      area: new FormControl(""),
    }),
  });
  constructor(
    public paymentService: PaymentsService,
    private lmsService: LmsService,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (!this.isPreviewForm) {
      this.getCountries();
      this.commonService.setDynamicValidators(
        this.basicInfoForm,
        this.personalDetails
      );
    } else {
      this.basicInfoForm.disable();
    }
    this.phoneNumberValidation();
  }

  ngAfterViewInit() {
    if (this.checkForField("contact")) this.setCountryFlag();
  }

  flagDropdownHandler(event: any) {
    this.default = false;
    this.contactFieldCountry = event.isoCode;
    this.countryCode = `+${event.phonecode}`;
    this.basicInfoForm.controls["contact"].setValue(
      `${this.countryCode} ${this.phoneNumber}`
    );
  }

  getCountries(): void {
    let indiaObject = null;
    let usObject = null;

    this.countries = Country.getAllCountries()
      .filter((country) => State.getStatesOfCountry(country.isoCode).length)
      .sort((a, b) => a.name.localeCompare(b.name));

    for (const item of this.countries) {
      if (item.isoCode === "IN" && !indiaObject) {
        indiaObject = item;
      } else if (item.isoCode === "US" && !usObject) {
        usObject = item;
      } else {
        this.filteredCountries.push(item);
      }
    }

    if (usObject) this.filteredCountries?.unshift(usObject);
    if (indiaObject) this.filteredCountries?.unshift(indiaObject);
    this.filteredCountryFlags = this.filteredCountries;
  }

  searchCountryFlagHandler(event: any) {
    const { value } = event.target;
    this.filteredCountryFlags = this.countries.filter((country: any) =>
      country.name.toLowerCase().includes(value.toLowerCase())
    );
    this.contactFieldCountry = "IN";
    this.countryCode = "+91";
    this.basicInfoForm.controls["contact"].setValue(
      `${this.countryCode} ${this.phoneNumber}`
    );
  }

  input(event: any) {
    const number = event.target.value;
    this.phoneNumber = number.split(" ")[1] ?? "";
    this.basicInfoForm.controls["contact"].setValue(
      `${this.countryCode} ${this.phoneNumber}`
    );
  }

  phoneNumberValidation() {
    const contactControl = this.basicInfoForm.get("contact");
    contactControl?.valueChanges.subscribe({
      next: (value: string) => {
        const currentCountryCode = this.countryCode;
        if (value.length === currentCountryCode.length + 1) {
          contactControl.setErrors({ required: true });
          if (!this.checkForIsRequired("contact", this.personalDetails)) {
            contactControl.setErrors(null);
          }
        }
      },
    });
  }

  selectHandler(event: MatSelectChange) {
    const selectedCountry = this.countries.find(
      (country: any) => country.name === event.value
    );
    this.getStates(selectedCountry?.isoCode);
  }

  getStates(isoCode: string, defaultState?: string) {
    this.states = State.getStatesOfCountry(isoCode);
    this.filteredStates = this.states.slice();
    this.basicInfoForm.controls["state"]?.enable();

    if (defaultState)
      this.basicInfoForm.controls["state"].setValue(defaultState);
  }

  searchCountryHandler(event: any) {
    const searchText: string = event.target.value;
    this.filteredCountries = this.countries.filter((country: any) =>
      country.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  searchStateHandler(event: any) {
    const searchText: string = event.target.value;
    this.filteredStates = this.states.filter((state: any) =>
      state.name.toLowerCase().includes(searchText.toLowerCase())
    );
  }

  setCountryFlag() {
    const element = this.elRef.nativeElement.querySelector(
      ".mat-mdc-select-value"
    );
    this.renderer.setStyle(
      element,
      "background-image",
      `url("http://purecatamphetamine.github.io/country-flag-icons/3x2/${this.contactFieldCountry}.svg")`
    );
    this.renderer.setStyle(element, "background-size", "21px 14px");
    this.renderer.setStyle(element, "background-repeat", "round");
  }

  checkForField(fieldName: string) {
    return this.personalDetails?.find(
      (field: any) => field.fieldName === fieldName
    )?.isSelected;
  }

  gotoEdit(value: number) {
    this.editClicked.emit(value);
  }

  proceedHandler() {
    if (!this.checkForIsRequired("contact", this.personalDetails)) {
      const contactControl = this.basicInfoForm.get("contact");
      const currentCountryCode = this.countryCode;
      if (contactControl?.value.length === currentCountryCode.length + 1) {
        contactControl.setValue("");
      }
    }

    this.lmsService.addPledgeFormValues({
      personalDetails: this.basicInfoForm.value,
    });
  }
}
