import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators ,ReactiveFormsModule} from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { errorText } from "src/app/shared/common/errorMessages";

@Component({
  selector: 'iitb-step-three',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,MatIconModule,MatFormFieldModule,CdkStepperModule, MatButtonModule, MatInputModule],
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent {
  stepThreeForm = new FormGroup({
		address: new FormControl('', Validators.required),
	});
	errorText = errorText;
	constructor() {}

	ngOnInit(): void {}

	stepThreeSubmit() {}

	showErrorState(formControlName: any) {
		if (formControlName.touched) {
			if (formControlName.hasError('required')) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
}