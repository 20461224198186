<mat-form-field appearance="fill" floatLabel="always" class="search">
  <div class="search__container">
    <div class="search__icon"><mat-icon svgIcon="search"></mat-icon></div>
    <div class="search__box">
      <input
        type="text"
        matInput
        id="search"
        [formControl]="searchFormControl"
        placeholder="Search"
      />
      <mat-icon svgIcon="cross-icon" *ngIf="searchFormControl.value" (click)="clearform()" class="cross-icon"></mat-icon>
    </div>
  </div>
</mat-form-field>
