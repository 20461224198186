import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './components/header/header/header.component';
import { FooterComponent } from './components/footer/footer/footer.component';
import { ProjectLayoutComponent } from './layouts/project-layout/project-layout.component';
import { RouterModule } from '@angular/router';
import { IndianCurrencyUnitPipe } from './pipe/indian-currency-unit.pipe';

// import { HTTP_INTERCEPTORS } from '@angular/common/http';
// import { TokenInterceptor } from './interceptors/token/token.interceptor';



@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ProjectLayoutComponent,
    IndianCurrencyUnitPipe
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: TokenInterceptor,
    //   multi: true
    // }
  ],
})
export class CoreModule { }
