import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { HttpClientModule } from "@angular/common/http";
import { debounceTime, Subscription } from "rxjs";

@Component({
  selector: "iitb-search",
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
  ],
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnChanges, OnInit, OnDestroy {
  subscriptionsArray: Subscription[] = [];
  @Input() searchText = "";
  @Input() debounce: number = 500;
  @Output() searchTextEmitter = new EventEmitter<any>();
  searchFormControl = new FormControl("");

  ngOnChanges(changes: SimpleChanges): void {
    this.searchFormControl.setValue(this.searchText);
  }
  ngOnInit() {
    this.getSearchTextValue();
  }

  getSearchTextValue() {
    this.subscriptionsArray.push(
      this.searchFormControl.valueChanges
        .pipe(debounceTime(this.debounce))
        .subscribe((searchValue) => {
          this.searchTextEmitter.emit(searchValue);
        })
    );
  }

  clearform() {
    this.searchFormControl.reset();
  }
  ngOnDestroy(): void {
    this.subscriptionsArray.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
