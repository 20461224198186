import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';

interface INotificationsData {
  notificationTitle:string,
  notificationDate:string,
  notificationTime:string,
}

@Component({
  selector: 'iitb-notifications-card',
  standalone: true,
  imports: [CommonModule , MatButtonModule, MatCardModule,MatDividerModule],
  templateUrl: './notifications-card.component.html',
  styleUrls: ['./notifications-card.component.scss']
})
export class NotificationsCardComponent {
  @Input() notificationsCard !: INotificationsData[];
  titleColor!:string;

  markAllread() {
    this.titleColor="notification__title--disabled";
  }
}
