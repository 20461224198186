import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[iitbStopInputScroll]',
  standalone : true
})
export class StopInputScrollDirective {

  constructor(private el: ElementRef) { }

  @HostListener('wheel', ['$event'])
  onWheel(event: WheelEvent): void {
    if (this.el.nativeElement === document.activeElement) {
      event.preventDefault();
      window.scrollBy(0, event.deltaY); // Manually handle the scrolling of the page
    }
  }

}
