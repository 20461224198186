import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { API_ENDPOINTS } from "../../structs/api.endpoints.enum";
import { IdonorDropdownData } from "../../models/donation";
import { IPaymentData } from "../../models/payments";

@Injectable({
  providedIn: "root",
})
export class PaymentsService {
  constructor(private http: HttpClient) {}

  //to set and get the values of form fields in the one time donation flow
  private paymentDetails = new BehaviorSubject({});

  addPaymentDetails(details: any) {
    const previousValue = this.paymentDetails.getValue();
    this.paymentDetails.next({ ...previousValue, ...details });
  }

  getPaymentDetails() {
    return this.paymentDetails.getValue();
  }

  getCause(isRecurring: boolean, payload: string[]) {
    return this.http.post<IdonorDropdownData>(
      `${API_ENDPOINTS.project}`,
      payload,
      {
        params: {
          isRecurring,
        },
      }
    );
  }

  getHostels(searchHostel: any) {
    return this.http.get(`${API_ENDPOINTS.getHostels}`, {
      params: {
        searchHostel,
      },
    });
  }

  validateOTP(payload: { otp: string; email: string }) {
    return this.http.post(API_ENDPOINTS.validateOTP, payload);
  }

  // signal to get data based on country selection
  private countrySelection = signal(false);

  setCountrySelection(value: boolean) {
    this.countrySelection.set(value);
  }

  getCountrySelection() {
    return this.countrySelection;
  }

  //one-time payment initialization
  initializePayment(payload: any) {
    return this.http.post<IPaymentData>(
      `${API_ENDPOINTS.paymentInit}`,
      payload
    );
  }

  //signal to get donation details on email validation
  private emailVerified = signal(false);

  setEmailVerifiedStatus(status: boolean) {
    this.emailVerified.set(status);
  }

  getEmailVerifiedStatus() {
    return this.emailVerified;
  }

  // to set and get donor details
  private donordetails = new BehaviorSubject(null);

  setDonorDetails(details: any) {
    this.donordetails.next(details);
  }

  getDonorDetails() {
    return this.donordetails.asObservable();
  }

  //to set and get the values of form fields in the one time donation flow
  private recurringPaymentDetails = new BehaviorSubject({});

  addRecurringPaymentDetails(details: any) {
    const previousValue = this.recurringPaymentDetails.getValue();
    this.recurringPaymentDetails.next({ ...previousValue, ...details });
  }

  getRecurringPaymentDetails() {
    return this.recurringPaymentDetails;
  }

  sendRecurringInformation(payload: any) {
    return this.http.post(`${API_ENDPOINTS.recurringForm}`, payload);
  }
}
