<div class="stepper" [ngClass]="[direction==='vertical' ? 'vertical' : '']">
  <div class="stepper-header">
    <ol class="steps">
      <div class="progress">
        <div class="step-progress">
          <span class="progress-count">{{currentIndex}}/{{steps.length}}</span>
        </div>
        <canvas #canvas width="56px" height="56px" class="canvas"></canvas>
      </div>
      <ng-container *ngFor="let step of steps; let i = index;">
        <li class="step"
        (click)="goToStep(step,i)"
          [ngClass]="getIconClass(step,selectedIndex,i)">
          <ng-container [ngTemplateOutlet]="step.stepLabel.template">
            <ng-content select=".step-bullet"></ng-content>
            <ng-content select=".step-title"></ng-content>
            <ng-content select=".step-content"></ng-content>
          </ng-container>
        </li>
      </ng-container>
    </ol>
  </div>
  <div class="stepper-body">
    <div class="content" *ngFor="let step of _steps; let i = index" [class.current]="selectedIndex === i"
      [@stepTransition]="_getAnimationDirection(i)">
      <ng-container [ngTemplateOutlet]="step.content">
      </ng-container>
    </div>
  </div>
</div>
