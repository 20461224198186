export enum RegexContainer {
  namePattern = "^[ 1-9a-zA-Z.]+$",
  emailPattern = "^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{1,}$",
  addressPattern = "^[a-zA-Z0-9\\s.,/'()&-]+$",
  contactPattern = "^[A-Z0-9]{1,10}$",
  ifscPattern = "^[A-Za-z0-9]{4}0[A-Za-z0-9]{6}$",
  panCardPattern = "^[A-Za-z]{5}\\d{4}[A-Za-z]$",
  ratePattern = "/^-?\\d+(\\.\\d+)?$/",
  passwordPattern = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9!@#$%^&*()_+])[a-zA-Z0-9!@#$%^&*()_+]{6,}$",
  countryStateCityPattern = "^[a-zA-Z ]*$",
  vpaPattern = "^[0-9A-Za-z.-_-]{2,256}@[A-Za-z]{2,64}$",
}
