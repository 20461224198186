<div class="verify__container">
  <div class="verify__email-input">
    <div class="verify__header__container">
      <div class="verify__header">
        <h6>{{ title }}</h6>
        <div class="cross-icon" *ngIf="data?.flow !== 'donor'">
          <mat-icon svgIcon="cross-icon" (click)="closeDialog()"></mat-icon>
        </div>
      </div>
      <div class="text-sm-light verify__subtitle">{{ subTitle }}</div>
    </div>

    <div class="input__field">
      <mat-label for="email" class="text-sm-light">{{ label }}</mat-label>
      <mat-form-field appearance="fill" floatLabel="always">
        <input
          iitbStopInputScroll
          [type]="type === 'EMAIL' ? 'email' : 'number'"
          matInput
          [id]="type === 'EMAIL' ? 'email' : 'otp'"
          [formControl]="type === 'EMAIL' ? emailFormControl : OTPFormControl"
          placeholder="Enter your {{ label }}"
        />
        <mat-error
          *ngIf="
            type === 'EMAIL' &&
              (emailFormControl.hasError('required') ||
                emailFormControl.hasError('email'));
            else OTPValid
          "
        >
          {{ label }} is
          {{ emailFormControl.hasError("required") ? "required" : "invalid" }}
        </mat-error>
        <ng-template #OTPValid>
          <mat-error
            *ngIf="
              type === 'OTP' &&
              (OTPFormControl.hasError('required') ||
                OTPFormControl.hasError('maxlength'))
            "
          >
            {{
              OTPFormControl.hasError("maxlength")
                ? errorMessages.otpLengthMismatch
                : "OTP is required"
            }}
          </mat-error>
        </ng-template>
      </mat-form-field>
    </div>

    <div *ngIf="type === 'OTP'" class="OTP__container">
      <a mat-button (click)="resendOTP()">Resend OTP</a>
    </div>
  </div>

  <div class="privacy-policy__container" *ngIf="type === 'EMAIL'">
    <section class="privacy-policy__title">
      <mat-checkbox [(ngModel)]="checked">
        I agree to the <a (click)="displayPolicy()" class="link"> Privacy Policy</a> for giving my information to this
        portal.
      </mat-checkbox>
    </section>
  </div>

  <div class="action__container">
    <button
      mat-flat-button
      disableRipple
      class="button--primary"
      (click)="dialogAction()"
      [disabled]="
        !(type === 'EMAIL' ? emailFormControl.valid && checked: OTPFormControl.valid)
      "
    >
      {{ action }}
    </button>
  </div>
</div>
