import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";

export const authGuard: CanActivateFn = (route, state) => {
  const url = state.url;
  const authService = inject(AuthService);
  const router = inject(Router);
  const userLoggedIn = authService.isUserLoggedIn();

  // if the url contains reset password or set password
  if (url && (url.includes("reset-password") || url.includes("set-password"))) {
    // if user is logged in
    if (userLoggedIn) {
      router.navigate(["/landing-page"]);
      return false;
    }

    // if url does not contain params
    else if (route?.queryParams["token"]) {
      return true;
    }

    // else navigate to login page
    else {
      router.navigate([""]);
      return false;
    }
  }

  // if url includes login or forgot password
  else if (url && (url.includes("login") || url.includes("forgot-password"))) {
    // if user is logged in
    if (userLoggedIn) {
      router.navigate(["/landing-page"]);
      return false;
    }

    // else direct to the requested page
    else {
      return true;
    }
  }

  // url other than auth
  else {
    if (userLoggedIn) return true;
    else router.navigate([""]);
    return false;
  }
};
