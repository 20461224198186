<div class="container">
    <form [formGroup]="stepFourForm" (ngSubmit)="stepFourSubmit()" autocomplete="off" novalidate #formRef="ngForm">
      <div>
        <label class="label">Phone number</label>
        <mat-form-field appearance="fill" floatLabel="always">
          <input type="text" tabindex="0" matInput formControlName="phoneNumber" placeholder="Phone number..." />
          <mat-icon matSuffix *ngIf="showErrorState(stepFourForm.get('phoneNumber'))"></mat-icon>
          <mat-error *ngIf="stepFourForm.get('phoneNumber')?.hasError('required')">
            {{ errorText.requiredField }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <label class="label">Email</label>
        <mat-form-field appearance="fill" floatLabel="always">
          <input type="text" tabindex="0" matInput formControlName="email" placeholder="email..." />
          <mat-icon matSuffix svgIcon="alert_circle" *ngIf="showErrorState(stepFourForm.get('email'))"></mat-icon>
          <mat-error *ngIf="stepFourForm.get('email')?.hasError('required')">
            {{ errorText.requiredField }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="stepper-button">
        <div>
          <button mat-flat-button color="primary" disableRipple class="sm" cdkStepperPrevious>Previous</button>
        </div>
        <div>
          <button mat-flat-button color="primary" disableRipple class="sm" [disabled]="!stepFourForm.valid"
            cdkStepperNext>Save</button>
        </div>
      </div>
    </form>
  </div>
