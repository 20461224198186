import { Directive, Input, TemplateRef, HostListener, ElementRef } from "@angular/core";
import { OverlayRef, Overlay, OverlayPositionBuilder } from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import  { TooltipComponent } from "./tooltip.component";
  
  @Directive({
    selector: "[appTooltip]",
    standalone: true,
  })
  export class TooltipDirective {
    @Input("appTooltip") content: TemplateRef<any> | undefined;
    private overlayRef!: OverlayRef;
    private isOverlayAttached: boolean = false;
  
    constructor(
      private overlayPositionBuilder: OverlayPositionBuilder,
      private elementRef: ElementRef,
      private overlay: Overlay
    ) {}
  
    @HostListener("mouseenter")
    onMouseEnter() {
      if (!this.isOverlayAttached) {
        this.show();
      }
    }
  
    @HostListener("mouseleave", ["$event"])
    onMouseLeave(event: MouseEvent) {
      const relatedTarget = event.relatedTarget as HTMLElement;
      if (!this.elementRef.nativeElement.contains(relatedTarget)) {
        this.hide();
      }
    }
  
    show() {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef.nativeElement.lastElementChild)
        .withPositions([
          {
            originX: "center",
            originY: "bottom",
            overlayX: "center",
            overlayY: "top",
          },
        ]);
  
      this.overlayRef = this.overlay.create({ positionStrategy });
  
      const tooltipPortal = new ComponentPortal(TooltipComponent);
  
      const tooltipRef = this.overlayRef.attach(tooltipPortal);
      tooltipRef.instance.content = this.content;
      this.isOverlayAttached = true;
    }
  
    hide() {
      if (this.overlayRef && this.overlayRef.hasAttached()) {
        this.overlayRef.detach();
        this.isOverlayAttached = false;
      }
    }
  }
  