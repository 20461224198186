import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';

@Component({
  selector: 'iitb-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.scss'],
  standalone: true,
})
export class ProfileImageComponent {
  constructor(
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private cd: ChangeDetectorRef
  ) {}

  @Input() initials!: string;
  ngAfterContentChecked() {
    const initial = this.getInitials(this.initials);
    this.renderer.setProperty(
      this.elementRef.nativeElement,
      'innerHTML',
      initial
    );
  }

  getInitials(initials: string): string {
    const namesArray = initials?.split(' ');
    let firstName = '';
    let lastName = '';
    if (namesArray?.length > 0) {
      firstName = namesArray[0];
      if (namesArray?.length > 1) {
        lastName = namesArray[namesArray.length - 1];
      }
    }
    const firstInitial = firstName.charAt(0);
    const lastInitial = lastName.charAt(0);
    return `${firstInitial}${lastInitial}`;
  }
}
