<iitb-header [isLoggedIn]="false" class="header"></iitb-header>
<div class="privacy-page">
  <section class="privacy-page__title">
    <h2 class="title">Privacy Policy</h2>
  </section>

  <section class="privacy-page__body">
    <p class="privacy-page__text">
      Indian Institute of Technology Bombay is thankful to all alumni who
      contribute time, material and/or money to the institute for various
      causes. IIT Bombay maintains strict policies to protect the integrity and
      privacy of personal information gathered from our website and the
      following terms apply to such data access.
    </p>
  </section>

  <section class="privacy-page__body">
    <h3 class="title">What information do we collect?</h3>
    <p class="privacy-page__text">
      When you make a donation to IIT Bombay, we may ask for information such as
      your name, address, email address, phone number and other relevant
      information.
    </p>
  </section>

  <section class="privacy-page__body">
    <h3 class="title">Use of personal information</h3>
    <p class="privacy-page__text">
      1. General browsing of the IIT Bombay website is anonymous and it does not
      register the user’s personal information except the time, date and place
      of visits and the name of internet service provider. This data is used
      only for statistics and diagnosis.
    </p>
    <p class="privacy-page__text">
      2. IIT Bombay keeps the user information strictly confidential, and this
      information is secured safely. All relevant information collected through
      IIT Bombay website is handled and used for specific purposes.
    </p>
    <p class="privacy-page__text">
      3. IIT Bombay uses the information in the following ways:
    </p>

    <ul class="privacy-page__text">
      <li>To verify the information</li>
      <li>To process your donation</li>
      <li>To send you a receipt for your donation</li>
      <li>To send you a thank you note for your donation or volunteering</li>
      <li>To ask for your opinion and/or assistance</li>
      <li>To respond to your questions or comments</li>
      <li>To send you additional information/news about IIT Bombay</li>
      <li>To send you invitations for upcoming events that may interest you</li>
    </ul>

    <p class="privacy-page__text">
      IIT Bombay will not use personal information in any way other than as
      described above.
    </p>
    <p class="privacy-page__text">
      4. You can opt to delete all the information you have provided by simply
      requesting such by e-mail. All information, without exception, will be
      deleted in two working days.
    </p>
  </section>

  <section class="privacy-page__body">
    <h3 class="title">Privacy of e-mail lists</h3>
    <p class="privacy-page__text">
      Individuals who join IIT Bombay’s mailing lists via its website or through
      its campaigning engagements are added to its email database. IIT Bombay
      does not sell, rent, loan, trade, or lease the addresses on our lists to
      anyone.
    </p>
  </section>
  <section class="privacy-page__body">
    <h3 class="title">Cookie Policy</h3>
    <p class="privacy-page__text">
      1. Cookies are pieces of electronic information which will be sent by IIT
      Bombay when a user visits the website. These will be placed in the hard
      disk of the user’s computer and will enable IIT Bombay to recognise the
      user when he/she visits the website again.
    </p>
    <p class="privacy-page__text">
      2. The user can configure his/her browser so that it responds to cookies
      the way he/she deems fit. For example, the user make want to accept all
      cookies, reject them all or get notified when a cookie is sent. The user
      may check his/her browser’s settings to modify cookie behaviour as per
      individual behaviour.
    </p>
    <p class="privacy-page__text">
      3. Please note if a user disables the use of cookies on the web browser or
      removes or rejects specific cookies from IIT Bombay’s website or linked
      sites then he/she may not be able to use the website as it is intended.
    </p>
  </section>

  <section class="privacy-page__body">
    <h3 class="title">Credit / Debit Card Security</h3>
    <p class="privacy-page__text">
      When you donate to IIT Bombay online, we redirect you to the PayUMoney
      Payment Gateway/ SBI Internet Banking. Your card information is secure;
      your credit card number is used only for that particular transaction and
      is not stored.
    </p>
  </section>
  <section class="privacy-page__body">
    <h3 class="title">Security of donation</h3>
    <p class="privacy-page__text">
      The security and confidentiality of your information is our highest
      priority. We use industry-standard SSL (secure socket layer) technology to
      protect your information and provide a safe and secure environment for
      online donations.
    </p>
    <p class="privacy-page__text">
      By using the website or by providing information, you consent to the
      collection and the use of the information you disclose on the website in
      accordance with this Privacy Policy and you shall be bound by the same.
    </p>
    <p class="privacy-page__text">
      For any questions or comments about this Terms & Conditions or to update
      any personally identifiable information on our website, please contact us
      by sending an email to contactus@iitbacr.com
    </p>
    <p class="privacy-page__text">
      IIT Bombay reserves the right to change the Privacy Policy at any time, to
      make sure you are aware, please review this policy periodically.
    </p>
  </section>

  <section class="privacy-page__body">
    <h3 class="title">
      Terms and Conditions for use of IIT Bombay alumni website
    </h3>
    <p class="privacy-page__text">
      Use of this website is provided by IIT Bombay subject to the following
      Terms and Conditions:
    </p>
    <p class="privacy-page__text">
      You agree to use this site only for lawful purposes, and in a manner which
      does not infringe the rights, or restrict, or inhibit the use and
      enjoyment of the site by any third party. Your use of the site constitutes
      your acceptance of the Terms and Conditions.
    </p>
    <p class="privacy-page__text">
      IIT Bombay reserves the rights to change these terms and conditions at any
      time by posting changes online. Your continued use of this site after
      changes are posted constitutes your acceptance of the modified Terms and
      Conditions.
    </p>
    <p class="privacy-page__text">
      This site and the information, names, images, pictures, logos regarding or
      relating to IIT Bombay are provided “as is” without any representation or
      endorsement made and without warranty of any kind whether express or
      implied. In no event will IIT Bombay be liable for any damages including,
      without limitation, indirect or consequential damages, or any damages
      whatsoever arising from the use or in connection with such use or loss of
      use of the site, whether in contract or in negligence.
    </p>
    <p class="privacy-page__text">
      IIT Bombay does not warrant that the functions contained in the material
      contained in this site will be uninterrupted or error free, that defects
      will be corrected, or that this site or the server that makes it available
      are free of viruses or bugs or represents the full functionality,
      accuracy, and reliability of the materials.
    </p>
  </section>
  <section class="privacy-page__body">
    <h3 class="title">Copyright restrictions:</h3>
    <p class="privacy-page__text">
      Commercial use or publication of all or any item displayed without prior
      authorization from IIT Bombay is strictly prohibited. Nothing contained
      herein shall be construed as conferring any license by IIT Bombay to use
      any item displayed.
    </p>
    <p class="privacy-page__text">
      Documents may be copied for personal use only on the condition that
      copyright and source indications are also copied, no modifications are
      made, and the document is copied entirely. However, some documents and
      photos may have been published on this site with the permission of the
      relevant copyright owners (who are not IIT Bombay). All rights are
      reserved on these documents and permission to copy them must be requested
      from the copyright owners (the sources are indicated within these
      documents/photographs).
    </p>
    <p class="privacy-page__text">
      IIT Bombay takes no responsibility for the content of external Internet
      sites. Other websites that we provide link to are owned and operated by
      third parties and IIT Bombay has no control over them. The fact that we
      include links to other websites does not mean that IIT Bombay approves of
      or endorses any other third-party website or the content of that website.
      We accept no liability for any statements, information, products or
      services that are published on or are accessible through any websites
      owned or operated by third parties.
    </p>
    <p class="privacy-page__text">
      Any communication or material that you transmit to, or post on, any public
      area of the site including any data, questions, comments, suggestions, or
      the like, is, and will be treated as non-confidential and non-proprietary
      information. If there is any conflict between these terms and conditions
      and rules and/or specific terms of use appearing on this site relating to
      specific material, then the latter shall prevail.
    </p>
    <p class="privacy-page__text">
      If these terms and conditions are not accepted in full, the use of this
      site must be terminated immediately.
    </p>
  </section>
</div>
<iitb-footer [portal]="'public'" class="footer"></iitb-footer>
