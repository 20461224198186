import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from "../../services/auth/auth.service";
import { inject } from "@angular/core";
import { LocalStorageData } from "../../utils/localstorage.data";
import { UtilityService } from "../../utils/utility.service";

export const routeGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const utilityService = inject(UtilityService);
  const url = state.url;

  const userPermissions =
    LocalStorageData.getDataFromLocalStorage("permissions");
  const permissions: any = JSON.parse(userPermissions!);

  const donationPermissions = permissions?.DONATIONS;

  const arjunaPermissions = permissions?.ARJUNA;

  const pledgePermissions = permissions?.PLEDGE;

  const drf = permissions?.RECRUITMENT.filter(
    (permission: any) => permission.DRF
  );

  const centre_project = permissions?.RECRUITMENT.filter(
    (permission: any) => permission.CENTRE_PROJECT
  );

  const drfPermissions = drf[0]?.DRF || [];
  const centre_projectPermissions = centre_project[0]?.CENTER_PROJECT || [];

  const redirectHandler = () => {
    if (permissions?.multiRole) router.navigate(["landing-page"]);
    else {
      utilityService.onHomeClick();
    }
  };

  if (!userPermissions) {
    router.navigate([""]);
    return false;
  }

  if (url.includes("admin")) {
    if (permissions?.SUPER_ADMIN) return true;
    else {
      redirectHandler();
      return false;
    }
  }

  if (url.includes("landing-page")) {
    if (permissions?.multiRole) return true;
    else {
      redirectHandler();
      return false;
    }
  }

  if (url.includes("donation")) {
    if (url.includes("offline")) {
      if (donationPermissions.includes("PERM_OFFLINE_DONATION")) return true;
      else {
        redirectHandler();
        return false;
      }
    } else if (url.includes("bulk-upload")) {
      if (donationPermissions.includes("PERM_BULK_UPLOAD")) return true;
      else {
        redirectHandler();
        return false;
      }
    } else {
      if (donationPermissions.includes("PERM_VIEW_DASHBOARD")) return true;
      else {
        redirectHandler();
        return false;
      }
    }
  }

  if (url.includes("recruitment")) {
    if (url.includes("DRF")) {
      if (url.includes("createNewJob")) {
        if (drfPermissions.includes("PERM_CREATE_JOB")) return true;
        else {
          redirectHandler();
          return false;
        }
      } else {
        if (drfPermissions.includes("PERM_VIEW_APPLICANT")) return true;
        else {
          redirectHandler();
          return false;
        }
      }
    } else if (url.includes("Centre")) {
      if (url.includes("createNewJob")) {
        if (centre_projectPermissions.includes("PERM_CREATE_JOB")) return true;
        else {
          redirectHandler();
          return false;
        }
      } else {
        if (drfPermissions.includes("PERM_VIEW_APPLICANT")) return true;
        else {
          redirectHandler();
          return false;
        }
      }
    } else {
      if (permissions.RECRUITMENT.length === 0) {
        redirectHandler();
        return false;
      } else return true;
    }
  }

  if (url.includes("arjuna")) {
    if (arjunaPermissions.includes("PERM_VIEW_DASHBOARD_ARJUNA")) return true;
    else {
      redirectHandler();
      return false;
    }
  }

  if (url.includes("lms")) {
    if (pledgePermissions.length) return true;
    else {
      redirectHandler();
      return false;
    }
  }

  redirectHandler();
  return false;
};
