<div class="container">
    <form [formGroup]="stepTwoForm" (ngSubmit)="stepTwoSubmit()" autocomplete="off" novalidate #formRef="ngForm">
      <div>
        <label class="label">City</label>
        <mat-form-field appearance="fill" floatLabel="always">
          <input type="text" tabindex="0" matInput formControlName="city" placeholder="City Name..." />
          <mat-icon matSuffix svgIcon="alert_circle" *ngIf="showErrorState(stepTwoForm.get('city'))"></mat-icon>
          <mat-error *ngIf="stepTwoForm.get('city')?.hasError('required')">
            {{ errorText.requiredField }}
          </mat-error>
        </mat-form-field>
      </div>
      <div>
        <label class="label">Country</label>
        <mat-form-field appearance="fill" floatLabel="always">
          <input type="text" tabindex="0" matInput formControlName="country" placeholder="Country name..." />
          <mat-icon matSuffix  *ngIf="showErrorState(stepTwoForm.get('country'))"></mat-icon>
          <mat-error *ngIf="stepTwoForm.get('country')?.hasError('required')">
            {{ errorText.requiredField }}
          </mat-error>
        </mat-form-field>
      </div>
      <div class="stepper-button">
        <div>
          <button mat-flat-button color="primary" disableRipple class="sm" cdkStepperPrevious>Previous</button>
        </div>
        <div>
          <button mat-flat-button color="primary" disableRipple class="sm" [disabled]="!stepTwoForm.valid"
            cdkStepperNext>Next</button>
        </div>
      </div>
    </form>
  </div>
