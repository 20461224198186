import { ITableConfig } from "src/app/core/models/data-table";
import { Headers } from "./form-lists/forms";

export const PERSONAL_DETAILS_FORM_FIELDS = [
  {
    title: "Full Name",
    isSelected: true,
    isRequired: true,
    fieldName: "name",
    isEditable: false,
  },
  {
    title: "Email",
    isSelected: true,
    isRequired: true,
    fieldName: "email",
    isEditable: false,
  },
  {
    title: "Phone Number with Country code",
    isSelected: true,
    isRequired: false,
    fieldName: "contact",
    isEditable: true,
  },
  {
    title: "Country of Residence",
    isSelected: true,
    isRequired: true,
    fieldName: "country",
    isEditable: false,
  },
  {
    title: "State",
    isSelected: true,
    isRequired: false,
    fieldName: "state",
    isEditable: true,
  },
  {
    title: "Town/City",
    isSelected: true,
    isRequired: false,
    fieldName: "city",
    isEditable: true,
  },
  {
    title: "Pin Code",
    isSelected: true,
    isRequired: false,
    fieldName: "zipCode",
    isEditable: true,
  },

  {
    title: "Area, Street, Sector, Village",
    isSelected: true,
    isRequired: false,
    fieldName: "area",
    isEditable: true,
  },
  {
    title: "Flat/Plot no., Building",
    isSelected: true,
    isRequired: false,
    fieldName: "flatNo",
    isEditable: true,
  },
];

export const INSTITUTIONAL_DETAILS_FORM_FIELDS = [
  {
    title: "Degree",
    isSelected: true,
    isRequired: true,
    fieldName: "degree",
    isEditable: false,
  },
  {
    title: "Department",
    isSelected: true,
    isRequired: true,
    fieldName: "department",
    isEditable: false,
  },
  {
    title: "Hostel",
    isSelected: true,
    isRequired: false,
    fieldName: "hostel",
    isEditable: true,
  },
  {
    title: "Roll Number",
    isSelected: true,
    isRequired: false,
    fieldName: "rollNo",
    isEditable: true,
  },
];

export const PLEDGE_DETAILS_FORM_FIELDS = [
  {
    title: "I'd like to pledge in",
    isSelected: true,
    isRequired: true,
    fieldName: "currencyId",
    isEditable: false,
  },
  {
    title:
      "I would like to pledge in shares and stocks (For US residents only)",
    isSelected: true,
    isRequired: true,
    fieldName: "isPledgeInSharesAndStocks",
    isEditable: true,
  },
  {
    title: "Amount I'd like to pledge",
    isSelected: true,
    isRequired: true,
    fieldName: "amountToPledge",
    isEditable: false,
  },
  {
    title: "Amount I'd like to pay now",
    isSelected: true,
    isRequired: true,
    fieldName: "amountToPayNow",
    isEditable: true,
  },
  {
    title: "Payment Cycle",
    key: "payment_cycle",
    fieldName: "cycleType",
    isSelected: true,
    isRequired: false,
    isEditable: true,

    fields: [
      {
        hasExtraFields: true,
        dropdownData: ["Monthly, Weekly"],
      },
      {
        hasExtraFields: true,
        dropdownData: ["Monthly", "Weekly"],
      },
    ],
  },
  {
    title: "Pledge completion date",
    key: "pledge_completion_date",
    fieldName: "pledgeCompletionDate",
    isSelected: true,
    isRequired: true,
    isEditable: true,
    fields: [{ hasExtraFields: true }, { hasExtraFields: true }],
  },
  {
    title: "Any matching gift from company",
    fieldName: "isMatchingGiftFromCompany",
    isEditable: true,

    fields: [
      {
        title: "Company matching gift amount",
        isSelected: true,
        // isRequired: false,
        hasExtraFields: false,
        fieldName: "companyMatchingGiftAmount",
        isEditable: false,
      },
      {
        title: "Company name",
        isSelected: true,
        // isRequired: false,
        hasExtraFields: false,
        fieldName: "companyName",
        isEditable: false,
      },
    ],
    isSelected: true,
    isRequired: false,
  },
  {
    title: "Are you interested in donating non-monetary assets, such as land, equipment, or supplies?",
    isSelected: true,
    isRequired: false,
    fieldName: "transferOtherThanMoney",
    isEditable: true,
  },
  {
    title: "Could you tentatively share with us when would you prefer to start your payments?",
    isSelected: true,
    isRequired: false,
    fieldName: "installmentStartsFrom",
    isEditable: true,
  },
  {
    title: "Anonymous pledge",
    isSelected: true,
    isRequired: false,
    fieldName: "isAnonymous",
    isEditable: true,
  },

  {
    title: "Are you interested to know about Bequest through your WILL?",
    isSelected: true,
    isRequired: false,
    isEditable: true,

    fieldName: "isInterestedInBequestThroughWill",
  },
  {
    title: "Comments",
    isSelected: true,
    isRequired: false,
    isEditable: true,

    fieldName: "commentText",
  },
];

export const PLEDGE_FORM = {
  ADMIN: "ADMIN",
};

export const ONGOING_FORM_HEADERS: Headers = {
  title: "Pledge Form Name",
  formStartDateTime: "Date of publish",
  formEndDateTime: "Form end date",
  pledgeEndDate: "Pledge end date",
  noOfResponses: "No. of responses",
  action: "Action",
};

export const PREVIOUS_FORM_HEADERS = {
  title: "Pledge Form Name",
  sanitizedDescription: "Description",
};

export const SCHEDULED_FORM_HEADERS: Headers = {
  title: "Pledge Form Name",
  formStartDateTime: "Date of publish",
  formEndDateTime: "Form end date",
  pledgeEndDate: "Pledge end date",
  action: "Action",
};

export const ARCHIVED_FORM_HEADERS: Headers = {
  title: "Pledge Form Name",
  formStartDateTime: "Date of publish",
  formEndDateTime: "Form end date",
  pledgeEndDate: "Pledge end date",
  noOfResponses: "No. of responses",
  action: "Action",
};

export const ONGOING_FORM_CONFIG: ITableConfig = {
  columns: [
    {
      type: "text",
      sorting: true,
    },
    {
      type: "dateFormat",
    },
    {
      type: "dateFormat",
    },
    {
      type: "dateFormat",
    },
    {
      type: "text",
    },
    {
      type: "actions",
    },
  ],
  actions: {
    ic_copy: "copy",
    archive: "archive",
  },
};

export const ARCHIVED_FORM_CONFIG: ITableConfig = {
  columns: [
    {
      type: "text",
      sorting: true,
    },
    {
      type: "dateFormat",
    },
    {
      type: "dateFormat",
    },
    {
      type: "dateFormat",
    },
    {
      type: "text",
    },
    {
      type: "actions",
    },
  ],
  actions: {
    ic_copy: "copy",
    un_archive: "unArchive",
  },
};

export const PREVIOUS_FORM_CONFIG: ITableConfig = {
  columns: [
    {
      type: "text",
      sorting: true,
    },
    {
      type: "text",
    },
  ],
};

export const SCHEDULED_FORM_CONFIG: ITableConfig = {
  columns: [
    {
      type: "text",
      sorting: true,
    },
    {
      type: "dateFormat",
    },
    {
      type: "dateFormat",
    },
    {
      type: "dateFormat",
    },

    {
      type: "actions",
    },
  ],
  actions: {
    ic_copy: "copy",
    archive: "archive",
  },
};

export const PLEDGE_FORM_RESPONSES_HEADERS = {
  isBatchLeader: "Assign as BL",
  name: "Respondent Name",
  email: "Email",
  contact: "Contact Number",
  formSubmissionDate: "Form submit date",
  pledgeAmount: "Pledge amount",
  pledgeCompletionDate: "Pledge completion date",
};

export const PLEDGE_FORM_RESPONSES_CONFIG = {
  columns: [
    {
      type: "checkbox",
    },
    {
      type: "text",
    },
    {
      type: "text",
    },
    {
      type: "text",
    },
    {
      type: "dateFormat",
    },
    {
      type: "text",
    },
    {
      type: "dateFormat",
    },

  ],
  isBatchLeader: "Assign as BL",
  name: "Respondent Name",
  email: "Email",
  contact: "Contact Number",
  formSubmissionDate: "Form submit date",
  pledgeAmount: "Pledge amount",
  pledgeCompletionDate: "Pledge completion date",
  country: "Country of residence",
  state: "State",
  degree: "Degree",
  department: "Department",
  hostel: "Hostel",
  rollNo: "Roll Number",
  currency: "Pledge in",
  isPledgeInSharesAndStocks: "Pledge in shares and stocks",
  amountToPayNow: "Amount to pay now",
  paymentCycle: "Payment cycle",
  isMatchingGiftFromCompany: "Matching gift from company",
  companyMatchingGiftAmount: "Matching gift amount",
  companyName: "Company name",
  transferOtherThanMoney: "Transfer other than money",
  installmentStartDate: "Installment start date",
  isInterestedInBequestThroughWill: "Interested about Bequest through will",
  commentText: "Comments",
};

export enum FORM_TYPE {
  ONGOING = 'ONGOING',
  SCHEDULED = 'SCHEDULED',
  ARCHIVED = 'ARCHIVED',
  PREVIOUS = 'PREVIOUS'
};