export class LocalStorageData {
  //method to get the data from the local storage
   static getDataFromLocalStorage(key: string): string | null {
    return localStorage.getItem(key);
  }

  //method to save the data to the local storage
  static saveDataToLocalStorage(key: string, value: string) : void {
    localStorage.setItem(key, value);
  }

  //method to remove the data from the local storage.
  static removeDataFromLocalStorage(key:string) : void {
    localStorage.removeItem(key);
  }

  //method to clear the data from the local storage.
  static clearDataFromLocalStorage() : void {
    localStorage.clear();
  }
}
