import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormGroup, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MAT_SELECT_CONFIG, MatSelectModule } from "@angular/material/select";
import { PaymentsService } from "src/app/core/services/payments/payments.service";
import { TextEditorComponent } from "src/app/shared/components/text-editor/text-editor.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { LmsService } from "src/app/core/services/lms/lms.service";
import { MatButtonModule } from "@angular/material/button";
import { CdkStepper, CdkStepperModule } from "@angular/cdk/stepper";
import { SnackbarService } from "src/app/core/services/snackbar/snackbar.service";
import { ActivatedRoute } from "@angular/router";
import { MatNativeDateModule } from "@angular/material/core";
import { StepperComponent } from "src/app/shared/components/stepper/stepper.component";
import { PersonalDetailsFormComponent } from "../personal-details-form/personal-details-form.component";
import { InstitutionalDetailsFormComponent } from "../institutional-details-form/institutional-details-form.component";
import { PledgeDetailsFormComponent } from "../pledge-details-form/pledge-details-form.component";
import { FormValuesPreviewComponent } from "../form-values-preview/form-values-preview.component";
import { DonationService } from "src/app/core/services/donation/donation.service";
import { LoaderService } from "src/app/core/services/loader/loader.service";
import { MatStepper } from "@angular/material/stepper";
import { FORM_TYPE } from "../../pledge-form.constants";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "iitb-pledge-form-filling",
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TextEditorComponent,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatButtonModule,
    CdkStepperModule,
    MatNativeDateModule,
    StepperComponent,
    PersonalDetailsFormComponent,
    InstitutionalDetailsFormComponent,
    PledgeDetailsFormComponent,
    FormValuesPreviewComponent,
    FormValuesPreviewComponent,
  ],
  templateUrl: "./pledge-form-filling.component.html",
  styleUrls: ["./pledge-form-filling.component.scss"],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: "flag-overlay" },
    },
    { provide: CdkStepper },
  ],
})
export class PledgeFormFillingComponent implements OnInit, OnDestroy {
  @Input() previewForm!: FormGroup;
  @Input() isPreviewForm: boolean = true;
  @Output() editClicked = new EventEmitter();
  @ViewChild("cdkStepper") cdkStepper!: MatStepper;
  @ViewChild('stepperContainer', { static: true }) stepperContainer!: ElementRef;
  formType: string | null = FORM_TYPE.ONGOING;
  otherFormData: any = {};
  selectedFieldsAndFormDetails: any;
  stepperIconName: any;
  currentStepperStep: number = 0;
  formId: any;
  batchDetails = {} as any;
  isFormSubmissionSuccess!: boolean;
  donationLinks: any;
  currencies: any[] = [];
  public subscriptions: any[] = [];
  constructor(
    public paymentService: PaymentsService,
    private lmsService: LmsService,
    private donationService: DonationService,
    private _snackbar: SnackbarService,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private title: Title
  ) {}

  ngOnInit(): void {
    if (this.isPreviewForm) {
      const subscription = this.lmsService
        .getPledgeFormDetails()
        .subscribe((res) => {
          this.selectedFieldsAndFormDetails = res;
        });
      this.subscriptions.push(subscription);
    }
    this.formId = this.route.snapshot.paramMap.get("formId");
    this.formType = this.base64Decode(this.route.snapshot.queryParamMap.get("t"));
    if (this.formId) this.getFormDetails();
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription?.unsubscribe());
  }

  getFormDetails() {
    this.loaderService.showLoader();
    this.subscriptions.push(
      this.lmsService.getPledgeFormById(this.formId).subscribe({
        next: (res: any) => {
          this.selectedFieldsAndFormDetails = res.data;
          this.otherFormData = { ...res, data: null };
          const formTitle = this.selectedFieldsAndFormDetails.title;
          this.lmsService.addPledgeFormValues({
            pledgeFormId: this.formId,
            batchId: this.selectedFieldsAndFormDetails.batch,
          });
          if (formTitle) this.getBatch(this.selectedFieldsAndFormDetails.batch, formTitle);
        }
      })
    );
    this.subscriptions.push(
      this.donationService.getCurrency().subscribe({
        next: (currency) => {
          const filteredData = currency.data.filter((value) => value.abbr !== 'NULL');
          this.currencies = [...filteredData];
        },
        complete: () => {
          setTimeout(() => {
            this.loaderService.hideLoader();
          }, 800)
        }
      })
    );
  }

  getBatch(batchId: string, title: string) {
    this.lmsService.getBatch(batchId).subscribe((res: any) => {
      this.batchDetails = {
        year: res?.data?.year,
        title
      };
      this.title.setTitle(title);
    });
  }

  getStepperIcon(icon: any) {
    this.stepperIconName = icon;
  }

  handleStepChange(step: number) {
    this.currentStepperStep = step;
    this.stepperContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  previousHandler() {
    throw new Error("Method not implemented.");
  }

  isArray(value: any): boolean {
    return Array.isArray(value);
  }

  formatDonationLinks(currencyData: any[], donationLinks: any[]) {    
    const currencyMap = currencyData.reduce((map, currency) => {
      map[currency.id] = currency.abbr;
      return map;
    }, {});
  
    return donationLinks
      .map(linkItem => ({
        link: linkItem.link,
        currencyId: linkItem.currencyId,
        abbr: currencyMap[linkItem.currencyId] || ''
      }))
      .filter(currency => currency.abbr);
  }
  

  gotoEdit(stepNumber: number) {
    this.cdkStepper.selectedIndex = stepNumber;
  }
  proceedHandler() {
    const form = {
      title:
        this.selectedFieldsAndFormDetails.pledgeFormInformation.pledgeTitle,
      description:
        this.selectedFieldsAndFormDetails.pledgeFormInformation
          .pledgeDescription,
      batch: this.selectedFieldsAndFormDetails.pledgeFormInformation.batch,
      personalDetailsOptions: this.selectedFieldsAndFormDetails.personalDetails,
      institutionalDetailsOptions:
        this.selectedFieldsAndFormDetails.institutionDetails,
      pledgeDetailsOptions: this.selectedFieldsAndFormDetails.pledgeDetails,
      miscellaneousOptions: null,
      formEndDateTime:
        this.selectedFieldsAndFormDetails.pledgeFormInformation.startDate,
      formStartDateTime:
        this.selectedFieldsAndFormDetails.pledgeFormInformation.endDate,
      formURL: "",
      pledgeCompletionDateTime:
        this.selectedFieldsAndFormDetails.pledgeFormDetails
          .pledgeCompletionDate,
      supportEmail: this.selectedFieldsAndFormDetails.supportMailId,
    };
    this.lmsService.createPledgeForm(form).subscribe({
      next: (response) => {
        this._snackbar.openSnackBar("Form created successfully", "success");
      },
      error: (e) => {
        console.log(e);
      },
    });
  }

  onFormSubmit(event: any) {
    this.isFormSubmissionSuccess = event.status;
    if (event.status && event.data) {
      this.donationLinks = this.formatDonationLinks(this.currencies, event.data.donationLink);
    }
    this.stepperContainer.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  base64Decode(value: string | null) {
    if (!value) return value;
    return atob(value);
  }
}
