import { CommonModule } from "@angular/common";
import { Component, Inject } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from "@angular/material/snack-bar";

@Component({
  selector: "iitb-snackbar",
  standalone: true,
  imports: [CommonModule, MatIconModule],
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.scss"],
})
export class SnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    public snackbarRef: MatSnackBarRef<SnackbarComponent>
  ) {}

  getSnackbarIcon() {
    if (this.data.type === "snackbar-error") return "cross-error";
    else return "cross-white";
  }
}
