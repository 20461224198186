<div class="container">
  <!-- Personal Details Section -->
  <div class="section">
    <div class="header">
      <span class="text-lg-bold">Personal Details</span>
      <button (click)="gotoEdit(0)" mat-icon-button>
        <mat-icon
          svgIcon="edit"
        ></mat-icon>
      </button>
    </div>
    <div class="row">
      <div class="detail-row" *ngIf="pledgeForm?.personalDetails?.name">
        <div class="heading">Full Name:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.personalDetails?.name }}
        </div>
      </div>
      <div class="detail-row" *ngIf="pledgeForm?.personalDetails?.email">
        <div class="heading">Email:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.personalDetails?.email }}
        </div>
      </div>
      <div class="detail-row" *ngIf="pledgeForm?.personalDetails?.contact">
        <div class="heading">Contact Number:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.personalDetails?.contact }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.personalDetails?.addressDetails?.country"
      >
        <div class="heading">Country Of Residence:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.personalDetails?.addressDetails?.country }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.personalDetails?.addressDetails?.state"
      >
        <div class="heading">State:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.personalDetails?.addressDetails?.state }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.personalDetails?.addressDetails?.city"
      >
        <div class="heading">Town/City:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.personalDetails?.addressDetails?.city }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.personalDetails?.addressDetails?.zipCode"
      >
        <div class="heading">Pin Code:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.personalDetails?.addressDetails?.zipCode }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.personalDetails?.addressDetails?.area"
      >
        <div class="heading">Area, Street, Sector, Village:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.personalDetails?.addressDetails?.area }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.personalDetails?.addressDetails?.flatNo"
      >
        <div class="heading">Flat/Plot no., Building:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.personalDetails?.addressDetails?.flatNo }}
        </div>
      </div>
    </div>
  </div>

  <!-- Institution Details Section -->
  <div class="section">
    <div class="header">
      <span class="text-lg-bold">Institution Details</span>
      <button (click)="gotoEdit(1)" mat-icon-button>
        <mat-icon
          svgIcon="edit"
        ></mat-icon>
      </button>
    </div>
    <div class="row">
      <div class="detail-row" *ngIf="pledgeForm?.institutionalDetails?.degree">
        <div class="heading">Degree:</div>
        <div class="text-md-medium">{{ degreeName }}</div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.institutionalDetails?.department"
      >
        <div class="heading">Department:</div>
        <div class="text-md-medium">{{ departmentName }}</div>
      </div>
      <div class="detail-row" *ngIf="pledgeForm?.institutionalDetails?.hostel">
        <div class="heading">Hostel:</div>
        <div class="text-md-medium">{{ hostelName }}</div>
      </div>
      <div class="detail-row" *ngIf="pledgeForm?.institutionalDetails?.rollNo">
        <div class="heading">Roll Number:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.institutionalDetails?.rollNo }}
        </div>
      </div>
    </div>
  </div>

  <!-- Pledge Details Section -->
  <div class="section">
    <div class="header">
      <span class="text-lg-bold">Pledge Details</span>
      <button mat-icon-button (click)="gotoEdit(2)">
        <mat-icon
          svgIcon="edit"
        ></mat-icon>
      </button>
    </div>
    <div class="row">
      <div class="detail-row" *ngIf="pledgeForm?.pledgeDetails?.currencyId">
        <div class="heading">I'd like to pledge in:</div>
        <div class="text-md-medium">{{ currency.abbr! }}</div>
      </div>
      <div
        class="detail-row"
        *ngIf="
          pledgeForm?.pledgeDetails?.isPledgeInSharesAndStocks !== undefined
        "
      >
        <div class="heading">I would like to pledge in shares and stocks:</div>
        <div class="text-md-medium">
          {{
            pledgeForm?.pledgeDetails?.isPledgeInSharesAndStocks ? "Yes" : "No"
          }}
        </div>
      </div>
      <div class="detail-row" *ngIf="pledgeForm?.pledgeDetails?.amountToPledge">
        <div class="heading">Amount I'd like to pledge:</div>
        <div class="text-md-medium">
          {{ currency.abbr }} {{ pledgeForm?.pledgeDetails?.amountToPledge }}
        </div>
      </div>
      <div class="detail-row" *ngIf="pledgeForm?.pledgeDetails?.amountToPayNow">
        <div class="heading">Amount I'd like to pay now:</div>
        <div class="text-md-medium">
          {{ currency.abbr }} {{ pledgeForm?.pledgeDetails?.amountToPayNow }}
        </div>
      </div>
      <div class="detail-row" *ngIf="pledgeForm?.pledgeDetails?.cycleType">
        <div class="heading">Payment Cycle:</div>
        <div class="text-md-medium">
          {{
            pledgeForm?.pledgeDetails?.cycleType === "MONTHLY"
              ? "Monthly"
              : "Yearly"
          }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.pledgeDetails?.pledgeCompletionDateTime"
      >
        <div class="heading">Pledge Completion Date:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.pledgeDetails?.pledgeCompletionDateTime | date }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.pledgeDetails?.isMatchingGiftFromCompany"
      >
        <div class="heading">Any matching gift from the company:</div>
        <div class="text-md-medium">
          {{
            pledgeForm?.pledgeDetails?.isMatchingGiftFromCompany ? "Yes" : "No"
          }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.pledgeDetails?.companyMatchingGiftAmount"
      >
        <div class="heading">Company matching gift amount:</div>
        <div class="text-md-medium">
          {{ currency!.abbr }}
          {{ pledgeForm?.pledgeDetails?.companyMatchingGiftAmount }}
        </div>
      </div>
      <div class="detail-row" *ngIf="pledgeForm?.pledgeDetails?.companyName">
        <div class="heading">Company Name:</div>
        <div class="comment-text text-md-medium">
          {{ pledgeForm?.pledgeDetails?.companyName }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.pledgeDetails?.transferOtherThanMoney"
      >
        <div class="heading">
          Do you want to transfer something other than money?:
        </div>
        <div class="comment-text text-md-medium">
          {{ pledgeForm?.pledgeDetails?.transferOtherThanMoney }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.pledgeDetails?.installmentStartsFrom"
      >
        <div class="heading">Installment starts on:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.pledgeDetails?.installmentStartsFrom | date }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="pledgeForm?.pledgeDetails?.isAnonymous !== undefined"
      >
        <div class="heading">Make my Pledge anonymous:</div>
        <div class="text-md-medium">
          {{ pledgeForm?.pledgeDetails?.isAnonymous ? "Yes" : "No" }}
        </div>
      </div>
      <div
        class="detail-row"
        *ngIf="
          pledgeForm?.pledgeDetails?.isInterestedInBequestThroughWill !==
          undefined
        "
      >
        <div class="heading">
          Are you interested to know about Bequest through your WILL?:
        </div>
        <div class="text-md-medium">
          {{
            pledgeForm?.pledgeDetails?.isInterestedInBequestThroughWill
              ? "Yes"
              : "No"
          }}
        </div>
      </div>
      <div class="detail-row" *ngIf="pledgeForm?.pledgeDetails?.commentText">
        <div class="heading">Comments:</div>
        <div class="comment-text text-md-medium">
          {{ pledgeForm?.pledgeDetails?.commentText }}
        </div>
      </div>
    </div>
  </div>

  <div class="actions">
    <button
      mat-flat-button
      disableRipple
      cdkStepperNext
      class="button--primary"
      type="button"
      (click)="submitForm()"
    >
      Submit
    </button>
  </div>
</div>
