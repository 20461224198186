<section class="personal-details">
  <div class="form__header">
    <span class="text-lg-medium"> Pledge Details</span>
    <button mat-icon-button (click)="gotoEdit(3)" *ngIf="isPreviewForm">
      <mat-icon svgIcon="edit" class="edit-icon"></mat-icon>
    </button>
  </div>
  <div class="personal-details__form">
    <form
      [formGroup]="pledgeDetailsForm"
      (ngSubmit)="proceedHandler()"
      autocomplete="off"
      novalidate
    >
      <div class="field-group">
        <ng-container *ngIf="checkForField('currencyId')">
          <div class="input__field">
            <mat-label class="text-sm-light"
              >I’d like to pledge in
              <span
                class="asterisk"
                *ngIf="checkForIsRequired('currencyId', pledgeDetails)"
                >*</span
              ></mat-label
            >
            <mat-form-field>
              <mat-select
                formControlName="currencyId"
                placeholder="Select currency"
              >
                <mat-option
                  *ngFor="let currency of currencies"
                  [value]="+currency.id"
                  >{{ currency.abbr }}</mat-option
                >
              </mat-select>
              <mat-error
                *ngIf="
                  pledgeDetailsForm.controls['currencyId'].hasError('required')
                "
              >
                Currency is required
              </mat-error>
            </mat-form-field>
          </div></ng-container
        >
        <ng-container *ngIf="checkForField('isAnonymous')">
          <div class="input__field" style="grid-column: 1 / 2; margin-bottom: 1rem;">
            <mat-label class="text-sm-light"
              >Make my Pledge anonymous
              <span
                class="asterisk"
                *ngIf="checkForIsRequired('isAnonymous', pledgeDetails)"
                >*</span
              ></mat-label
            >
            <mat-slide-toggle
              formControlName="isAnonymous"
            ></mat-slide-toggle></div
        ></ng-container>

        <ng-container *ngIf="checkForField('isPledgeInSharesAndStocks')">
          <div class="input__field">
            <mat-label class="text-sm-light"
              >I would like to pledge in shares and stocks
              <span
                class="asterisk"
                *ngIf="
                  checkForIsRequired('isPledgeInSharesAndStocks', pledgeDetails)
                "
                >*</span
              ></mat-label
            >
            <mat-slide-toggle formControlName="isPledgeInSharesAndStocks">
              <small
                class="text-xs-light"
                *ngIf="
                  pledgeDetailsForm.get('isPledgeInSharesAndStocks')?.value
                "
              >
                <a
                  target="_blank"
                  href="https://uat-iitb-lms-file-uploads.s3.ap-south-1.amazonaws.com/ui-asset/Tax+Benifits+of+Donating+Securities+in+US.pdf"
                  >Read More</a
                >
              </small>
            </mat-slide-toggle>
          </div></ng-container
        >
        <!-- </div> -->
        <ng-container *ngIf="checkForField('amountToPledge')">
          <div class="input__field">
            <mat-label class="text-sm-light"
              >Amount I’d like to pledge
              <span
                class="asterisk"
                *ngIf="checkForIsRequired('amountToPledge', pledgeDetails)"
                >*</span
              ></mat-label
            >
            <mat-form-field appearance="fill" floatLabel="always">
              <input
                matInput
                formControlName="amountToPledge"
                placeholder="Enter value"
                type="number"
              />
              <mat-error
                *ngIf="
                  pledgeDetailsForm.get('amountToPledge')!.hasError('required')
                "
              >
                Pledge amount is required.
              </mat-error>
            </mat-form-field>
          </div></ng-container
        >
        <ng-container *ngIf="checkForField('amountToPayNow')">
          <div class="input__field">
            <mat-label class="text-sm-light"
              >Amount I’d like to pay now
              <span
                class="asterisk"
                *ngIf="checkForIsRequired('amountToPayNow', pledgeDetails)"
                >*</span
              ></mat-label
            >
            <mat-form-field appearance="fill" floatLabel="always">
              <input
                matInput
                formControlName="amountToPayNow"
                placeholder="Enter value"
                type="number"
              />
              <mat-error
                *ngIf="
                  pledgeDetailsForm.get('amountToPayNow')!.hasError('required')
                "
              >
                Amount is required.
              </mat-error>
            </mat-form-field>
          </div></ng-container
        >
      </div>
      <div class="field-group">
        <div class="input__field" *ngIf="checkForField('cycleType')">
          <mat-label class="text-sm-light"
            >Payment Cycle
            <span
              class="asterisk"
              *ngIf="checkForIsRequired('cycleType', pledgeDetails)"
              >*</span
            ></mat-label
          >
          <mat-form-field appearance="fill" floatLabel="always">
            <mat-select
              formControlName="cycleType"
              placeholder="Select your answer"
            >
              <mat-option [value]="'MONTHLY'">Monthly</mat-option>
              <mat-option [value]="'YEARLY'">Yearly</mat-option>
            </mat-select>
            <mat-error
              *ngIf="
                pledgeDetailsForm.controls['cycleType'].hasError('required')
              "
            >
              Please select correct value
            </mat-error>
          </mat-form-field>
        </div>

        <div class="input__field" *ngIf="checkForField('pledgeCompletionDate')">
          <mat-label class="text-sm-light"
            >Pledge Completion Date <span class="asterisk">*</span></mat-label
          >
          <mat-form-field>
            <input
              matInput
              [matDatepicker]="picker"
              placeholder="DD/MM/YYYY"
              formControlName="pledgeCompletionDateTime"
              [min]="minDate"
              readonly
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
      <ng-container *ngIf="checkForField('isMatchingGiftFromCompany')">
        <div class="field-group">
          <div class="input__field">
            <mat-label class="text-sm-light"
              >Any matching gift from Company
              <span
                class="asterisk"
                *ngIf="
                  checkForIsRequired('isMatchingGiftFromCompany', pledgeDetails)
                "
                >*</span
              >
              <mat-icon svgIcon="ic_info" [appTooltip]="matchingCompanyTooltip"></mat-icon>
              <ng-template #matchingCompanyTooltip>
                <small class="para-regular">
                  Many organizations financially match their employees' donations to nonprofits. Kindly check with your employer for more details on the same.
                </small>
              </ng-template>
            </mat-label>
            <mat-form-field>
              <mat-select
                formControlName="isMatchingGiftFromCompany"
                placeholder="Select your answer"
              >
                <mat-option [value]="true">Yes</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
              <mat-error
                *ngIf="
                  pledgeDetailsForm.controls[
                    'isMatchingGiftFromCompany'
                  ].hasError('required')
                "
              >
                Please select correct value
              </mat-error>
            </mat-form-field>
          </div>
        </div></ng-container
      >
      <div class="field-group" *ngIf="showAnyMatchingGiftFromCompanyFields()">
        <ng-container>
          <div class="input__field">
            <mat-label class="text-sm-light"
              >Company matching gift amount
              <span
                class="asterisk"
                *ngIf="
                  pledgeDetailsForm
                    .get('companyMatchingGiftAmount')
                    ?.hasValidator(companyMatchingCtrl)
                "
                >*</span
              ></mat-label
            >
            <mat-form-field appearance="fill" floatLabel="always">
              <input
                matInput
                formControlName="companyMatchingGiftAmount"
                placeholder="Enter amount"
                type="number"
              />
              <mat-error
                *ngIf="
                  pledgeDetailsForm
                    .get('companyMatchingGiftAmount')!
                    .hasError('required')
                "
              >
                Amount is required.
              </mat-error>
            </mat-form-field>
          </div></ng-container
        >
        <ng-container>
          <div class="input__field">
            <mat-label class="text-sm-light"
              >Company Name
              <span
                class="asterisk"
                *ngIf="
                  pledgeDetailsForm
                    .get('companyName')
                    ?.hasValidator(companyMatchingCtrl)
                "
                >*</span
              >
            </mat-label>
            <mat-form-field appearance="fill" floatLabel="always">
              <input
                matInput
                formControlName="companyName"
                placeholder="Enter name"
              />
              <mat-error
                *ngIf="
                  pledgeDetailsForm.get('companyName')!.hasError('required')
                "
              >
                Company name is required.
              </mat-error>
            </mat-form-field>
          </div></ng-container
        >
      </div>
      <ng-container *ngIf="checkForField('transferOtherThanMoney')">
        <div class="input__field">
          <mat-label class="text-sm-light"
            >Are you interested in donating non-monetary assets, such as land, equipment, or supplies?
            <span
              class="asterisk"
              *ngIf="
                checkForIsRequired('transferOtherThanMoney', pledgeDetails)
              "
              >*</span
            ></mat-label
          >
          <mat-form-field>
            <textarea
              matInput
              formControlName="transferOtherThanMoney"
              placeholder="Enter your comments here"
              rows="3"
            ></textarea>
          </mat-form-field>
        </div>
      </ng-container>
      <div class="field-group">
        <ng-container *ngIf="checkForField('installmentStartsFrom')">
          <div class="input__field">
            <mat-label class="text-sm-light"
              >Could you tentatively share with us when would you prefer to
              start your payments?
              <span
                class="asterisk"
                *ngIf="
                  checkForIsRequired('installmentStartsFrom', pledgeDetails)
                "
                >*</span
              ></mat-label
            >
            <mat-form-field>
              <input
                matInput
                [matDatepicker]="picker1"
                placeholder="DD/MM/YYYY"
                formControlName="installmentStartsFrom"
                [min]="minDate"
                readonly
              />
              <mat-datepicker-toggle
                matIconSuffix
                [for]="picker1"
                [disableRipple]="true"
              ></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field></div
        ></ng-container>
        <!-- <ng-container *ngIf="checkForField('isAnonymous')">
          <div class="input__field">
            <mat-label class="text-sm-light"
              >Make my Pledge anonymous
              <span class="asterisk" *ngIf="checkForIsRequired('isAnonymous', pledgeDetails)"
                >*</span
              ></mat-label
            >
            <mat-slide-toggle
              formControlName="isAnonymous"
            ></mat-slide-toggle>
          </div></ng-container
        > -->
      </div>
      <ng-container *ngIf="checkForField('isInterestedInBequestThroughWill')">
        <div class="toggle-container">
          <mat-label class="text-sm-light"
            >Are you Interested to know about Bequest through your WILL?<span
              class="asterisk"
              *ngIf="
                checkForIsRequired(
                  'isInterestedInBequestThroughWill',
                  pledgeDetails
                )
              "
              >*</span
            > 
            <mat-icon
              svgIcon="ic_info"
              [appTooltip]="bequestTooltip"
            ></mat-icon>
            <ng-template #bequestTooltip>
              <small class="para-regular"
                >If you would like to consider leaving a future gift to IIT Bombay as
                part of your legacy. A bequest through a will is a specific type of
                planned gift in which you can designate an asset or portion of your
                estate IIT Bombay, to be transferred in future.</small
              >
            </ng-template>
          </mat-label>
          <div class="toggle">
            <mat-slide-toggle
              formControlName="isInterestedInBequestThroughWill"
            ></mat-slide-toggle>
          </div></div
      ></ng-container>
      <ng-container *ngIf="checkForField('commentText')">
        <div class="input__field">
          <mat-label class="text-sm-light"
            >Comments
            <span
              class="asterisk"
              *ngIf="checkForIsRequired('commentText', pledgeDetails)"
              >*</span
            ></mat-label
          >
          <mat-form-field>
            <textarea
              matInput
              formControlName="commentText"
              placeholder="Enter your comments here"
              rows="3"
            ></textarea>
          </mat-form-field>
        </div>
      </ng-container>
      <div class="actions">
        <button
          *ngIf="!isPreviewForm"
          mat-flat-button
          disableRipple
          class="button--secondary"
          type="button"
          cdkStepperPrevious
        >
          Previous
        </button>
        <button
          *ngIf="!isPreviewForm"
          mat-flat-button
          disableRipple
          cdkStepperNext
          class="button--primary"
          type="button"
          [disabled]="pledgeDetailsForm.invalid"
          (click)="proceedHandler()"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</section>
