<ng-container *ngIf="isLoggedIn">
  <mat-toolbar class="header__loggedIn">
    <mat-icon svgIcon="header-logo"></mat-icon>
    <div class="header__actions">
      <!-- <mat-icon svgIcon="notification" class="icon__notification"></mat-icon> -->
      <iitb-profile-image
        [initials]="loggedInDetails?.name"
        class="icon__user"
        [matMenuTriggerFor]="menu"
      ></iitb-profile-image>

      <mat-menu #menu="matMenu" class="menu-box">
        <div class="menu">
          <div mat-menu-item class="name-box">
            <span class="name">{{ loggedInDetails?.name }}</span>
            <span class="email">{{ loggedInDetails?.email }}</span>
          </div>
          <mat-divider></mat-divider>
          <span mat-menu-item class="sign-out" (click)="signout()"
            >Sign out</span
          >
        </div>
      </mat-menu>
    </div>
  </mat-toolbar>
</ng-container>

<ng-container *ngIf="!isLoggedIn">
  <mat-toolbar class="header__not-loggedIn">
    <div class="logo-Box">
      <mat-icon svgIcon="iitb-logo"></mat-icon>
      <div class="header-text">
        <h6 class="name">Indian Institute of Technology Bombay</h6>
        <p class="sub-name">Development and Relations Foundation</p>
      </div>
    </div>

    <div class="header__actions">
      <mat-nav-list *ngIf="viewMenuButton === false">
        <mat-list-item
          *ngFor="let item of navListing"
          (click)="redirectTo(item.menuItem)"
        >
          <a
            [routerLink]="item.link"
            #rla="routerLinkActive"
            [ngClass]="rla.isActive ? 'text-sm-medium' : 'text-sm-light'"
            routerLinkActive
          >
            {{ item.menuItem }}
          </a>
        </mat-list-item>
      </mat-nav-list>

      <div
        [matMenuTriggerFor]="menu"
        class="mobile-menu"
        *ngIf="viewMenuButton === true"
      >
        <mat-icon svgIcon="hamburger" class="mobile-menu-icon"></mat-icon>
      </div>
      <mat-menu #menu="matMenu" yPosition="below">
        <button
          mat-menu-item
          (click)="redirectTo(item.menuItem)"
          [routerLink]="item.link"
          #rla="routerLinkActive"
          [ngClass]="rla.isActive ? 'text-sm-medium' : 'text-sm-light'"
          routerLinkActive
          *ngFor="let item of navListing"
        >
          {{ item.menuItem | titlecase }}
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>
</ng-container>
