import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatExpansionModule} from '@angular/material/expansion';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { MatIconModule } from '@angular/material/icon';


interface IAccordion {
  panelOpenState:boolean,
  title:string,
  description:string
}
interface IAccordionData {
  question:string,
  answer:string,
  id?: number,
  activeFlag?:boolean
}
// interface ICdkAccordion {
//   items:IAccordionData[],
// }

@Component({
  selector: 'iitb-accordion',
  standalone: true,
  imports: [CommonModule,MatExpansionModule,CdkAccordionModule,MatIconModule],
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})


export class AccordionComponent {
    @Input() accordionData !: IAccordion;

    @Input() cdkAccordionData !:IAccordionData[];
}
