<!-- <iitb-payment-header class="app__header"></iitb-payment-header> -->
<section class="banner">
  <img
    src="assets/images/donation-banner.svg"
    alt="iit-banner"
    class="banner__image"
  />
</section>
<div class="form-container">
  <iitb-pledge-form-filling
    [isPreviewForm]="false"
    class="form-container__form"
  ></iitb-pledge-form-filling>
  <iitb-footer></iitb-footer>
  <iitb-loader></iitb-loader>
</div>
