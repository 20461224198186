import { HttpClient, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { API_BASE_URL } from "../../constants/urls/urls";
import { API_ENDPOINTS } from "../../structs/api.endpoints.enum";
import { IFileUploadService } from "../file-upload.service.interface";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class LmsService implements IFileUploadService {
  constructor(private http: HttpClient, private router: Router) {}

  private pledgeFormDetails = new BehaviorSubject<any>({});
  private pledgeFormValues = new BehaviorSubject<any>({});

  addPledgeFormDetails(formDetail: any) {
    let currentDetails = this.pledgeFormDetails.getValue();
    this.pledgeFormDetails.next({
      ...currentDetails,
      ...formDetail,
    });
  }

  getPledgeFormDetails() {
    return this.pledgeFormDetails.asObservable();
  }

  addPledgeFormValues(formDetail: any) {
    let currentDetails = this.pledgeFormValues.getValue();
    this.pledgeFormValues.next({
      ...currentDetails,
      ...formDetail,
    });
  }

  getPledgeFormValues() {
    return this.pledgeFormValues.asObservable();
  }

  resetPledgeFormDetails() {
    this.pledgeFormDetails.next({});
  }

  resetPledgeFormValues() {
    this.pledgeFormValues.next({});
  }

  uploadFile(formData: FormData): Observable<HttpEvent<any>> {
    return this.http.post(`${API_ENDPOINTS.lmsBulkUpload}`, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  getFilePreview(formData: FormData) {
    return this.http.post(
      `${API_BASE_URL}${API_ENDPOINTS.lmsBulkUploadPreview}`,
      formData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
  }

  getFileHistory(params: any,type: string = "ushf") {
    return this.http.get(
      `${API_BASE_URL}${API_ENDPOINTS.lmsPledgeHistory}/${type}?pageNumber=${params.currentPage}&pageSize=${params.selectedPageSize}`
    );
  }

  createPledgeForm(payload: any) {
    return this.http.post(
      `${API_BASE_URL}${API_ENDPOINTS.createPledgeForm}`,
      payload
    );
  }

  getPledgeFormById(id: string) {
    return this.http.get(`${API_BASE_URL}${API_ENDPOINTS.getPledgeForm}/${id}`);
  }

  submitForm(payload: any) {
    return this.http.post(
      `${API_BASE_URL}${API_ENDPOINTS.submitPledgeForm}`,
      payload
    );
  }

  getBatch(batchId: string) {
    return this.http.get(`${API_BASE_URL}${API_ENDPOINTS.getBatch}/${batchId}`);
  }

  getDepartment(departmentId: string) {
    return this.http.get(
      `${API_BASE_URL}${API_ENDPOINTS.getDepartment}/${departmentId}`
    );
  }

  getDegree(degreeId: string) {
    return this.http.get(
      `${API_BASE_URL}${API_ENDPOINTS.getDegree}/${degreeId}`
    );
  }

  getHostel(hostelId: string) {
    return this.http.get(
      `${API_BASE_URL}${API_ENDPOINTS.getHostel}/${hostelId}`
    );
  }

  getFormsList(data: any) {
    return this.http.get(`${API_BASE_URL}${API_ENDPOINTS.getForms}/`, {
      params: data,
    });
  }

  getFormsResponses(data: any) {
    return this.http.get(`${API_BASE_URL}${API_ENDPOINTS.getAllResponses}`, {
      params: data,
    });
  }

  exportAllForms(formStatus: string) {
    return this.http.get(`${API_BASE_URL}${API_ENDPOINTS.exportForms}`, {
      params: {
        formStatus: formStatus,
      },
      responseType: "arraybuffer",
    });
  }

  exportPledgeInformation() {
    return this.http.get(`${API_BASE_URL}${API_ENDPOINTS.exportPledgeInfo}`,{responseType: "arraybuffer"})
  }

  exportAllResponses(pledgeFormId: string) {
    return this.http.get(`${API_BASE_URL}${API_ENDPOINTS.exportResponses}`, {
      params: {
        pledgeFormId: pledgeFormId,
      },
      responseType: "arraybuffer",
    });
  }

  archiveForm(formId: string, isArchive: boolean) {
    return this.http.patch(`${API_BASE_URL}${API_ENDPOINTS.archiveForm}?id=${formId}&isArchived=${isArchive}`,{});
  }

  assignBatchLeader(userId: string, pledgeId: string,isSelcted: boolean) {
    return this.http.patch(`${API_BASE_URL}${API_ENDPOINTS.assignBatchLeader}/${userId}/${pledgeId}?isSelected=${isSelcted}`,{});
  }

  getCommentsAndAttachments(userId: any) {
    return this.http.get(`${API_ENDPOINTS.getCommentAndAttachments}${userId}`);
  }

  addCommentsAndAttachments(userId: any, payload: any, notes: string) {
    return this.http.post(`${API_ENDPOINTS.addCommentsAndAttachments}${userId}?Note=${notes}`, payload);
  }

  deleteAttachments(attachmentId: any) {
    return this.http.delete(`${API_ENDPOINTS.deleteAttachments}`);
  }
}
