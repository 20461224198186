import { CommonModule } from '@angular/common';
import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'iitb-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  standalone: true,
  imports : [CommonModule]
})
export class TooltipComponent {

  @Input() content: TemplateRef<any> | undefined;

}
