import { Component, isDevMode } from "@angular/core";
import { UtilityService } from "./core/utils/utility.service";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { ActivatedRoute, Router } from "@angular/router";
import { LocalStorageData } from "./core/utils/localstorage.data";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "iitb-portal-frontend";
  timedOut = false;
  lastPing?: any = null;
  constructor(
    private utilityService: UtilityService,
    private idle: Idle,
    private keepalive: Keepalive,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    const publicPage: boolean =
      this.router.url.includes("about-us") ||
      this.router.url.includes("job-listing") ||
      this.router.url.includes("privacy-policy") ||
      this.router.url.includes("payments");
    this.utilityService.registerIcons();
    this.activatedRoute.queryParams.subscribe((params) => {
      if (publicPage) {
        // If the route contains 'job-listing', skip idle logic setup
        return;
      }

      idle.setIdle(300);
      idle.setTimeout(300);
      idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

      idle.onIdleEnd.subscribe(() => {
        this.reset();
      });

      idle.onTimeout.subscribe(() => {
        this.timedOut = true;
        LocalStorageData.clearDataFromLocalStorage();
        if (this.router.url.includes("payments"))
          this.router.url.includes("payments/recurring")
            ? this.router.navigate(["/payments/recurring"])
            : this.router.navigate(["/payments"]);
        else this.router.navigate(["/login"]);
      });

      // sets the ping interval to 15 minute
      keepalive.interval(300);

      keepalive.onPing.subscribe(() => (this.lastPing = new Date()));

      this.reset();
    });
  }

  reset() {
    this.idle.watch();
    this.timedOut = false;
  }
}
