import { Component, OnInit } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LandingCardComponent } from "src/app/shared/components/landing-card/landing-card.component";
import { HeaderComponent } from "src/app/shared/components/header/header.component";
import { LocalStorageData } from "src/app/core/utils/localstorage.data";
import { ILandingPageTiles } from "src/app/core/utils/admin-common.interface";

@Component({
  selector: "iitb-landing-page",
  standalone: true,
  imports: [CommonModule, LandingCardComponent, HeaderComponent],
  templateUrl: "./landing-page.component.html",
  styleUrls: ["./landing-page.component.scss"],
})
export class LandingPageComponent implements OnInit {
  permissions: any;

  constructor() {}

  ngOnInit(): void {
    const userPermissions =
      LocalStorageData.getDataFromLocalStorage("permissions");
    this.permissions = JSON.parse(userPermissions!);
  }

  donationDetails: ILandingPageTiles = {
    title: "Donation MIS",
    description:
      "Streamlines and tracks the donation process, ensuring efficient management and transparency.",
    redirection: "/donation",
  };

  recruitmentDetails: ILandingPageTiles = {
    title: "Recruitment MIS",
    description:
      "Recruitment MIS streamlines the hiring process, offering features for job posting and applicant tracking.",
    redirection: "/recruitment",
  };

  adminDetails: ILandingPageTiles = {
    title: "Admin MIS",
    description: "",
    redirection: "/admin",
  };

  arjunaDetails: ILandingPageTiles = {
    title: "Arjuna MIS",
    description: "Arjuna MIS gives a detailed insight into donation projects with ability to search and filter through the records.",
    redirection: "/arjuna",
  };

  lmsDetails: ILandingPageTiles = {
    title: "LMS MIS",
    description: "Legacy Management System  is designed specifically for managing and processing financial databases for Alumni donations.",
    redirection: "/lms",
  };
}
