import { Component, Inject, Optional } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormControl, Validators } from "@angular/forms";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatInputModule } from "@angular/material/input";
import { JobListingService } from "src/app/core/services/job-listing/job-listing.service";
import { SnackbarService } from "src/app/core/services/snackbar/snackbar.service";
import { LocalStorageData } from "src/app/core/utils/localstorage.data";
import { Router } from "@angular/router";
import { HttpErrorResponse } from "@angular/common/http";
import { errorText, successMessages } from "../../common/errorMessages";
import { minimum } from "src/app/core/utils/validators";
import { AuthService } from "src/app/core/services/auth/auth.service";
import { PaymentsService } from "src/app/core/services/payments/payments.service";
import { GAService } from "src/app/core/services/google-analytics/ga.service";
import { OTP_VALIDATION_JL, OTP_VALIDATION_PY } from "src/app/core/constants/google-analytics/ga-events";
import { StopInputScrollDirective } from "src/app/core/directives/stop-input-scroll.directive";

@Component({
  selector: "iitb-otp-dialog",
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    StopInputScrollDirective
  ],
  templateUrl: "./otp-dialog.component.html",
  styleUrls: ["./otp-dialog.component.scss"],
})
export class OtpDialogComponent {
  type: string = "EMAIL";
  label = "Email ID";
  action = "Send OTP";
  title = "Verify your Email ID";
  subTitle = "Enter your email ID to apply for the Post";
  email = "";
  jobId = "";
  flow: string = "";
  errorMessages = errorText;

  // jobListingService: JobListingService;

  constructor(
    public dialogRef: MatDialogRef<OtpDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private jobListingService: JobListingService,
    private _snackbar: SnackbarService,
    private router: Router,
    private authService: AuthService,
    private paymentService: PaymentsService,
    private gaService: GAService
  ) {
    // this.jobListingService = this.data.injector.get(JobListingService);
    const { type, email, jobId, title, subTitle, flow } = data || {};
    this.email = email;
    this.jobId = jobId;
    this.type = type === "OTP" ? type : "EMAIL";
    this.label = type === "OTP" ? "OTP" : "Email ID";
    this.action = type === "OTP" ? "Verify OTP" : "Send OTP";
    this.title = title;
    this.subTitle = subTitle;
    this.flow = flow || "candidate";
  }

  emailFormControl = new FormControl("", [
    Validators.required,
    Validators.email,
  ]);
  OTPFormControl = new FormControl("", [
    Validators.required,
    Validators.maxLength(6),
    minimum(6),
  ]);
  checked = false;

  dialogAction() {
    const eventData =
      this.type === "EMAIL"
        ? { event: this.type, email: this.emailFormControl.value }
        : { event: this.type, otp: this.OTPFormControl.value };
    if (this.type === "OTP") {
      const payload = {
        email: this.email,
        otp: this.OTPFormControl.value || "",
      };
      if (this.flow === "candidate") {
        this.jobListingService.verifyOTP(payload).subscribe({
          next: (response: any) => {
            this.gaService.trackEvent(
              OTP_VALIDATION_JL['category'],
              OTP_VALIDATION_JL['action'],
              OTP_VALIDATION_JL['label']
            );
            this.authService.setApplyJobToken(response.data.token);
            let data = response.data.candidateDetailsResponse;
            LocalStorageData.saveDataToLocalStorage("OTP-emailId", this.email);
            LocalStorageData.saveDataToLocalStorage(
              "appliedJobDetails",
              JSON.stringify(data)
            );
            this._snackbar.openSnackBar(
              successMessages.otpValidateSuccessfully
            );
            this.router.navigate([
              `job-listing/${this.jobId}/application/apply`,
            ]);
            this.dialogRef.close(eventData);
          },
          error: (error: HttpErrorResponse) => {},
        });
      } else if (this.flow === "donor") {
        this.paymentService.validateOTP(payload).subscribe({
          next: (response: any) => {
            this.gaService.trackEvent(
              OTP_VALIDATION_PY['category'],
              OTP_VALIDATION_PY['action'],
              OTP_VALIDATION_PY['label']
            );
            const token = response?.data?.token || "";
            this.authService.setPaymentInitializationToken(token);
            this.dialogRef.close(response?.data);
          },
        });
      }
    } else if (this.type === "EMAIL") {
      this.dialogRef.close(eventData);
    }
  }

  resendOTP() {
    const payload = {
      email: this.email,
    };
    this.jobListingService.sendOTPForApply(payload).subscribe({
      next: (response: any) => {
        this._snackbar.openSnackBar(successMessages.otpSentSuccessfully);
      },
      error: (error: any) => {},
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }

  displayPolicy() {
    this.router.navigate([]).then((result) => {
      window.open("privacy-policy", "_blank");
    });
  }
}
