<section class="personal-details">
  <div class="form__header">
    <span class="text-lg-bold"> Personal Details</span>
    <button mat-icon-button (click)="gotoEdit(1)" *ngIf="isPreviewForm">
      <mat-icon
        svgIcon="edit"
      ></mat-icon>
    </button>
  </div>
  <div class="personal-details__form">
    <form
      [formGroup]="basicInfoForm"
      (ngSubmit)="proceedHandler()"
      autocomplete="off"
      novalidate
    >
      <ng-container *ngIf="checkForField('name')">
        <div class="input__field">
          <mat-label  class="text-sm-light"
            >Full Name
            <span
              class="asterisk"
              *ngIf="checkForIsRequired('name', personalDetails)"
              >*</span
            ></mat-label
          >
          <mat-form-field appearance="fill" floatLabel="always">
            <input matInput formControlName="name" placeholder="Full name" />
            <mat-error *ngIf="basicInfoForm.get('name')!.hasError('required')">
              Name is required.
            </mat-error>
          </mat-form-field>
        </div>
      </ng-container>
      <div class="field-group">
        <ng-container *ngIf="checkForField('email')">
          <div class="input__field">
            <mat-label  class="text-sm-light"
              >Email
              <span
                class="asterisk"
                *ngIf="checkForIsRequired('email', personalDetails)"
                >*</span
              ></mat-label
            >
            <mat-form-field appearance="fill" floatLabel="always">
              <input
                matInput
                formControlName="email"
                placeholder="Enter mail id"
              />
              <mat-error
                *ngIf="basicInfoForm.get('email')!.hasError('required')"
              >
                Email id is required.
              </mat-error>
              <mat-error *ngIf="basicInfoForm.get('email')?.hasError('pattern')">
                Please enter a valid email address
              </mat-error>
            </mat-form-field>
          </div></ng-container
        >
        <ng-container *ngIf="checkForField('contact')">
          <div class="input__field contact-field">
            <mat-label class="text-sm-light"
              >Contact Number
              <span
                class="asterisk"
                *ngIf="checkForIsRequired('contact', personalDetails)"
                >*</span
              ></mat-label
            >
            <section class="contact-field__container">
              <div
                class="country-dropdown"
                [ngClass]="{ 'default-flag': default }"
              >
                <mat-select
                  (valueChange)="flagDropdownHandler($event)"
                  disableRipple
                >
                  <mat-select-trigger>
                    <img
                      class="flag-icon"
                      [src]="
                        'http://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                        contactFieldCountry +
                        '.svg'
                      "
                      height="14px"
                      width="21px"
                    />
                  </mat-select-trigger>
                  <input
                    type="text"
                    (keyup)="searchCountryFlagHandler($event)"
                    placeholder="Search country"
                    class="dropdown-search__input"
                  />
                  <mat-option
                    *ngFor="let country of filteredCountryFlags"
                    [value]="country"
                  >
                    <img
                      alt="country-flag"
                      [src]="
                        'http://purecatamphetamine.github.io/country-flag-icons/3x2/' +
                        country.isoCode +
                        '.svg'
                      "
                      height="14px"
                      width="21px"
                    />
                    &nbsp;
                    {{ country.name }}
                  </mat-option>
                </mat-select>
              </div>
              <mat-form-field>
                <input
                  type="text"
                  matInput
                  formControlName="contact"
                  onkeydown="javascript: return ['Backspace','Delete','ArrowLeft','ArrowRight'].includes(event.code) ? true : !isNaN(Number(event.key)) && event.code!=='Space'"
                  (input)="input($event)"
                />

                <mat-error
                  *ngIf="basicInfoForm.get('contact')!.hasError('required')"
                >
                  Contact Number is required
                </mat-error>
              </mat-form-field>
            </section>
          </div></ng-container
        >
        <ng-container [formGroupName]="'addressDetails'">
          <ng-container *ngIf="checkForField('country')">
            <div class="input__field">
              <mat-label class="text-sm-light"
                >Country of Residence
                <span
                  class="asterisk"
                  *ngIf="checkForIsRequired('country', personalDetails)"
                  >*</span
                ></mat-label
              >
              <mat-form-field>
                <mat-select
                  formControlName="country"
                  (selectionChange)="selectHandler($event)"
                  placeholder="Select country"
                >
                  <input
                    type="text"
                    (keyup)="searchCountryHandler($event)"
                    placeholder="Search country"
                    class="dropdown-search__input"
                  />
                  <mat-option
                    *ngFor="let country of filteredCountries"
                    [value]="country.name"
                    >{{ country.name }}</mat-option
                  >
                </mat-select>
                <mat-error
                  *ngIf="
                    basicInfoForm
                      .get('addressDetails')
                      ?.get('country')
                      ?.hasError('required')
                  "
                >
                  Country of residence is required
                </mat-error>
              </mat-form-field>
            </div></ng-container
          >
          <ng-container *ngIf="checkForField('state')">
            <div class="input__field">
              <mat-label class="text-sm-light"
                >State
                <span
                  class="asterisk"
                  *ngIf="checkForIsRequired('state', personalDetails)"
                  >*</span
                ></mat-label
              >
              <mat-form-field>
                <mat-select formControlName="state" placeholder="Select State">
                  <input
                    type="text"
                    (keyup)="searchStateHandler($event)"
                    placeholder="Search state"
                    class="dropdown-search__input"
                  />
                  <mat-option
                    *ngFor="let state of filteredStates"
                    [value]="state.name"
                    >{{ state.name }}</mat-option
                  >
                </mat-select>
                <mat-error
                  *ngIf="
                    basicInfoForm
                      .get('addressDetails')
                      ?.get('state')
                      ?.hasError('required')
                  "
                >
                  State is required
                </mat-error>
              </mat-form-field>
            </div></ng-container
          >
          <ng-container *ngIf="checkForField('city')">
            <div class="input__field">
              <mat-label  class="text-sm-light"
                >Town/ City
                <span
                  class="asterisk"
                  *ngIf="checkForIsRequired('city', personalDetails)"
                  >*</span
                ></mat-label
              >
              <mat-form-field appearance="fill" floatLabel="always">
                <input
                  matInput
                  formControlName="city"
                  placeholder="Enter town/city"
                />
                <mat-error
                  *ngIf="
                    basicInfoForm
                      .get('addressDetails')
                      ?.get('city')!
                      .hasError('required')
                  "
                >
                  Town/ City is required.
                </mat-error>
              </mat-form-field>
            </div></ng-container
          >
          <ng-container *ngIf="checkForField('zipCode')">
            <div class="input__field">
              <mat-label  class="text-sm-light"
                >Pin code
                <span
                  class="asterisk"
                  *ngIf="checkForIsRequired('zipCode', personalDetails)"
                  >*</span
                ></mat-label
              >
              <mat-form-field appearance="fill" floatLabel="always">
                <input
                  matInput
                  formControlName="zipCode"
                  placeholder="Enter pin"
                />
                <mat-error
                  *ngIf="
                    basicInfoForm
                      .get('addressDetails')
                      ?.get('zipCode')!
                      .hasError('required')
                  "
                >
                  Pin code is required.
                </mat-error>
              </mat-form-field>
            </div></ng-container
          >
          <ng-container *ngIf="checkForField('area')">
            <div class="input__field">
              <mat-label  class="text-sm-light"
                >Area, Street, Sector, Village
                <span
                  class="asterisk"
                  *ngIf="checkForIsRequired('area', personalDetails)"
                  >*</span
                ></mat-label
              >
              <mat-form-field appearance="fill" floatLabel="always">
                <input
                  matInput
                  formControlName="area"
                  placeholder="Enter text"
                />
                <mat-error
                  *ngIf="
                    basicInfoForm
                      .get('addressDetails')
                      ?.get('area')!
                      .hasError('required')
                  "
                >
                  Area is required.
                </mat-error>
              </mat-form-field>
            </div></ng-container
          >
          <ng-container *ngIf="checkForField('flatNo')">
            <div class="input__field">
              <mat-label  class="text-sm-light"
                >Flat/ Plot no., Building
                <span
                  class="asterisk"
                  *ngIf="checkForIsRequired('flatNo', personalDetails)"
                  >*</span
                ></mat-label
              >
              <mat-form-field appearance="fill" floatLabel="always">
                <input
                  matInput
                  formControlName="flatNo"
                  placeholder="Enter text"
                />
                <mat-error
                  *ngIf="
                    basicInfoForm
                      .get('addressDetails')
                      ?.get('flatNo')!
                      .hasError('required')
                  "
                >
                  Flat No. is required.
                </mat-error>
              </mat-form-field>
            </div></ng-container
          >
        </ng-container>
      </div>
      <div class="actions">
        <button
          *ngIf="!isPreviewForm"
          mat-flat-button
          disableRipple
          cdkStepperNext
          class="button--primary"
          type="button"
          [disabled]="basicInfoForm.invalid"
          (click)="proceedHandler()"
        >
          Next
        </button>
      </div>
    </form>
  </div>
</section>
