<iitb-header class="app__header" [isLoggedIn]="true"></iitb-header>

<div class="landing__container">
  <div class="landing__cards" *ngIf="permissions.SUPER_ADMIN">
    <h3>SUPER ADMIN</h3>
    <div class="landing__content landing__card">
      <iitb-landing-card [details]="adminDetails" type="ADMIN"></iitb-landing-card>
    </div>
  </div>
  <div class="landing__cards" *ngIf="permissions.RECRUITMENT.length > 0 || permissions.DONATIONS.length > 0 || permissions.PLEDGE.length > 0">
    <h3>MIS</h3>
    <div class="landing__content">
      <iitb-landing-card [details]="lmsDetails"  *ngIf="permissions.PLEDGE.length > 0" type="ADMIN"></iitb-landing-card>
      <iitb-landing-card [details]="recruitmentDetails" *ngIf="permissions.RECRUITMENT.length > 0"
        type="RECRUITMENT"></iitb-landing-card>
      <iitb-landing-card [details]="donationDetails" *ngIf="permissions.DONATIONS.length > 0" type="DONATION"
        [chipList]="permissions.DONATIONS"></iitb-landing-card>
      <iitb-landing-card *ngIf="permissions.ARJUNA.length > 0" [details]="arjunaDetails" type="DONATION"
        [chipList]="permissions.ARJUNA"></iitb-landing-card>
    </div>
  </div>
</div>