export const donationMailId = "batchlegacy@iitbacr.com";
export const donationContactNumber = "9122-2576-4889";
export const contactUsNumber = "9122-2576-4889, 2576-7023";
export const contactUsEmail = "dean.acr.office@iitb.ac.in";
export const countries = [
  { name: "India", icon: "Indian-flag", redirectUrl: "" },
  {
    name: "USA",
    icon: "USA-flag",
    redirectUrl: "https://www.iitbombay.org/fundraising",
  },
  {
    name: "UK",
    icon: "UK-flag",
    redirectUrl: "https://www.iituk.org/project/iit-bombay-donations",
  },
  {
    name: "Other Country",
    icon: "others-flag",
    redirectUrl: "https://acr.iitbombay.org/giving-back/ways-to-giving/",
  },
];
