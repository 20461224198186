import {
  Component,
  EventEmitter,
  inject,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { LmsService } from "src/app/core/services/lms/lms.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule, MatIconButton } from "@angular/material/button";
import { DonationService } from "src/app/core/services/donation/donation.service";
import { MatIconModule } from "@angular/material/icon";

@Component({
  selector: "iitb-form-values-preview",
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatButtonModule, MatIconModule],
  templateUrl: "./form-values-preview.component.html",
  styleUrls: ["./form-values-preview.component.scss"],
})
export class FormValuesPreviewComponent implements OnInit, OnDestroy {
  @Output() onSubmit = new EventEmitter();
  @Output() onEdit = new EventEmitter();
  lmsService = inject(LmsService);
  donationService = inject(DonationService);
  pledgeForm: any;
  currency!: { id: number; abbr: string };
  batchYear: any;
  departmentName = "";
  degreeName = "";
  hostelName = "";
  public subscriptions: any[] = [];
  ngOnInit(): void {
    const subscription = this.lmsService
      .getPledgeFormValues()
      .subscribe((res: any) => {
        this.pledgeForm = res;
        if (this.pledgeForm?.pledgeDetails?.currencyId)
          this.getAbbrById(this.pledgeForm?.pledgeDetails?.currencyId);
        if (this.pledgeForm.batchId) this.getBatch(this.pledgeForm.batchId);
        if (this.pledgeForm.institutionalDetails?.degree)
          this.getDegree(this.pledgeForm.institutionalDetails?.degree);
        if (this.pledgeForm.institutionalDetails?.department)
          this.getDepartment(this.pledgeForm.institutionalDetails?.department);
        if (this.pledgeForm.institutionalDetails?.hostel)
          this.getHostel(this.pledgeForm.institutionalDetails?.hostel);
      });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription?.unsubscribe());
  }

  getAbbrById(id: string) {
    this.donationService.getCurrencyById(id).subscribe({
      next: (response: any) => {
        this.currency = response.data;
      },
    });
  }

  getBatch(batchId: string) {
    this.lmsService.getBatch(batchId).subscribe((res: any) => {
      this.batchYear = res.data.year;
    });
  }
  getDepartment(departmentId: string) {
    this.lmsService.getDepartment(departmentId).subscribe((res: any) => {
      this.departmentName = res.data.name;
    });
  }
  getDegree(degreeId: string) {
    this.lmsService.getDegree(degreeId).subscribe((res: any) => {
      this.degreeName = res.data.name;
    });
  }
  getHostel(hostelId: string) {
    this.lmsService.getHostel(hostelId).subscribe((res: any) => {
      this.hostelName = res.data.name;
    });
  }

  gotoEdit(value: number) {
    this.onEdit.emit(value);
  }
  submitForm() {
    this.lmsService.submitForm(this.pledgeForm).subscribe((res: any) => {
      if (res.data.email) {
        this.onSubmit.emit({ status: true, data: { ...res.data } });
      } else {
        this.onSubmit.emit({ status: false, data: {} });
      }
    });
  }
}
