<div class="footer">
    <div class="footer__container">
        <div class="newsletter-container">
            <div class="message-box">
                <span class="message-title"> Engage with us </span>
                <span class="message-subText">
                    We welcome your ideas and suggestions and encourage you to get in
                    touch with us at
                </span>
            </div>
            <div class="contact-details">
                <div class="contact-details-item">
                    <mat-icon svgIcon="email"></mat-icon>
                    <span>{{givingBackContactEmail}}</span>
                </div>
            </div>
        </div>

        <div class="menu-container">
            <div class="menu-section">
                <div>
                    <mat-icon svgIcon="donation_iitb_logo"></mat-icon>
                </div>
                <div class="menu-text">
                    <span class="links">Main Gate Rd, IIT Area, Powai, Mumbai, </span>
                    <span class="links">Maharashtra 400076.</span>
                </div>
            </div>
            <div class="quick-links__section">
                <span class="quick-link-title">Quick Links</span>
                <div class="links__container">
                    <div class="links-box">
                        <ng-container *ngFor="let item of quickLinks">
                            <a href={{item.link}} class="links" target="_blank">{{item.name}}</a>
                        </ng-container>
                    </div>

                    <div class="links-box">
                        <ng-container *ngFor="let link of otherLinks">
                            <a href={{link.link}} class="links" target="_blank">{{ link.name }}</a>
                        </ng-container>
                    </div>
                </div>
            </div>
            <div class="other-links__section">
                <span class="other-link-title">Contact us</span>
                <div class="other-links-box">
                    <span class="links">{{contactNumber}}</span>
                    <span class="links">{{contactEmail}}</span>
                </div>
            </div>
        </div>

        <div class="bottom-bar">
            <span>{{currentYear}} IIT Bombay. All Rights Reserved.</span>
        </div>

    </div>
    
</div>
