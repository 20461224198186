import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { CdkStepperModule } from "@angular/cdk/stepper";
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MAT_SELECT_CONFIG, MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { TextEditorComponent } from "src/app/shared/components/text-editor/text-editor.component";
import { DonationService } from "src/app/core/services/donation/donation.service";
import { LmsService } from "src/app/core/services/lms/lms.service";
import { PaymentsService } from "src/app/core/services/payments/payments.service";
import { PLEDGE_FORM } from "../../pledge-form.constants";
import { currencyDropdownValue } from "src/app/core/models/donation";
import { CommonService } from "src/app/pages/lms/services/common-service/common.service";
import { MatTooltipModule, TooltipComponent } from "@angular/material/tooltip";
import { TooltipDirective } from "src/app/shared/components/tooltip/tooltip.directive";

@Component({
  selector: "iitb-pledge-details-form",
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    TextEditorComponent,
    MatDatepickerModule,
    MatSlideToggleModule,
    MatButtonModule,
    CdkStepperModule,
    MatNativeDateModule,
    MatTooltipModule,
    TooltipDirective,
  ],
  templateUrl: "./pledge-details-form.component.html",
  styleUrls: ["./pledge-details-form.component.scss"],
  providers: [
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: "flag-overlay" },
    },
  ],
})
export class PledgeDetailsFormComponent implements OnInit, OnDestroy {
  @Input() isPreviewForm!: boolean;
  @Input() pledgeDetails!: any;
  @Input() miscellaneousOptions: any;
  @Output() editClicked = new EventEmitter();
  companyMatchingCtrl = Validators.required;
  currencies!: currencyDropdownValue;
  selectedFieldsAndFormDetails: any;
  ADMIN = PLEDGE_FORM.ADMIN;
  minDate: Date = new Date();
  public subscriptions: any[] = [];
  pledgeDetailsForm: FormGroup<any> = new FormGroup({
    currencyId: new FormControl("", Validators.required),
    isPledgeInSharesAndStocks: new FormControl(false, Validators.required),
    amountToPledge: new FormControl("", Validators.required),
    amountToPayNow: new FormControl("", Validators.required),
    isMatchingGiftFromCompany: new FormControl(false, Validators.required),
    companyMatchingGiftAmount: new FormControl(""),
    companyName: new FormControl(""),
    transferOtherThanMoney: new FormControl("", Validators.required),
    isInterestedInBequestThroughWill: new FormControl(
      false,
      Validators.required
    ),
    commentText: new FormControl("", Validators.required),
    cycleType: new FormControl("", Validators.required),
    isAnonymous: new FormControl(false, Validators.required),
    installmentStartsFrom: new FormControl(null, Validators.required),
    pledgeCompletionDateTime: new FormControl("", Validators.required),
  });

  checkForIsRequired = this.commonService.checkForIsRequired;
  constructor(
    public paymentService: PaymentsService,
    private donationService: DonationService,
    private lmsService: LmsService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (!this.isPreviewForm) {
      this.donationService.getCurrency().subscribe({
        next: (response) => {
          this.currencies = response.data.filter((value) => value.abbr !== 'NULL');
        },
      });
      this.checkIsDisabledAndSetValues(this.miscellaneousOptions[0]);
      this.commonService.setDynamicValidators(
        this.pledgeDetailsForm,
        this.pledgeDetails
      );
    } else {
      const subscription = this.lmsService
        .getPledgeFormDetails()
        .subscribe((res) => {
          this.selectedFieldsAndFormDetails = res;
          this.pledgeDetailsForm.disable();
          this.checkIsDisabledAndSetValues(
            this.selectedFieldsAndFormDetails?.pledgeFormDetails
          );
        });
      this.subscriptions.push(subscription);
    }

    this.pledgeDetailsForm.get('isMatchingGiftFromCompany')?.valueChanges.subscribe({
      next: (res: boolean) => {
        const companyMatchingGiftAmountControl = this.pledgeDetailsForm.get('companyMatchingGiftAmount');
        const companyNameControl = this.pledgeDetailsForm.get('companyName');

        if (res) {
          companyMatchingGiftAmountControl?.setValidators(this.companyMatchingCtrl);
          companyNameControl?.setValidators(this.companyMatchingCtrl);
        } else {
          companyMatchingGiftAmountControl?.clearValidators();
          companyMatchingGiftAmountControl?.reset();
          companyNameControl?.clearValidators();
          companyNameControl?.reset();
        }

        companyMatchingGiftAmountControl?.updateValueAndValidity();
        companyNameControl?.updateValueAndValidity();
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.map((subscription) => subscription?.unsubscribe());
  }

  checkIsDisabledAndSetValues(values: {
    paymentCycleBy: string;
    pledgeCompletionDateBy: string;
    pledgeCompletionDate: string;
    paymentCycle: string;
  }) {
    const pledgeFormDetails = values;
    if (!this.isPreviewForm)
      this.toggleField(
        "pledgeCompletionDateTime",
        pledgeFormDetails?.pledgeCompletionDateBy === this.ADMIN
      );
    if (!this.isPreviewForm)
      this.toggleField(
        "cycleType",
        pledgeFormDetails?.paymentCycleBy === this.ADMIN
    );
    // Patch form values
    this.pledgeDetailsForm.patchValue({
      cycleType:
        pledgeFormDetails?.paymentCycleBy === this.ADMIN
          ? pledgeFormDetails?.paymentCycle ?? null
          : 'NA',
      pledgeCompletionDateTime:
        pledgeFormDetails?.pledgeCompletionDateBy === this.ADMIN
          ? pledgeFormDetails?.pledgeCompletionDate ?? null
          : null,
    });
  }

  toggleField(controlName: string, shouldDisable: boolean) {
    const control = this.pledgeDetailsForm.get(controlName);
    if (control) {
      shouldDisable ? control.disable() : control.enable();
    }
  }

  checkForField(fieldName: string) {
    return this.pledgeDetails?.find(
      (field: any) => field.fieldName === fieldName
    )?.isSelected;
  }

  gotoEdit(value: number) {
    this.editClicked.emit(value);
  }

  proceedHandler() {
    this.lmsService.addPledgeFormValues({
      pledgeDetails: this.pledgeDetailsForm.getRawValue(),
    });
  }

  showAnyMatchingGiftFromCompanyFields() {
    return this.checkForField('isMatchingGiftFromCompany') && this.pledgeDetailsForm.controls[
      'isMatchingGiftFromCompany'
    ].value;
  }
}
