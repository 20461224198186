import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, catchError, throwError } from "rxjs";
import { AuthService } from "../../services/auth/auth.service";
import { Router } from "@angular/router";
import { LocalStorageData } from "../../utils/localstorage.data";
import { SnackbarService } from "../../services/snackbar/snackbar.service";
import { errorText } from "src/app/shared/common/errorMessages";
import { LoaderService } from "../../services/loader/loader.service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
    private _snackbar: SnackbarService,
    private loader: LoaderService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        const errorMessage = this.handleError(request, next, error);
        this._snackbar.openSnackBar(errorMessage, "snackbar-error");
        this.loader.hideLoader();
        return throwError(errorMessage);
      })
    );
  }

  handleError(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    error: HttpErrorResponse
  ) {
    const publicPage =
      this.router.url.includes("about-us") ||
      this.router.url.includes("job-listing") ||
      this.router.url.includes("privacy-policy") ||
      this.router.url.includes("payments");

    let errorMessage;
    if (error.status === 401) {
      LocalStorageData.clearDataFromLocalStorage();
      if (publicPage) {
        errorMessage = errorText.restrictedAccess;
        window.location.reload();
      } else if (this.authService.isUserLoggedIn()) {
        errorMessage = errorText.unauthorizedError;
        this.router.navigate([""]);
      } else {
        errorMessage = errorText.restrictedAccess;
        window.location.reload();
      }
    } else if (error.status === 404) {
      errorMessage = errorText.pageNotFound;
      this.router.navigate(['/page-not-found']);
    } else if (error.status === 500) {
      errorMessage = errorText.somethingWentWrong;
    } else if (error.error instanceof ErrorEvent) {
      errorMessage = error.error.message;
    } else if (error?.error?.errors[0]?.errorCode === 1005) {
      errorMessage = errorText.somethingWentWrong;
      LocalStorageData.clearDataFromLocalStorage();
      this.router.navigate([""]);
    } else if (error.status !== 0) {
      errorMessage = error.error.errors[0].message;
    } else {
      errorMessage = errorText.somethingWentWrong;
    }
    return errorMessage;
  }
}
