import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxEditorModule } from 'ngx-editor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { ErrorInterceptor } from './core/interceptors/error/error.interceptor';
import { TokenInterceptor } from './core/interceptors/token/token.interceptor';
import { NotFoundPageComponent } from './not-found-page/not-found-page.component';
import { AccordionComponent } from './shared/components/accordion/accordion.component';
import { ComponentLibraryComponent } from './shared/components/component-library/component-library.component';
import { HeaderComponent } from './shared/components/header/header.component';
import { DonationTypeChipComponent } from './shared/donation-type-chip/donation-type-chip.component';
import { FooterComponent } from './shared/footer/footer.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { GA_TRACKING_CODE } from './core/constants/google-analytics/ga-code';
import { MatMenuModule } from '@angular/material/menu';

// import { MomentModule } from 'angular2-moment';
@NgModule({
  declarations: [AppComponent, NotFoundPageComponent],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    NgxGoogleAnalyticsModule.forRoot(GA_TRACKING_CODE),
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CoreModule,
    AppRoutingModule,
    MatCardModule,
    MatDialogModule,
    HttpClientModule,
    MatButtonModule,
    MatInputModule,
    FormsModule,
    MatFormFieldModule,
    AccordionComponent,
    ComponentLibraryComponent,
    HttpClientModule,
    DonationTypeChipComponent,
    FooterComponent,
    NgxEditorModule,
    MatSnackBarModule,
    MatMenuModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    HeaderComponent,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
