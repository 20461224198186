<mat-card class="card">
  <mat-card-header class="card__header">
    <mat-card-title class="card__titlebox">
        <p class="card__title">All Notifications</p>
        <button mat-flat-button disableRipple class="button--tertiary" (click)="markAllread()">Mark all as read</button>
    </mat-card-title>
  </mat-card-header>
  <mat-card-content class="card__content" *ngFor="let notification of notificationsCard">
    <div class="card__contentbox">
        <p class="notification__title" [ngClass]="titleColor">{{notification.notificationTitle}}</p>
        <p class="notification__timestamp">{{notification.notificationDate}} | {{notification.notificationTime}}</p>
    </div>
  </mat-card-content>
</mat-card>
