<!-- Header -->
<iitb-header class="app__header" [isLoggedIn]="false"></iitb-header>

<div style="margin-left: 50px;">
    <!-- Profile Image -->
    <iitb-profile-image initials="Random User Extra"></iitb-profile-image>

    <!-- Buttons -->
    <h2>Buttons</h2>
    <div class="buttons">
        <button mat-flat-button disableRipple class="button--primary">Button</button>
        <button mat-flat-button disableRipple class="button--secondary">Button</button>
        <button mat-flat-button disableRipple class="button--tertiary">Button</button>

        <button mat-flat-button disableRipple class="button--secondary">Button</button>
    </div>

    <!-- Input-Fields -->
    <h2>Input Fields</h2>
    <div>
        <div class="input__field">
            <label for="name">Label</label>
            <mat-form-field appearance="fill" floatLabel="always">
                <input type="text" matInput id="name" [formControl]="nameFormControl" placeholder="Placeholder" />
                <mat-error *ngIf="nameFormControl.hasError('required')">
                    {{ errorText.requiredField }}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="input__field">
            <label for="otp">OTP</label>
            <mat-form-field appearance="fill" floatLabel="always">
                <input type="password" matInput id="otp" [formControl]="nameFormControl" placeholder="Placeholder" />
                <mat-error *ngIf="nameFormControl.hasError('required')">
                    {{ errorText.requiredField }}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="input__field">
            <mat-label for="email">Email</mat-label>

            <mat-form-field appearance="fill" floatLabel="always">
                <input type="email" matInput id="email" [formControl]="emailFormControl"
                    placeholder="Enter your Email" />
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    {{ errorText.requiredField }}
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('email')">
                    {{ errorText.emailInvalid }}
                </mat-error>
            </mat-form-field>
        </div>

        <div class="input__field">
            <label for="phone">Phone Number</label>
            <mat-form-field appearance="fill" floatLabel="always">
                <input type="tel" matInput id="phone" [formControl]="nameFormControl"
                    placeholder="Enter your phone number" />
                <mat-error *ngIf="nameFormControl.hasError('required')">
                    {{ errorText.requiredField }}
                </mat-error>
            </mat-form-field>
        </div>
    </div>

    <!-- cdkAccordion -->
    <h2>Accordion</h2>
    <iitb-accordion [cdkAccordionData]="cdkAccordionData"></iitb-accordion>

    <!-- search component -->
    <h2>Search Component</h2>
    <iitb-search (searchTextEmitter)="onSearch($event)"></iitb-search>

    <!--Notifications Card-->
    <iitb-notifications-card [notificationsCard]=" notificationsCardData"></iitb-notifications-card>

    <h2>Donation Counter Sub Card</h2>
    <!-- <donation-count-card [donationDetails]="donationDetails"></donation-count-card> -->

    <!-- search component -->
    <h2>Search Component</h2>
    <iitb-search (searchTextEmitter)="onSearch($event)"></iitb-search>

    <!--Notifications Card-->
    <iitb-notifications-card [notificationsCard]=" notificationsCardData"></iitb-notifications-card>

    <!--Banner-->
    <iitb-banner (knowMorebtnEmitter)="knowMoreclicked($event)"></iitb-banner>

    <!--Social Button-->
    <h2>Social Button</h2>
    <div class="buttons">
        <button mat-flat-button disableRipple class="button--social">
            <mat-icon svgIcon="google-icon"></mat-icon>
            <p>Sign in with Google</p>
        </button>
        <button mat-flat-button disableRipple class="button--social" [disabled]="true">
            <mat-icon svgIcon="google-icon"></mat-icon>
            <p>Sign in with Google</p>
        </button>
    </div>

    <!--Segment controls-->
    <iitb-segment-controls [segmentControlItems]="segmentControlData" (displayContentEmitter)="displayData($event)"></iitb-segment-controls>

    <!--Banner-->
    <iitb-banner (knowMorebtnEmitter)="knowMoreclicked($event)"></iitb-banner>

    <h2>Current Opening Card</h2>
    <current-opening-card [currentOpeningDetails]="currentOpeningDetails"></current-opening-card>

    <h2>File upload</h2>
    <!-- <file-upload></file-upload> -->
    
    <br><br>
    <button mat-flat-button disableRipple class="button--primary" (click)="openDialog()">Launch dialog</button>
    <br><br>
    <button mat-flat-button disableRipple class="button--primary" (click)="openSuccessAlert()">Success Alert
        dialog</button>
    <br><br>
    <button mat-flat-button disableRipple class="button--primary" (click)="openErrorAlert()">Error Alert dialog</button>

    <!--Stepper-->
    <iitb-stepper (iconEvent)="getIcon($event)" #cdkStepper [linear]="false">
        <cdk-step [stepControl]="stepv1.stepOneForm" [optional]="false">
            <ng-template cdkStepLabel>
                <div class="step-bullet">
                    <mat-icon svgIcon="{{iconName.iconName}}"></mat-icon>
                    <mat-icon svgIcon="{{iconName.iconLine}}" class="step-line"></mat-icon>
                </div>
                <div class="step-title">
                    <span class="step-heading">Basic Information</span>
                    <span class="step-subheading">Provide Basic Information about Communications</span>
                </div>
            </ng-template>
            <iitb-step-one #stepv1></iitb-step-one>
        </cdk-step>
        <cdk-step [stepControl]="stepv2.stepTwoForm" [optional]="false">
            <ng-template cdkStepLabel>
                <div class="step-bullet">
                    <mat-icon svgIcon={{iconName.iconName}}></mat-icon>
                    <mat-icon svgIcon="{{iconName.iconLine}}" class="step-line"></mat-icon>
                </div>
                <div class="step-title">
                    <span class="step-heading">Education Qualifications</span>
                    <p class="step-subheading">Fill your qualification details</p>
                </div>
            </ng-template>
            <iitb-step-two #stepv2></iitb-step-two>
        </cdk-step>
        <cdk-step [stepControl]="stepv3.stepThreeForm" [optional]="false">
            <ng-template cdkStepLabel>
                <div class="step-bullet">
                    <mat-icon svgIcon={{iconName.iconName}}></mat-icon>
                    <mat-icon svgIcon="{{iconName.iconLine}}" class="step-line"></mat-icon>
                </div>
                <div class="step-title">
                    <span class="step-heading">Upload documents</span>
                    <p class="step-subheading">Upload your resume and cover letter</p>
                </div>
            </ng-template>
            <iitb-step-three #stepv3></iitb-step-three>
        </cdk-step>

        <cdk-step [stepControl]="stepv4.stepFourForm" [optional]="false">
            <ng-template cdkStepLabel>
                <div class="step-bullet">
                    <mat-icon svgIcon={{iconName.iconName}}>{{iconName}}</mat-icon>
                    <mat-icon svgIcon="{{iconName.iconLine}}" class="step-line"></mat-icon>
                </div>
                <div class="step-title">
                    <span class="step-heading">Preview</span>
                    <p class="step-subheading">View your details filled/uploaded</p>
                </div>
            </ng-template>
            <iitb-step-four #stepv4></iitb-step-four>
        </cdk-step>
    </iitb-stepper>

</div>


