import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { errorText } from "src/app/shared/common/errorMessages";

@Component({
  selector: 'iitb-step-two',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, MatIconModule, MatFormFieldModule, CdkStepperModule, MatButtonModule, MatInputModule],
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent {
  stepTwoForm = new FormGroup({
    city: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required)
  });
  errorText = errorText;
  constructor() { }

  ngOnInit(): void { }

  stepTwoSubmit() {
  }

  submit(step: any) {
  }

  showErrorState(formControlName: any) {
    if (formControlName.touched) {
      if (formControlName.hasError('required')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
