import { CommonModule } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewEncapsulation
} from "@angular/core";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { BreakpointObserver} from '@angular/cdk/layout';

interface segmentControlData {
  checked: boolean;
  data: string;
  key: string;
}
@Component({
  selector: 'iitb-segment-controls',
  standalone: true,
  imports: [CommonModule, MatButtonToggleModule],
  templateUrl: "./segment-controls.component.html",
  styleUrls: ["./segment-controls.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SegmentControlsComponent {
  @Input() segmentControlItems!: segmentControlData[];
  @Input() drfDisabled: boolean = false;
  @Input() centreDisabled: boolean = false;
  @Output() displayContentEmitter: EventEmitter<any> = new EventEmitter<any>();

 


  displayContentvalue(event: any, item: segmentControlData) {
    this.displayContentEmitter.emit(item);
  }
}
