<iitb-header [isLoggedIn]="false" class="header"></iitb-header>
<div class="about-us">
    <section class="about-us__title">
        <h2 class="title">About Us</h2>
        <img src="../../../../assets/images/IITB_site.jpg">
    </section>

    <section class="about-us__body">
        <h3 class="title">Our Vision</h3>
        <p class="about-us__text">
            Help IIT Bombay rank among the top engineering and technology institutes worldwide by fostering strong relationships with its alumni, corporations and philanthropists
        </p>
    </section>

    <section class="about-us__body">
        <h3 class="title">IIT Bombay Dean Alumni and Corporate Relations</h3>
        <p class="about-us__text">
            DRF assists Dean ACR’s fundraising endeavours by way of donor solicitations, managing donor programs, maintaining financial accounts and reporting details of all activities implemented with alumni donations. 
        </p>
    </section>

    <section class="about-us__body">
        <h3 class="title">Development & Relations Foundations</h3>
        <p class="about-us__text">
            The Development and Relations Foundation (DRF) is a not-for-profit section 8 unit set up by the Dean ACR office. DRF facilitates Dean ACR’s core mission and engages with our vibrant alumni community, corporations, and foundations with the goal of developing and sustaining long-term relationships.
        </p>
    </section>
</div>
<iitb-footer [portal]="'public'" class="footer"></iitb-footer>
