import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Injectable({
  providedIn: 'root'
})
export class GAService {

  constructor(private $gaService: GoogleAnalyticsService) { }

  trackPageView(url: string, title?: string): void {
    this.$gaService.pageView(url, title);
  }

  trackEvent(category: string, action: string, label?: string, value?: number): void {
    this.$gaService.event(category, action, label, value);
  }
}
