import { Component } from "@angular/core";
import { PaymentHeaderComponent } from "src/app/pages/payments/components/payment-header/payment-header.component";
import { FooterComponent } from "src/app/pages/payments/components/footer/footer.component";
import { PledgeFormFillingComponent } from "../pledge-form-filling/pledge-form-filling.component";
import { LoaderComponent } from "src/app/shared/loader/loader.component";

@Component({
  selector: "iitb-fill-form",
  templateUrl: "./fill-form.component.html",
  styleUrls: ["./fill-form.component.scss"],
  standalone: true,
  imports: [
    PledgeFormFillingComponent,
    PaymentHeaderComponent,
    FooterComponent,
    LoaderComponent
  ],
})
export class FillFormComponent {}
