import { Injectable } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { Observable, map, takeWhile, timer } from "rxjs";
import { AuthService } from "../services/auth/auth.service";
import { constants } from "./constants.enum";
import { LocalStorageData } from "./localstorage.data";

@Injectable({
  providedIn: "root",
})
export class UtilityService {
  /**
   * Registers the icons in the icons array in the matIconRegistry
   */
  icons = [
    "search",
    "caret-down",
    "caret-up",
    "cross-icon",
    "login-logo",
    "back-icon",
    "google-icon",
    "banner-logo",
    "header-logo",
    "copyright",
    "social-icons-twitter",
    "social-icons-facebook",
    "social-icons-linkedin",
    "notification",
    "user",
    "success",
    "cross-success",
    "error",
    "cross-error",
    "arrow-up-right",
    "building",
    "ic-user",
    "location",
    "file-upload",
    "preview",
    "thanks",
    "receipt",
    "stepper-inactive",
    "stepper-complete",
    "stepper-current",
    "stepper-active-line",
    "stepper-inactive-line",
    "stepper-complete-line",
    "total-applications",
    "shortListed-applications",
    "hired-applications",
    "not-shortlisted-appliactions",
    "close-jobs",
    "eye",
    "job-openings",
    "plus-icon",
    "money-icon",
    "suitcase-icon",
    "buildings-icon",
    "red-caret-down",
    "green-caret-down",
    "orange-caret-down",
    "grey-caret-down",
    "attachment",
    "envelope",
    "arrow-left",
    "form-success",
    "edit",
    "download",
    "file-arrow-up",
    "cross-file",
    "file-upload-disabled",
    "warning",
    "suitcase_simple",
    "money",
    "iitb-logo",
    "ic_plus",
    "remove-user",
    "edit-user",
    "paper-plane-tilt",
    "cross-white",
    "ellipse",
    "ic_cloud_arrow_up",
    "ic_cloud_arrow_up_24_disabled",
    "envelope",
    "error-icon",
    "trash",
    "donation_iitb_logo",
    "mobile",
    "email",
    "ic_clipboard_text_24",
    "ic_caret_down",
    "ic_x",
    "ic_x_black",
    "hamburger",
    "dot-pattern",
    "bank",
    "credit-card",
    "buildings",
    "bed",
    "handheart",
    "lifebuoy",
    "give-back",
    "hostel-17",
    "footer-background",
    "Indian-flag",
    "USA-flag",
    "UK-flag",
    "others-flag",
    "seal-check",
    "warning-octagon",
    "handsPraying",
    "header-arjuna-logo",
    "dashboard-icon",
    "audit-icon",
    "stack-icon",
    "bulk-upload-icon",
    "ic_copy",
    "refresh-icon",
    "checkCircle",
    "archive",
    "un_archive",
    "ic_info",
    "notes",
    "Ic_Notes",
    "pledge-icon",
    "archive",
    "action",
    "filter",
    "icon_pledges",
    "icon_pledge_form",
    "icon_transaction_mapping",
    "icon_purposes",
    "icon_audit_trail",
    "icon_pledges_active",
    "icon_ongoing_active",
    "icon_tasks",
    "empty-folder",
    "file",
    "ic_delete",
    "Ic_Delete_icon",
    "file-upload-icon",
    "calendar_today",
    "clear",
    "total_amount",
    "Ic_Money",
    "Indian-rupee",
    "USD-dollar",
    "UK-pound",
    "plus-icon-gray"
  ];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private authService: AuthService,
    private router: Router
  ) {}

  registerIcons() {
    this.icons.forEach((icon: string) => {
      this.matIconRegistry.addSvgIcon(
        icon,
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `assets/icons/${icon}.svg`
        )
      );
    });
  }

  // to set timer
  setTimer(time: number): Observable<string> {
    return timer(0, 1000).pipe(
      map(() => {
        time -= 1000;

        if (time === 0) {
          return "";
        }

        return this.formatTimer(time);
      }),
      takeWhile((result) => result !== "Timer completed")
    );
  }

  formatTimer(time: number): string {
    const seconds = Math.floor(time / 1000);
    const minutes = Math.floor(seconds / 60);
    const formattedTime =
      seconds % 60 < 10
        ? `${minutes}:0${seconds % 60}`
        : `${minutes}:${seconds % 60}`;
    return formattedTime;
  }

  cancelHandler() {
    const confirmation = window.confirm("Are you sure you want to cancel?");
    if (confirmation) {
      history.back();
    }
  }

  setUserPermissions(permissions: any, superAdmin: boolean) {
    const permission: any = {};
    const donations = permissions?.donations || [];
    const recruitment = permissions?.recruitment || [];
    const arjuna = permissions?.arjuna || [];
    const pledge = permissions?.pledge || [];

    permission.PLEDGE = pledge;
    permission.DONATIONS = donations?.map(
      (permission: any) => permission.permission
    );

    permission.ARJUNA = arjuna?.map(
      (permission: any) => `${permission.permission}_ARJUNA`
    );

    permission.RECRUITMENT = [];
    permission.PLEDGE = pledge.map((permission: any) => permission.permission);

    if (superAdmin) permission.SUPER_ADMIN = superAdmin;

    if (recruitment.length > 0) {
      const drf = recruitment.filter(
        (permission: any) => permission.orgName === constants.drf
      );

      const center = recruitment.filter(
        (permission: any) => permission.orgName === constants.center
      );

      if (drf.length > 0) {
        const DRF = drf.map((permission: any) => permission.permission);
        permission.RECRUITMENT.push({ DRF });
      }

      if (center.length > 0) {
        const CENTRE_PROJECT = center.map(
          (permission: any) => permission.permission
        );
        permission.RECRUITMENT.push({ CENTRE_PROJECT });
      }
    }

    const allPermissions =
      recruitment.length > 0
        ? [...donations, [...recruitment], ...arjuna, ...pledge]
        : [...donations, ...arjuna, ...pledge];

    permission.multiRole =
      allPermissions.length > 1 || (allPermissions.length >= 1 && superAdmin)
        ? true
        : false;

    LocalStorageData.saveDataToLocalStorage(
      "permissions",
      JSON.stringify(permission)
    );

    if (permission.multiRole) this.router.navigate(["landing-page"]);
    else this.pageRedirectHandler(permission);
  }

  pageRedirectHandler(permission: any) {
    if (permission.DONATIONS.length > 0) {
      switch (permission.DONATIONS[0]) {
        case "PERM_VIEW_DASHBOARD":
          this.router.navigate(["/donation"]);
          break;
        case "PERM_OFFLINE_DONATION":
          this.router.navigate(["/donation/offline"]);
          break;
        case "PERM_BULK_UPLOAD":
          this.router.navigate(["/donation/bulk-upload"]);
      }
    } else if (permission.RECRUITMENT.length > 0) {
      this.router.navigate(["recruitment"]);
    } else if (permission.ARJUNA.length > 0) this.router.navigate(["/arjuna"]);
    else if (permission.SUPER_ADMIN) this.router.navigate(["/admin"]);
    else {
      this.onHomeClick();
    }
  }

  onHomeClick() {
    let permission: any =
      LocalStorageData.getDataFromLocalStorage("permissions");
    permission = JSON.parse(permission!);
    if (!permission) this.router.navigate(["job-listing"]);
    else
      !permission?.multiRole
        ? this.pageRedirectHandler(permission)
        : this.router.navigate(["landing-page"]);
  }
}
