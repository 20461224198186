import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormControl, Validators,ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { errorText } from "src/app/shared/common/errorMessages";

@Component({
  selector: 'iitb-step-four',
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule,MatIconModule,CdkStepperModule, MatButtonModule, MatFormFieldModule, MatInputModule],
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss']
})
export class StepFourComponent {
  stepFourForm = new FormGroup({
    phoneNumber: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required)
  });
  errorText = errorText;
  constructor() {}

  ngOnInit(): void {
  }

  stepFourSubmit() {}

  showErrorState(formControlName: any) {
    if (formControlName.touched) {
      if (formControlName.hasError('required')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
