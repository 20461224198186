export class API_ENDPOINTS {
  // auth endpoints
  public static login = `v1/auth/login`;
  public static forgotPassword = `v1/auth/forgot-password`;
  public static resetPassword = `v1/auth/reset-password`;
  public static setPassword = `v1/auth/set-password`;
  public static userLogout = `v1/auth/logout`;
  public static generateToken = `v1/auth/refresh-token`;
  public static getUserProfile = `v1/user/profile`;

  //Hr
  public static createJob = `v1/job`;
  public static jobList = `v1/job/landing-page`;
  public static jobApplicantDetails = `v1/hr/dashboard/job-detail`;
  public static isAssessmentReady = `v1/hr/dashboard/babblebot/`;
  public static sendAssessment = `v1/hr/dashboard/babblebot/send-invites`;

  //Donation
  public static bulkUpload = `v1/donation/upload`;
  public static getPreview = `v1/donation/preview`;
  public static getProjectIds = `v1/donation/dashboard/projects`;
  public static donationList = "v1/donation/dashboard/donors/";
  public static donationListWithFilter = "v1/donation/dashboard/filters/";
  public static pendingTransaction = "v1/payment/donors/";
  public static donationPreview = "v1/donation/";
  public static donationKPI = "v1/donation/dashboard/summary";
  public static donationExport = "v1/donation/dashboard/export";
  public static donationPaymentExport = "v1/payment/export";
  public static downloadPdf = "v1/donation/pdf/";

  // Offline Form
  public static donation = "v1/donation";
  public static affiliation = "v1/donation/affiliations";
  public static level1 = "v1/donation/levels1";
  public static level2 = "v1/donation/levels2";
  public static project = "v1/donation/projects";
  public static department = "v1/donation/departments";
  public static degree = "v1/donation/degrees";
  public static hostel = "v1/donation/hostels";
  public static transactionTypes = "v1/donation/transaction-types";
  public static currency = "v1/donation/currencies";
  public static batches = "v1/donation/batches";

  public static getFaq = "v1/faq";
  public static getLandingPageJobs = "v1/job/landing-page";

  // Super Admin
  public static applicationPermission = "v1/auth/permissions/";
  public static deleteUser = "v1/auth/delete/";
  public static registerUser = "v1/auth/register";
  public static getApplication = "v1/auth/applications";
  public static getAllUserPermissions = "v1/auth/users/";
  public static getUserPermissionsById = "v1/auth/user/";
  public static editUser = "v1/auth/permission/";

  //hr-flow
  public static getJobs = "v1/hr/dashboard/summary";

  //jobs-flow
  public static applicationStatuses = "v1/job/application-statuses";
  public static applicationStatus = "v1/job/application-status";
  public static jobDownload = "v1/job/download";
  public static closeJobs = "v1/job/job-status";
  public static applyJob = "v1/job/apply";

  //Candidate
  public static jobCandidateApply = "v1/user/otp/send";
  public static jobVerifyOTp = "v1/candidate/otp/validate";
  public static getAffiliations = "v1/candidate/affiliation";

  public static getHostels = "v1/donation/hostels";

  //payments
  public static paymentInit = "v1/payment/init";
  public static validateOTP = "v1/donation/otp/validate";
  public static recurringForm = "v1/mandate/register";

  //Arjuna portal
  public static arjunaDashboard = "v1/arjuna/dashboard/projects/";
  public static arjunaDetailView = "v1/arjuna/dashboard/projectDetails";

  public static lmsBulkUpload = "v2/pledge/upload/ushf";
  public static lmsBulkUploadPreview = "v2/pledge/preview/ushf";
  public static lmsPledgeHistory = "v2/pledge/file-history";

  // pledge form
  public static createPledgeForm = "v2/pledge-forms/create";
  public static getPledgeForm = "v2/pledge-forms";
  public static submitPledgeForm = "v2/pledge/register";
  public static getForms = "v2/pledge-forms";
  public static getAllResponses = "v2/pledge-forms/get-all-respondents";
  public static getBatch = "v2/institution/batch";
  public static getDepartment = "v2/institution/department";
  public static getDegree = "v2/institution/degree";
  public static getHostel = "v2/institution/hostel";
  public static exportForms = "v2/pledge-forms/export-csv/pledge-forms";
  public static exportResponses = "v2/pledge-forms/export-csv/form-respondents";
  public static archiveForm = "v2/pledge-forms/archive-unarchive";
  public static assignBatchLeader  = 'v2/batch-leader/assign'

  //lms Admin
  public static getManagePurpose = "v1/purpose/manage-purposes";
  public static getArchivePurpose = "v1/purpose/get-all-archived-purposes";
  public static getAllocationPurpose = "v1/purpose/allocation-summary"
  public static deletePurpose = "v1/purpose";
  public static getAllPurposesForPledge = "v1/purpose/get-all/PLEDGE";
  public static allocatePurposeToPledge = "v1/purpose/allocation-purpose";
  public static getAllCoursesForPledge = "v1/donation/degrees";
  public static getAllBatchesForPledge = "v2/pledge-view/get-all-years";
  public static createPurpose = "v1/purpose/create-purpose";
  public static editPurpose = "v1/purpose";
  public static getPurposeComments = "v1/purpose/get-purpose-comments";
  public static getPledgeComments = "v1/purpose/get-pledge-comments-and-attachments";
  public static archivePurpose = "v1/purpose/archive-unarchive";
  public static exportPledgeInfo = "v2/pledge/export-csv/pledges";
  public static createPurposeNotes = "v1/purpose/add-comment-for-pledge-purpose/";
  public static getAllDomesticData = '';
  public static getAllUSHFData = '';
  public static getAllUKData = '';
  public static getAllOthersData = '';
  public static getAllArchivedData = '';

  //transaction-mapping
  public static getTransactionByFundtype = 'transaction/get-transactions';
  public static getTransactionStats = "transaction/get-transaction-stats";
  public static getCommentAndAttachments = "v2/admin/get-comments-and-attachments/";
  public static deleteAttachments = "";
  public static addCommentsAndAttachments = "v2/admin/add-comments-and-attachments/";
  public static getAllPledges = "v2/pledge/getAll";

  public static createPledge = `v2/pledge/create-pledge`;
  public static uploadNotesAndAttachments = 'transaction/add-note-file';
  public static getAllComments = `v2/comment/get-comments`;
  public static getAllAttachments = `v2/attachment/get-attachments`;
  public static archiveAttachments = `v2/attachment/archive-unarchive`;
  public static archiveTransaction = `transaction/archive-unarchive`;
  public static mapPledge = `transaction/map-pledge/`;
}

export class API_ENDPOINTS_V2 {

  // Pledges
  public static tooltipData = "v2/pledge-view/get-received-amount/";
  public static getPledgeGraph = "v2/pledge-view/get-all-details";
  public static getFundingCount = "v2/pledge-view/get-funding-count/";
  public static getFundingTooltip = "v2/pledge-view/get-funding-source/";
  public static getReceivedAmount = "v2/pledge-view/get-received-amount/";
  public static getOutstandingAmount = "v2/pledge-view/get-outstanding-amount/";
  public static getTotalPledgeAmount = "v2/pledge-view/get-total-pledge-amount/";
  public static getBatchList = "v2/pledge-view/get-all-years";
  public static getPledgeMemberOverview = "v2/donor/overview/";
  public static exportOverview = "v2/donor/get-csv/"

  public static getBatchPoolDetails = 'v2/pledge-view/get-batch-pool-details/';
  public static getAllDetails = 'v2/pledge-view/get-All-pool-details/';
  public static getIndividualDetailsByYear = 'v2/pledge-view/get-individual-details-by-year/';
  public static getAllPledgesByYear = 'v2/pledge-view/get-all-pledges/';
  public static getPurposesByPledgeId = 'v2/pledge-view/get-all-purposes/';
  public static exportAllMembersByYear = 'v2/pledge-view/get-csv-for-all-members/';
  public static exportIndividualDonorsByYear = 'v2/pledge-view/get-individual-members-csv/';
  public static exportBatchPoolDataByYear = 'v2/pledge-view/get-csv-for-batch-members/';
  public static getUnallocatedAmtDetails = 'v2/pledge-view/get-unallocated-amount-details-by-pledge-id/';

  // Audit
  public static getAllAudit = "v2/audit";
  public static exportCSV = "v2/audit/export-csv/audit";

  public static getGraphData = "v2/pledge-view/get-amount-details";

  // Dashboard
  public static getAllDashboardData = "v2/admin/get-dashboard-data";
}
