import { ValidatorFn, AbstractControl, ValidationErrors } from "@angular/forms";

export function maximumWords(count: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    const wordsCount = control.value.replace(/\s/g, "");
    return wordsCount.length > count ? { wordsCount: true } : null;
  };
}

export function contactValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;
    return control.value.split(" ")[1] === "" ? { number: true } : null;
  };
}

export function rateValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;
    const value = String(control?.value)?.split(".");
    return value.length === 1 || value[1] === "" || value[0] === ""
      ? { rate: true }
      : null;
  };
}

export function minimum(count: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;
    const value = String(control?.value);
    return value.length < count ? { minimum: true } : null;
  };
}

export function fullNameValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value.split(" ");
    if (!control.value) return null;
    return value.length < 2 || !value[1] ? { fullName: true } : null;
  };
}
